import React, { useState, useEffect, useRef } from "react";
import * as Query from "../utils/dbQueries";
import {
  getDoc,
  doc,
  getCountFromServer,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { ad1Ref } from "../utils/dbQueries";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import * as tf from "../utils/translations";
import SkeleLoadMainCats from "./ui/SkeleLoadMainCats";
import {
  CLICK_TYPE_CATEGORY_ITEM,
  CLICK_TYPE_QUERY_PARAM_ITEM,
  CLICK_TYPE_SUB_CATEGORY_ITEM,
} from "../utils/constants";

function CategoryMenuBar({
  setLoadedCats,
  loadedCats,
  setMainCategories,
  subCategories,
  setSubCategories,
  selectedMainCategory,
  setSelectedMainCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedValues,
  setSelectedValues,
  userPreferedLang,
  setPosts,
  uid,
  setLastPost,
}) {
  // set up state variables
  const [gettingqueryParams, setgetQueryParams] = useState(null);
  const [isLoadingMainCat, setIsLoadingMainCat] = useState(true);
  const [isLoadingSubCat, setIsLoadingSubCat] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [MainCategoryCounts, setMainCategoryCounts] = useState([]);
  const [isLoadingCountMain, setIsLoadingCountMain] = useState(false);
  const [SubCategoryCounts, setSubCategoryCounts] = useState([]);
  const [isLoadingCountSub, setIsLoadingCountSub] = useState(false);
  const [AllPostCounts, setAllPostCounts] = useState([]);
  const [isLoadingCountAll, setIsLoadingCountAll] = useState(false);
  const [AllSubPostCounts, setAllSubPostCounts] = useState([]);
  const [isLoadingCountAllSub, setIsLoadingCountAllSub] = useState(false);
  // set up a ref for the category list container
  const categoryListRef = useRef(null);

  // handle AllMaincat Click
  const handleAllMainCatClick = () => {
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedValues([]);
    setPosts([]);
    setLastPost(null);
    // console.log('selected mainCat  all Posts : ',selectedMainCategory?.name_en)
  };

  // handle AllMainCat Click
  const handleAllSubCatClick = () => {
    setSelectedSubCategory(null);
    setSelectedValues([]);
    setPosts([]);
    setLastPost(null);
    // console.log('Category that was selected : ',selectedMainCategory?.name_en)
  };

  // Fetch the counts from the server for main categories
  const fetchMainCategoryCounts = async (cats) => {
    const counts = {};
    setIsLoadingCountMain(true);

    if (Array.isArray(cats)) {
      for (const category of cats) {
        const countRef = query(
          ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("catId", "==", category?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[category.id] = counter.data().count;
      }
    } else if (typeof cats === "object" && cats !== null) {
      for (const categoryId in cats) {
        const category = cats[categoryId];
        const countRef = query(
          ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("catId", "==", category?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[category.id] = counter.data().count;
      }
    }

    setMainCategoryCounts(counts);
    //  setAllSubPostCounts(counts);
    setIsLoadingCountMain(false);
  };

  // Fetch the counts from the server for sub_categories
  const fetchSubCategoryCounts = async (cats) => {
    const counts = {};
    setIsLoadingCountSub(true);
    for (const category of cats) {
      const countRef = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("subCatId", "==", category?.id)
      );
      const counter = await getCountFromServer(countRef);
      counts[category.id] = counter.data().count;
      // console.log('counts', counts)
    }
    setSubCategoryCounts(counts);
    setIsLoadingCountSub(false);
  };

  // Fetch the counts from the server for all Ads
  const fetchAllItems = async () => {
    setIsLoadingCountAll(true);
    try {
      const countRef = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true)
      );
      const counter = await getCountFromServer(countRef);
      const counters = counter.data().count;
      // console.log('counts', counts)
      setAllPostCounts(counters);
      setIsLoadingCountAll(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllSubItems = async (cat_id) => {
    setIsLoadingCountAllSub(true);
    try {
      const countRef = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("catId", "==", cat_id)
      );
      const counter = await getCountFromServer(countRef);
      const counters = counter.data().count;
      // console.log('counts', counts)
      setAllSubPostCounts(counters);
      setIsLoadingCountAllSub(false);
    } catch (err) {
      console.log(err);
    }
  };

  // fetch the main categories when the component mounts
  useEffect(() => {
    if (uid !== null) {
      Query.getAllCats().then((data) => {
        setIsLoadingMainCat(false);
        setMainCategories(data);
        setLoadedCats(data);
        fetchMainCategoryCounts(data);
        fetchAllItems();
      });
    }

    if (loadedCats !== null) {
      setIsLoadingMainCat(false);
      fetchMainCategoryCounts();
    } else {
      setIsLoadingMainCat(true);
    }
  }, [uid]);

  // update the scroll arrow visibility when the window is resized or the category list is scrolled
  useEffect(() => {
    const handleResize = () => {
      if (categoryListRef.current) {
        // Added null check
        setShowLeftArrow(
          categoryListRef.current.scrollLeft > 0 &&
            categoryListRef.current.scrollWidth >
              categoryListRef.current.clientWidth
        );
        setShowRightArrow(
          categoryListRef.current.scrollLeft <
            categoryListRef.current.scrollWidth -
              categoryListRef.current.clientWidth
        );
      }
    };

    const handleScroll = () => {
      if (categoryListRef.current) {
        // Add null check
        setShowLeftArrow(categoryListRef.current.scrollLeft > 0);
      }
    };

    window.addEventListener("resize", handleResize);
    if (categoryListRef.current) {
      categoryListRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (categoryListRef.current) {
        categoryListRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // REGISTER USER'S FAVORITE CATEGORIES,SUBCATEGORY AND QUERY pARAMS itemClicks
  const registeritemClick = async (item, clickType) => {
    const createitemClickDoc = doc(Query.itemClicksRef);
    const itemClickId = createitemClickDoc.id;
    try {
      const itemClickObject = {
        clickId: itemClickId,
        userId: uid,
        itemId: clickType == CLICK_TYPE_QUERY_PARAM_ITEM ? item : item?.id,
        anonymous: true,
        userLanguageCode: userPreferedLang,
        clickType: clickType,
        deviceType: "WEB",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      await setDoc(
        doc(Query.itemClicksRef, itemClickObject.clickId),
        itemClickObject
      );

      //  console.log("newly created item click activity: ",itemClickObject)
    } catch (error) {
      console.log("error recording activity", error);
    }
  };

  // handle a click on a main category
  const handleMainCategoryClick = async (category) => {
    setIsLoadingSubCat(true);
    setSelectedMainCategory(category);
    fetchAllSubItems(category?.id);
    // register activity
    registeritemClick(category, CLICK_TYPE_CATEGORY_ITEM);
    setSelectedSubCategory(null);
    setSelectedValues([]);
    setPosts([]);
    setLastPost(null);

    try {
      const allCategories = Query.allSubCategories;
      const docRef = doc(allCategories, category?.id);
      const docSnapshot = await getDoc(docRef);
      const subCategories = docSnapshot.get("subCategories");

      setSubCategories(subCategories);
      fetchSubCategoryCounts(subCategories);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSubCat(false);
    }
  };

  // handle a click on a sub category
  const handleSubCategoryClick = (category) => {
    setSelectedSubCategory(category);
    setgetQueryParams(category?.queryParams);
    // register activity
    registeritemClick(category, CLICK_TYPE_SUB_CATEGORY_ITEM);
    setSelectedValues([]);
    setPosts([]);
    setLastPost(null);
  };

  // change element bg when an option is selected
  const handleSelectChange = (event, qpId) => {
    setPosts([]);
    setLastPost(null);
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [qpId]: event.target.value,
    }));

    // register activity
    registeritemClick(event.target.value, CLICK_TYPE_QUERY_PARAM_ITEM);
  };

  // handle a click on the left scroll arrow
  const handleLeftArrowClick = () => {
    categoryListRef.current.scrollLeft -= categoryListRef.current.clientWidth;
  };

  // handle a click on the right scroll arrow
  const handleRightArrowClick = () => {
    categoryListRef.current.scrollLeft += categoryListRef.current.clientWidth;
  };

  return (
    <>
      <div className="category-menu-bar lg:mb-2">
        <div className="flex items-center w-full ">
          {showLeftArrow && (
            <div
              className="category-scroll-icon text-[#2B9EDA] flex items-center justify-center p-2 cursor-pointer rounded-full shadow-lg"
              onClick={handleLeftArrowClick}
            >
              <FaChevronLeft />
            </div>
          )}
          <div
            className="category-list-container flex overflow-x-auto whitespace-nowrap w-full"
            ref={categoryListRef}
          >
            {/* render the main categories */}
            {isLoadingMainCat ? (
              <div className="text-center w-full text-gray-300">
                <SkeleLoadMainCats />
              </div>
            ) : (
              <>
                {/* add an "All" category as the first item */}
                <div
                  className={`category-item flex items-center ml-2  text-gray-700 lg:text-[15px] py-2 px-4 rounded-md cursor-pointer group
              ${
                selectedMainCategory === null
                  ? "text-white bg-[#2B9EDA]"
                  : "bg-[#F0F0F0] hover:text-white hover:bg-[#2B9EDA]"
              }`}
                  onClick={handleAllMainCatClick}
                >
                  <div>{userPreferedLang == "FR" ? tf.AllFr : "All"}</div>
                  <div className="text-xs pl-1 text-gray-500  group-hover:text-white">
                    {isLoadingCountAll ? (
                      <>
                        <div className="w-2 h-2 border border-t-4 border-[#2B9EDA] rounded-full animate-spin"></div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`lg:mt-1 ${
                            selectedMainCategory === null ? "text-white" : ""
                          }`}
                        >
                          <span className="pl-2"> • </span>
                          <span className="pr-[2.5px]">{AllPostCounts}</span>
                          {userPreferedLang === "FR" ? "Pubs" : "Ads"}{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* render the rest of the main categories */}

                {loadedCats?.map((category, index) => {
                  const countMain = MainCategoryCounts[category.id];
                  return (
                    <div
                      key={index}
                      className={`flex items-center  text-gray-700 lg:text-[15px] mx-2 rounded-md category-item p-2 cursor-pointer group
        ${
          selectedMainCategory?.id === category?.id
            ? "text-white bg-[#2B9EDA]"
            : "hover:text-white hover:bg-[#2B9EDA] bg-[#F0F0F0] "
        }`}
                      onClick={() => handleMainCategoryClick(category)}
                    >
                      <div className="w-5 mx-1 bg-white p-1 rounded-full">
                        <img src={category.iconUrl} alt={category?.name_en} />
                      </div>
                      {/* CATEGORY NAME */}
                      <div>
                        {userPreferedLang === "FR"
                          ? category?.name_fr
                          : category?.name_en}
                      </div>
                      {/* category item_count */}
                      <div className="text-xs pl-1 text-gray-500  group-hover:text-white">
                        {isLoadingCountMain ? (
                          <>
                            <div className="w-2 h-2 border border-t-4 border-[#2B9EDA] rounded-full animate-spin"></div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`lg:mt-1  ${
                                selectedMainCategory?.id === category?.id
                                  ? "text-white"
                                  : ""
                              }`}
                            >
                              <span className="pl-2"> • </span>
                              <span className="pr-[2.5px]">{countMain}</span>
                              {userPreferedLang === "FR" ? "Pubs" : "Ads"}{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {showRightArrow && (
            <div
              className="category-scroll-icon flex items-center text-[#2B9EDA] justify-center p-2 cursor-pointer rounded-full shadow-lg"
              onClick={handleRightArrowClick}
            >
              <FaChevronRight />
            </div>
          )}
        </div>

        {/* render the sub categories */}
        {isLoadingSubCat ? (
          <div className="text-center text-gray-500 my-4 ">
            {userPreferedLang == "FR" ? tf.loadingMenu : "Loading ..."}
          </div>
        ) : (
          <>
            {selectedMainCategory && (
              <div className="flex items-center mb-1 w-full">
                <div className="flex mt-2 overflow-x-auto whitespace-nowrap text-xs lg:text-[1em]">
                  <div
                    className={`category-item flex items-center ml-2 text-xs text-gray-700 lg:text-[15px] py-2 px-4 rounded-md  cursor-pointer group
              ${
                !selectedSubCategory
                  ? "text-white bg-[#2B9EDA]"
                  : "bg-[#F0F0F0] hover:text-white hover:bg-[#2B9EDA]"
              }`}
                    onClick={handleAllSubCatClick}
                  >
                    <div>{userPreferedLang == "FR" ? tf.AllFr : "All"}</div>
                    <div className="text-xs pl-1 text-gray-400  group-hover:text-white">
                      <div
                        className={`lg:mt-1  ${
                          !selectedSubCategory ? "text-white" : ""
                        }`}
                      >
                        <span className="pl-2"> • </span>
                        <span className="pr-[2.5px]">{AllSubPostCounts}</span>
                        {userPreferedLang === "FR" ? "Pubs" : "Ads"}{" "}
                      </div>
                    </div>
                  </div>

                  {/* render the rest of the sub categories */}
                  {subCategories?.map((category, index) => {
                    const countSub = SubCategoryCounts[category.id];
                    return (
                      <>
                        <div
                          key={index}
                          className={`flex items-center text-xs text-gray-700 lg:text-[15px] mx-2 rounded-md category-item p-2 cursor-pointer group
              ${
                selectedSubCategory?.id === category.id
                  ? "text-white bg-[#2B9EDA]"
                  : " hover:text-white hover:bg-[#2B9EDA] bg-[#F0F0F0] group"
              }`}
                          onClick={() => handleSubCategoryClick(category)}
                        >
                          <div className=" w-5 mx-1 bg-white p-1 rounded-full ">
                            <img
                              src={category?.iConUrl}
                              alt={category?.name_en}
                            />
                          </div>
                          {/* CATEGORY NAME */}
                          <div>
                            {userPreferedLang === "FR"
                              ? category?.name_fr
                              : category?.name_en}
                          </div>
                          {/* category item_count */}
                          <div className="text-xs pl-1 text-gray-500  group-hover:text-white">
                            {isLoadingCountSub ? (
                              <>
                                <div className="w-2 h-2 border border-t-4 border-[#2B9EDA] rounded-full animate-spin"></div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={`lg:mt-1  ${
                                    selectedSubCategory?.id === category.id
                                      ? "text-white"
                                      : ""
                                  }`}
                                >
                                  <span className="pl-2"> • </span>
                                  <span className="pr-[2.5px]">{countSub}</span>
                                  {userPreferedLang === "FR"
                                    ? "Pubs"
                                    : "Ads"}{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}

        {/* render queryParams */}
        {selectedSubCategory && (
          <div className="flex items-center my-2 w-full">
            <div className="flex overflow-x-auto whitespace-nowrap text-xs text-gray-700 lg:text-[15px]">
              {gettingqueryParams?.map((qp) => (
                <div key={qp.id} className="flex items-center">
                  <select
                    className={`p-2 mx-2 shadow-lg border-none outline-none  focus:outline-none transition-all ease-in-out text-gray-600 lg:text-[15px] ${
                      selectedValues[qp.id] !== undefined &&
                      selectedValues[qp.id] !== ""
                        ? "text-white bg-[#2B9EDA]"
                        : "text-gray-400 bg-[#F0F0F0]"
                    }`}
                    value={selectedValues[qp.id] || ""}
                    onChange={(event) => handleSelectChange(event, qp.id)}
                  >
                    {/* DEFAULT NAME OF PARAM */}
                    <option value="" disabled defaultValue={"selected"} hidden>
                      {userPreferedLang == "FR" ? qp?.name_fr : qp?.name_en}
                    </option>

                    <option value="" className="bg-white text-black">
                      {userPreferedLang == "FR"
                        ? "Tous " + qp?.name_fr
                        : "All " + qp?.name_en + "'s"}
                    </option>

                    {/* SHOW ITEMS OF EACH QUERY PARAM */}
                    {qp.items.map((item) => (
                      <>
                        <option
                          className="bg-white text-black"
                          key={item.id}
                          value={item.id}
                          label={
                            userPreferedLang == "FR"
                              ? item?.name_fr
                              : item?.name_en
                          }
                        />
                      </>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CategoryMenuBar;
