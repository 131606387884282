import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  FaShare,
  FaCopy,
  FaCheckCircle,
  FaPhoneAlt,
  FaWhatsapp,
  FaCamera,
  FaTags,
} from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import AdPopup from "./AdPopup";
import * as Query from "../utils/dbQueries";
import TimeDifference from "../utils/TimeDifference";
import Logo from "../assets/logo.png";
import * as tf from "../utils/translations";
import { doc, setDoc, updateDoc, increment } from "firebase/firestore";
import { json, useNavigate } from "react-router-dom";
import {
  CLICK_TYPE_AD,
  FOREIGN_USEDen,
  LAND_PLOTS_FOR_SALE,
  LOCALLY_USEDen,
  NOTIFICATION_SENDING_USER_TYPE_ANONYMOUS,
  NOTIFICATION_TYPE_POST_VIEWED,
  POST_TYPE_HOUSE_FOR_RENT,
  POST_TYPE_ITEM_FOR_SALE,
  POST_TYPE_SHORT_LET,
  REFURBISHEDen,
  USEDen,
} from "../utils/constants";
import { currencySign } from "../utils/dbQueries";
import Seo from "../utils/Seo";
import { Link } from "react-router-dom";
import WarnUser from "./ui/WarnUser";

function MobilePostAd({
  post_object,
  loadedCats,
  uid,
  userPreferedLang,
  buttonIfAlertWasShown,
  setButtonAlert,
}) {
  const user_info = post_object?.user;
  const [showPopup, setShowPopup] = useState(false);
  const [allCats, setAllCats] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const [showMiniPopup, setMiniPop] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [showWarning, setShowWarning] = useState(false);

  const handleImageClick = useCallback((adId) => {
    setShowPopup(true);
    incrementPostViewCount(adId, "imageClick");
    registerAdClick(adId);
  }, []);

  const handleClosePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  useEffect(() => {
    Query.getAllCats().then((data) => {
      setAllCats(data);
    });
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // FUNCTION TO SEND THE CHAT CLICK EVENT TO DB
  const userClickCTA = async (clickType, post_object) => {
    const createClickObject = doc(Query.allChats);
    const clickId = createClickObject.id;
    try {
      const clickObject = {
        chatId: clickId,
        clientId: uid,
        vendorId: post_object?.userId,
        clientName: "Anonymous",
        createdAt: new Date(),
        type: clickType,
        itemId: post_object?.id,
        catId: post_object?.catId,
        subCatId: post_object?.subCatId,
        locationId: post_object?.localityId,
        subLocationId: post_object?.subLocalityId,
      };

      //  set new ibject
      await setDoc(doc(Query.allChats, clickObject.chatId), clickObject);

      //  console.log(clickObject)
    } catch (err) {
      console.log(err);
    }
  };

  // THIS FUNCTION WILL INCREMENT THE POST VIEW COUNT
  const incrementPostViewCount = async (adId, typeOfEvent) => {
    const docRef = doc(Query.ad1Ref, adId?.id);
    try {
      await updateDoc(docRef, {
        views: increment(1),
      });

      // check if the function is from ran from when they are in viewport
      if (typeOfEvent == "viewPort") {
        // console.log("From Viewport");
      } else {
        // SEND PUSH NOTIFICATION FOR AD VIEWED
        sendViewedPostPushNotification(adId);
      }
    } catch (err) {
      console.error(err);
    }
    // console.log(`View : ${adId?.views}`);
  };

  // send user notification that post has been viewed
  const sendViewedPostPushNotification = async (adInfo) => {
    const createNotificationDoc = doc(Query.NotificationsRef);
    const notificationId = createNotificationDoc.id;
    try {
      const notification = {
        id: notificationId,
        receiverDeviceToken: adInfo?.user?.fcmToken,
        titleEn: "iConz",
        titleFr: "iConz",
        senderDeviceToken: "",
        textEn: "Someone has viewed you Post",
        textFr: "Quelqu'un a vu votre annonce",
        sendingUser: null,
        sendingUserType: NOTIFICATION_SENDING_USER_TYPE_ANONYMOUS,
        createdAt: new Date(),
        updatedAt: new Date(),
        notificationType: NOTIFICATION_TYPE_POST_VIEWED,
        sendingUserId: uid,
        receivingUser: adInfo?.user,
        receivingUserId: adInfo?.userId,
        itemId: adInfo?.id,
        viewed: false,
      };

      await setDoc(doc(Query.NotificationsRef, notification.id), notification);

      //  console.log(notificationId)
    } catch (error) {
      console.log("error sending viewed ad notification", error);
    }
  };

  // USER FUNCTIONS THAT CAPTURE USER ad-clicks
  const registerAdClick = async (adId) => {
    const createAdClickDoc = doc(Query.allAdsClicksRef);
    const adClickId = createAdClickDoc.id;
    try {
      const adClickObject = {
        clickId: adClickId,
        clientId: uid,
        vendorId: adId?.userId,
        clientName: "Anonymous",
        anonymous: true,
        itemId: adId?.id,
        catId: adId?.catId,
        subCatId: adId?.subCatId,
        locationId: adId?.localityId,
        subLocationId: adId?.subLocalityId,
        userLanguageCode: userPreferedLang,
        clickType: CLICK_TYPE_AD,
        deviceType: "WEB",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      await setDoc(
        doc(Query.allAdsClicksRef, adClickObject.clickId),
        adClickObject
      );

      //  console.log("newly created click activity: ",adClickObject)
    } catch (error) {
      console.log("error recording activity", error);
    }
  };

  useEffect(() => {
    let isViewCountIncremented = false;
    if (imageRef.current && "IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isViewCountIncremented) {
              const lazyImage = entry.target;
              const lazyImageSrc = lazyImage.dataset.src;
              lazyImage.src = lazyImageSrc;
              observer.unobserve(lazyImage);

              const realImage = new Image();
              realImage.onload = () => {
                if (lazyImage.src === lazyImageSrc) {
                  incrementPostViewCount(post_object, "viewPort");
                  isViewCountIncremented = true;
                }
              };
              realImage.onerror = () => {
                console.error("Error loading image:", lazyImageSrc);
              };
              realImage.src = lazyImageSrc;
            }
          });
        },
        { rootMargin: "0px" } // observe only when fully in view
      );
      observer.observe(imageRef.current);
      return () => {
        observer.disconnect();
      };
    } else {
      // fallback to simply loading the image
      setImageLoaded(true);
    }
  }, [imageRef]);

  // handle copy and share link popup
  const handleMiniPopUp = () => {
    setMiniPop(!showMiniPopup);
  };

  // HANDLE SHARE BUTTON
  const handleShareButtonClick = async (post_object) => {
    if (navigator.share) {
      navigator
        .share({
          title: post_object?.title,
          text: post_object?.description,
          url: post_object?.shortLink,
        })
        .then(() => console.log("Link shared successfully."))
        .catch((error) => console.error("Error sharing link:", error));
    } else {
      console.log("Web Share API not supported.");
      alert("Sharing is supported on this browser");
    }
  };

  // HANDLE COPY DYNAMIC LINK TO CLIPBOARD
  const handleCopyToClipboard = (link) => {
    const input = document.createElement("input");
    input.setAttribute("value", link);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    console.log("Deep link copied to clipboard");
  };

 
  //   numBER format
  const nf = new Intl.NumberFormat();
  return (
    <>
      <div className="p_wrapper w-full bg-white">
        <div className="w-full ">
          <div
            role="status"
            className="px-1 rounded-md shadow-md border border-slate-100"
          >
            {/* HEADER/USER DETAILS */}
            <div className="header_details w-full flex items-center justify-between">
              <Link
                to={{ pathname: `/profile/${user_info?.userName}` }}
                state={{ id: user_info?.id }}
                className="flex items-center space-x-1 pt-1"
              >
                <img
                  src={user_info?.profilePhotoUrl}
                  alt="user_profile_pic"
                  className="w-[30px] h-[30px] rounded-full "
                />

                <div className="flex justify-between -mt-1 w-full">
                  <div>
                    <div className="w-12 h-1.5  flex items-center">
                      <span className="text-[14px] whitespace-nowrap">
                        {user_info?.userName.length > 10
                          ? `${user_info?.userName.slice(0, 20)}.`
                          : user_info?.userName}
                      </span>

                      {user_info?.verified ? (
                        <span>
                          <FaCheckCircle className="text-[#2B9EDA] text-[11px] ml-1" />
                        </span>
                      ) : null}
                    </div>
                    <div className=" h-1 mt-1 text-[#2B9EDA] text-[10px]">
                      <TimeDifference
                        date_created={post_object?.updatedAt}
                        userPreferedLang={userPreferedLang}
                      />
                    </div>
                  </div>
                </div>
              </Link>
              <div
                onClick={handleMiniPopUp}
                className="sub_menu_button cursor-pointer -mr-[5px]"
              >
                {showMiniPopup ? (
                  <>
                    {" "}
                    <IoIosClose className="text-xl" />
                  </>
                ) : (
                  <>
                    <BsThreeDotsVertical />
                  </>
                )}
              </div>
            </div>

            {/* THE POST IMAGE */}
            <div className="flex items-center justify-center w-full h-[135px] rounded mt-1">
              <div className="image_container w-full h-full overflow-hidden rounded relative ">
                <img
                  onClick={() => handleImageClick(post_object)}
                  ref={imageRef}
                  data-src={
                    post_object?.itemImageUrls &&
                    post_object.itemImageUrls.length > 0
                      ? post_object.itemImageUrls[0]
                      : post_object?.photo1Url
                  }
                  src={
                    imageLoaded &&
                    post_object?.itemImageUrls &&
                    post_object.itemImageUrls.length > 0
                      ? post_object.itemImageUrls[0]
                      : post_object?.photo1Url || Logo
                  }
                  alt="post_image"
                  className={`w-full h-full object-cover cursor-pointer`}
                  onLoad={handleImageLoad}
                />

                {/* NUMBER OF PICS */}
                <div className="number_of_pics absolute bottom-0 left-0 bg-[#707070] text-white w-[24px] h-[13px] rounded-[3px]">
                  <div className="flex justify-center items-center text-[8px]">
                    <FaCamera className="mr-1" />
                    <p>
                      {post_object?.itemImageUrls !== undefined
                        ? post_object?.itemImageUrls?.length
                        : post_object?.photoCount}
                    </p>
                  </div>
                </div>
                {/* SUBSCRIPTION PLAN */}
                {post_object?.boostPackage === "30DMINI" ||
                post_object?.boostPackage === "30DTOP" ? (
                  <div className="number_of_pics absolute top-0 right-0 bg-[#57039F] text-white w-[22px] h-[12px] rounded-tl-[3px] rounded-bl-[3px] shadow-md">
                    <div className="flex justify-center items-center font-bold text-[8px]">
                      <p>PRO</p>
                    </div>
                  </div>
                ) : post_object?.boosted ? (
                  <div className="number_of_pics absolute top-0 right-0 bg-[#0F8747] text-white w-[22px] h-[12px] rounded-tl-[3px] rounded-bl-[3px] shadow-md">
                    <div className="flex justify-center items-center font-bold text-[8px]">
                      <p>TOP</p>
                    </div>
                  </div>
                ) : null}

                {/* SHARE/COPY LINK OF POST */}
                <div
                  className={`share absolute z-50 top-0 right-0 rounded bg-white shadow-md  transition-all  w-[100px] ease-in ${
                    showMiniPopup ? "h-[55px] border" : "h-0 overflow-hidden"
                  }`}
                >
                  <div className="inner_wrap ">
                    <button
                      className="group w-full flex items-center px-2 lg:px-1 hover:bg-[#2B9EDA] hover:text-white"
                      onClick={() =>
                        handleCopyToClipboard(post_object?.shortLink)
                      }
                    >
                      {" "}
                      <span>
                        {userPreferedLang == "FR" ? "Copie" : "Copy link"}
                      </span>{" "}
                      <span>
                        <FaCopy className="ml-1 group-hover:text-white text-[#2B9EDA]" />
                      </span>{" "}
                    </button>
                    <button
                      className="group w-full flex items-center px-2 lg:px-1 hover:bg-[#2B9EDA] hover:text-white transition-all ease-in-out"
                      onClick={() => handleShareButtonClick(post_object)}
                    >
                      {" "}
                      <span>
                        {userPreferedLang == "FR" ? "Partager" : "Share"}
                      </span>{" "}
                      <span>
                        <FaShare className="ml-1 text-[#2B9EDA] group-hover:text-white transition-all ease-in-out" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* PRODUCT/AD DETAILS PRICE AND TITLE */}
            {/* Price */}
            <div className=" text-[15px] mt-1 price_item">
              <span>
                {currencySign} {nf.format(post_object?.itemPrice)}
                {/* get the post type */}
                {post_object?.postType == POST_TYPE_HOUSE_FOR_RENT ? (
                  <>{userPreferedLang == "FR" ? "/Mois" : "/Month"} </>
                ) : post_object?.postType == POST_TYPE_SHORT_LET ? (
                  <>{userPreferedLang == "FR" ? "/Jour" : "/Day"} </>
                ) : post_object?.subLocalityId == LAND_PLOTS_FOR_SALE ? (
                  "M/SQ"
                ) : post_object?.postType == POST_TYPE_ITEM_FOR_SALE ? (
                  ""
                ) : null}
              </span>
            </div>
            {/* TITLE */}
            <div className="truncate-text text-[14px] text-gray-700 p_title">
              {" "}
              {post_object?.title}
            </div>
            {/* TAG NAME */}
            {post_object?.postType !== POST_TYPE_HOUSE_FOR_RENT ? (
              <div className="tag_name flex items-center">
                <FaTags className="text-[10px] text-[#999999] mr-1" />
                <span className="text-[11px] font-light">
                  {userPreferedLang == "FR"
                    ? post_object?.brandFr
                    : post_object?.brandEn}
                </span>
              </div>
            ) : null}

            {post_object?.postType === POST_TYPE_HOUSE_FOR_RENT ? (
              <div className="flex">
                <p className="text-[10px] bg-[#f2efef] text-green-500 px-1 rounded-full">
                  {userPreferedLang == "FR"
                    ? post_object?.conditionFr
                    : post_object?.conditionEn}
                </p>
              </div>
            ) : null}

            <div className="flex">
              {/* location */}
              <div className="location flex items-center">
                <MdLocationOn className="text-[#2B9EDA] text-sm" />
                <p className="text-[12px] ">
                  {post_object?.postType === POST_TYPE_HOUSE_FOR_RENT
                    ? post_object?.neighborhood + ","
                    : null}
                  {userPreferedLang == "FR"
                    ? post_object?.localityFr
                    : post_object?.localityEn}
                </p>
              </div>
              {/* condition */}
              <div className="condition">
                <span>
                  {post_object?.conditionEn === REFURBISHEDen ? (
                    <>
                      <p className="ml-4   bg-[#e3e2e2] px-2 text-[10px] rounded-full">
                        {userPreferedLang == "FR"
                          ? post_object?.conditionFr
                          : post_object?.conditionEn}
                      </p>
                    </>
                  ) : post_object?.conditionEn === USEDen ? (
                    <>
                      <p className="ml-2  bg-[#e3e2e2] px-2 text-[10px] rounded-full">
                        {userPreferedLang == "FR"
                          ? post_object?.conditionFr
                          : post_object?.conditionEn}
                      </p>
                    </>
                  ) : post_object?.conditionEn === FOREIGN_USEDen ? (
                    <>
                      <p className="ml-2  bg-[#e3e2e2] px-2 text-[10px] rounded-full">
                        {userPreferedLang == "FR"
                          ? post_object?.conditionFr
                          : post_object?.conditionEn}
                      </p>
                    </>
                  ) : post_object?.conditionEn === LOCALLY_USEDen ? (
                    <>
                      <p className="ml-2 bg-[#e3e2e2] px-2 text-[10px] rounded-full">
                        {userPreferedLang == "FR"
                          ? post_object?.conditionFr
                          : post_object?.conditionEn}
                      </p>
                    </>
                  ) : (
                    <>
                      {post_object?.postType !== POST_TYPE_HOUSE_FOR_RENT ? (
                        <p className="ml-2 text-[10px] bg-[#f2efef] text-green-500 px-2 rounded-full whitespace-nowrap">
                          {userPreferedLang == "FR"
                            ? post_object?.conditionFr
                            : post_object?.conditionEn}
                        </p>
                      ) : null}
                    </>
                  )}
                </span>
              </div>
            </div>

            {/* CALL AND CHAT CTA BUTTONS */}
            <div className="w-full flex justify-between h-[35px] shadow-sm border-t border-slate-100 mt-1">
              <div className="w-full h-full border-r  hover:bg-[#2B9EDA] text-[#2B9EDA] flex justify-center items-center">
                {!buttonIfAlertWasShown ? (
                  <>
                    <p
                      onClick={() => {
                        setActionType("CALL");
                        setShowWarning(true);
                        userClickCTA(tf.call, post_object);
                      }}
                      className="flex items-center justify-center  hover:text-white transition-all ease-in cursor-pointer"
                    >
                      {" "}
                      <span className="">
                        {userPreferedLang == "FR" ? tf.callFr : "Call"}
                      </span>{" "}
                      <FaPhoneAlt className="ml-1 text-[14px]" />
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <a
                      onClick={() => userClickCTA(tf.call, post_object)}
                      href={`tel:${user_info?.phoneNumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full h-full flex items-center justify-center  hover:text-white transition-all ease-in"
                    >
                      <span className="text-xl">
                        {userPreferedLang == "FR" ? tf.callFr : "Call"}
                      </span>{" "}
                      <FaPhoneAlt className="ml-1 " />
                    </a>
                  </>
                )}
              </div>
              <div className="w-full h-full  border-l hover:bg-[#2B9EDA] text-[#2B9EDA] flex justify-center items-center">
                {!buttonIfAlertWasShown ? (
                  <>
                    <p
                      onClick={() => {
                        setActionType("WA");
                        setShowWarning(true);
                        userClickCTA(tf.Chatbtn, post_object);
                      }}
                      className="group flex items-center justify-center  hover:text-white transition-all ease-in cursor-pointer"
                    >
                      {" "}
                      <span className="">
                        {userPreferedLang == "FR" ? tf.chatFr : "Chat"}
                      </span>{" "}
                      <FaWhatsapp className="text-green-500 group-hover:text-white ml-1 text-[14px]" />
                    </p>
                  </>
                ) : (
                  <>
                    <a
                      onClick={() => userClickCTA(tf.Chatbtn, post_object)}
                      href={
                        userPreferedLang == "FR"
                          ? `https://wa.me/${user_info?.whatsAppNumber}?text=Bonne journée,%0Aje%20veux%20acheter%0A${post_object?.shortLink}`
                          : `https://wa.me/${user_info?.whatsAppNumber}?text=Hi,%0AI%20want%20to%20buy%20this%0A${post_object?.shortLink}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group flex items-center justify-center  hover:text-white transition-all ease-in"
                    >
                      <span className="">
                        {userPreferedLang == "FR" ? tf.chatFr : "Chat"}
                      </span>{" "}
                      <FaWhatsapp className="text-green-500 group-hover:text-white ml-1 text-[14px]" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ad popup */}
      {showPopup && (
        <>
          <Seo
            title={`${post_object?.title}`}
            description={`${post_object?.description}`}
          />

          <AdPopup
            ad={post_object}
            onClose={handleClosePopup}
            loadedCats={loadedCats}
            uid={uid}
            userClickCTA={userClickCTA}
            userPreferedLang={userPreferedLang}
          />
        </>
      )}

      {/* alert */}
      {showWarning ? (
        <>
          {" "}
          <WarnUser
            userPreferedLang={userPreferedLang}
            setShowWarning={setShowWarning}
            actionTaken={actionType}
            user_info={user_info}
            post_link={post_object?.shortLink}
            setButtonAlert={setButtonAlert}
          />
        </>
      ) : null}
    </>
  );
}

export default MobilePostAd;
