import React, { useState, useEffect, useRef } from "react";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { FiSearch, FiChevronRight } from "react-icons/fi";
import Logo from "../assets/logo.png";
import Logo200 from '../assets/logo200.gif'
import LaptopPopup from "./ui/LaptopPopup";
import * as Query from "../utils/dbQueries";
import {
  doc,
  setDoc,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import * as tf from "../utils/translations";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  CLICK_TYPE_LOCALITIES_ITEM,
  CLICK_TYPE_SUB_LOCALITIES_ITEM,
  SEARCH_OBJECT_TYPE_CAT,
  SEARCH_OBJECT_TYPE_QUERY_PARAM_ITEM,
  SEARCH_OBJECT_TYPE_SUB_CAT,
  USER_SEARCH_TYPE_CAT_SEARCH,
  USER_SEARCH_TYPE_MAIN_SEARCH,
  USER_SEARCH_TYPE_QUERY_PARAM_ITEM_SEARCH,
  USER_SEARCH_TYPE_SUB_CAT_SEARCH,
} from "../utils/constants";

function TopBar({
  toggleMenu,
  showMenu,
  setUserPreferedLang,
  userPreferedLang,
  setUserSelectedSubLocality,
  userSelectedSubLocality,
  loadedCats,
  setPosts,
  setSelectedMainCategory,
  setSelectedSubCategory,
  setSelectedValues,
  setUserSelectedRegion,
  handleIconzLogoclick,
  uid,
  setLastPost,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location; //get the pathname
  //COMPONENT'S STATE VARIABLES
  const [AllLocations, setLocations] = useState([]);
  const [AllSublocalities, setsubLocalities] = useState([]);
  //selectedregion used just inside this component
  const [selectedRegion, setselectedRegion] = useState(null);
  const [selectedSublocality, setSelectedSublocality] = useState(null);
  const [showLocations, setShowLocations] = useState(false);
  const popupRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  // show search suggestions box
  const [showList, setShowList] = useState(false);
  const [filteredSearchWords, setFilteredSearchWords] = useState([]);
  // userSelected subloacity and locatiy
  const [showUserSelects, setUserSelects] = useState(null);
  // track if a user has clicked on a search suggestion
  const [clickedSuggestion, setClickedSuggestion] = useState(false);
  // sub-locality item count
  const [AllSubLocalityPostCounts, setAllSubLocalityPostCounts] = useState([]);
  const [MainLocationCounts, setMainLocationCounts] = useState([]);
  const [isLoadingLocationMain, setIsLoadingLocationMain] = useState(false);

  // RESET TO NORMAL TO SEE ADS FROM ALL OVER THE MARKETPLACE
  const handleResetLocations = () => {
    // remove userselected locality for testing
    localStorage.removeItem("userSelectedSubLocality");
    setselectedRegion(null);
    setsubLocalities(null);
    setUserSelectedRegion(null);
    setSelectedSublocality(null);
    setShowLocations(false);
    setPosts([]);
    setLastPost(null);
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedValues([]);
    setUserSelects(null);
    setUserSelectedSubLocality(null);
    // redirect ot home page to load ads if user not in home page
    if (pathname !== "/") {
      navigate("/");
    }
  };
  // RESET AND SHOW JUST ADS IN THE WHOLE REGION NOT SUB-LOCALITY
  const handleRegionReset = (region) => {
    setselectedRegion(region);
    setsubLocalities(region.subLocalities);
    setUserSelectedRegion(region);
    setSelectedSublocality(null);
    setShowLocations(false);
    setPosts([]);
    setLastPost(null);
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedValues([]);
    setUserSelectedSubLocality(null);

    // redirect ot home page to load ads if user not in home page
    if (pathname !== "/") {
      navigate("/");
    }

    // console.log("selected Region to Reset  ",region?.name_en)
    if (userPreferedLang == "FR") {
      setUserSelects(region?.name_fr);
    } else {
      setUserSelects(region?.name_en);
    }
  };
  // HANDLE USER SELECTING REGION AND HIGHLIGHTING SELECTED REGION
  const handleRegionSelection = (region) => {
    setselectedRegion(region);
    setsubLocalities(region?.subLocalities);
    fetchAllSubItemsLocality(region?.subLocalities);
    // register activity
    registeritemClick(region, CLICK_TYPE_LOCALITIES_ITEM);

    // console.log("selected Region object ",region?.name_en)
  };
  // HANDLE SETTING USER SUB-LOCALITY AND HIGHLIGHTING IT
  const handleSublocalityClick = (sublocality) => {
    setSelectedSublocality(sublocality);
    setUserSelectedSubLocality(sublocality);
    // register activity
    registeritemClick(sublocality, CLICK_TYPE_SUB_LOCALITIES_ITEM);
    setShowLocations(false);
    setPosts([]);
    setLastPost(null);
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedValues([]);

    // redirect ot home page to load ads if user not in home page
    if (pathname !== "/") {
      navigate("/");
    }

    // console.log("selected Sub-locality  ", sublocality?.name_en)
    if (userPreferedLang == "FR") {
      setUserSelects(sublocality?.name_fr);
    } else {
      setUserSelects(sublocality?.name_en);
    }
  };
  // OPEN LOCATIONS CONTAINER
  const handleSeeLocations = () => {
    setShowLocations(true);
  };
  // CHANGE USER-PREFFERED LANGUAGE
  const handlePreferedLangChange = (event) => {
    setUserPreferedLang(event.target.value);
  };
  // REDIRECT TO SEARCH PAGE
  const handleSearchWord = (searchedWord, wordType) => {
    // this will allowed the clicked term to be in search input
    setSearchTerm(searchedWord);
    setClickedSuggestion(true);
    setUserSelectedRegion(null);
    setSelectedSublocality(null);
    setUserSelects(null);
    setUserSelectedSubLocality(null);
    localStorage.removeItem("userSelectedSubLocality");
    setselectedRegion(null);

    //register event
    registerUserSearch(searchedWord, wordType);
    setShowList(false);

    navigate("/search", {
      state: {
        searchedWord: searchedWord,
        wordType: wordType,
      },
    });
  };

  // REGITER USER'S LOCALITY AND SUBLOCALITY itemClicks
  const registeritemClick = async (item, clickType) => {
    const createitemClickDoc = doc(Query.itemClicksRef);
    const itemClickId = createitemClickDoc.id;
    try {
      const itemClickObject = {
        clickId: itemClickId,
        userId: uid,
        itemId: item?.id,
        anonymous: true,
        userLanguageCode: userPreferedLang,
        clickType: clickType,
        deviceType: "WEB",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      await setDoc(
        doc(Query.itemClicksRef, itemClickObject.clickId),
        itemClickObject
      );

      //  console.log("newly created item click activity: ",itemClickObject)
    } catch (error) {
      console.log("error recording activity", error);
    }
  };

  // USER FUNCTION TO  CAPTURE USER SEARCH ACTIVITIES
  const registerUserSearch = async (searchedWord, wordType) => {
    const createUserSearchDoc = doc(Query.userSearchRef);
    const userSearchId = createUserSearchDoc.id;
    let userSearch = {};
    try {
      if (wordType === "suggestedWord") {
        let typeOfSearch = searchedWord?.itemType;

        if (typeOfSearch === SEARCH_OBJECT_TYPE_CAT) {
          userSearch = {
            id: userSearchId,
            userId: uid,
            anonymous: true,
            userLanguageCode: userPreferedLang,
            searchType: USER_SEARCH_TYPE_CAT_SEARCH,
            searchString: searchedWord?.matchingString,
            searchStringLength: searchedWord?.matchingString.length,
            deviceType: "WEB",
            createdAt: new Date(),
            updatedAt: new Date(),
          };
        } else if (typeOfSearch === SEARCH_OBJECT_TYPE_SUB_CAT) {
          userSearch = {
            id: userSearchId,
            userId: uid,
            anonymous: true,
            userLanguageCode: userPreferedLang,
            searchType: USER_SEARCH_TYPE_SUB_CAT_SEARCH,
            searchString: searchedWord?.matchingString,
            searchStringLength: searchedWord?.matchingString.length,
            deviceType: "WEB",
            createdAt: new Date(),
            updatedAt: new Date(),
          };
        } else {
          userSearch = {
            id: userSearchId,
            userId: uid,
            anonymous: true,
            userLanguageCode: userPreferedLang,
            searchType: USER_SEARCH_TYPE_QUERY_PARAM_ITEM_SEARCH,
            searchString: searchedWord?.matchingString,
            searchStringLength: searchedWord?.matchingString.length,
            deviceType: "WEB",
            createdAt: new Date(),
            updatedAt: new Date(),
          };
        }
      } else {
        userSearch = {
          id: userSearchId,
          userId: uid,
          anonymous: true,
          userLanguageCode: userPreferedLang,
          searchType: USER_SEARCH_TYPE_MAIN_SEARCH,
          searchString: searchedWord,
          searchStringLength: searchedWord.length,
          deviceType: "WEB",
          createdAt: new Date(),
          updatedAt: new Date(),
        };
      }

      await setDoc(doc(Query.userSearchRef, userSearch.id), userSearch);

      //  console.log("newly created activity: ",userSearch)
      //  console.log("WORD TYPE: ", wordType)
    } catch (error) {
      console.log("error recording activity", error);
    }
  };

  // Fetch the counts from the server for main categories
  const fetchMainLocationCounts = async (locs) => {
    const counts = {};
    setIsLoadingLocationMain(true);

    if (Array.isArray(locs)) {
      for (const location of locs) {
        const countRef = query(
          Query.ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("localityId", "==", location?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[location.id] = counter.data().count;
      }
    } else if (typeof locs === "object" && locs !== null) {
      for (const locationId in locs) {
        const location = locs[locationId];
        const countRef = query(
          Query.ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("localityId", "==", locs?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[location.id] = counter.data().count;
      }
    }

    setMainLocationCounts(counts);
    setIsLoadingLocationMain(false);
  };

  // fetch data for all locations
  const fetchAllSubItemsLocality = async (subRegions) => {
    const counts = {};
    setIsLoadingLocationMain(true);

    if (Array.isArray(subRegions)) {
      for (const subLoc of subRegions) {
        const countRef = query(
          Query.ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("subLocalityId", "==", subLoc?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[subLoc.id] = counter.data().count;
      }
    } else if (typeof subRegions === "object" && subRegions !== null) {
      for (const subLocId in subRegions) {
        const subLoc = subRegions[subLocId];
        const countRef = query(
          Query.ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          where("subLocalityId", "==", subRegions?.id)
        );
        const counter = await getCountFromServer(countRef);
        counts[subLoc.id] = counter.data().count;
      }
    }

    setAllSubLocalityPostCounts(counts);
    setIsLoadingLocationMain(false);
  };

  // GET ALL LOCATIONS
  useEffect(() => {
    // GET ALL LOCATIONS
    if (uid !== null) {
      Query.getAllLocations().then((locations) => {
        setLocations(locations);
        fetchMainLocationCounts(locations);
      });
    }

    if (userSelectedSubLocality !== null) {
      if (userPreferedLang == "FR") {
        setUserSelects(userSelectedSubLocality?.name_fr);
      } else {
        setUserSelects(userSelectedSubLocality?.name_en);
      }
    }
  }, [uid]);

  // HIDE/CLOSE SUGGESTIONS WHEN USER CLCIKS OUTSIDE
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowLocations(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  // when the user clicks on the small cancel button refresh/redirect to home
  useEffect(() => {
    const searchInput = document.getElementById("getSearchCancelButton");
    const handleSearch = () => {
      if (searchInput.value === "") {
        handleIconzLogoclick();
      }
    };
    searchInput.addEventListener("search", handleSearch);
    return () => searchInput.removeEventListener("search", handleSearch);
  }, []);

  // WHEN USER STARTS A SEARCH FOR A PARTICULAR ITEM
  useEffect(() => {
    let newFilteredSearchWords = [];

    if (clickedSuggestion) {
      // Fetch search results based on clicked suggestion
      setShowList(false);
      setClickedSuggestion(false);
    } else {
      if (searchTerm) {
        setShowList(true);

        if (userPreferedLang == "FR") {
          loadedCats?.map((Maincat) => {
            if (
              Maincat?.name_fr
                ?.toLowerCase()
                ?.includes(searchTerm?.toLocaleLowerCase())
            ) {
              var searchObject = {
                itemId: Maincat?.id,
                matchingString: Maincat?.name_fr,
                subCatName: "",
                itemType: SEARCH_OBJECT_TYPE_CAT,
              };
              if (
                !newFilteredSearchWords.some(
                  (word) => word.matchingString === searchObject.matchingString
                )
              ) {
                newFilteredSearchWords.push(searchObject);
              }
            }

            Maincat?.subCategories?.map((subcat) => {
              if (
                subcat?.name_fr
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLocaleLowerCase())
              ) {
                var searchObjectSubCat = {
                  itemId: subcat?.id,
                  matchingString: subcat?.name_fr,
                  subCatName: Maincat?.name_fr + " > " + subcat?.name_fr,
                  itemType: SEARCH_OBJECT_TYPE_SUB_CAT,
                };
                if (
                  !newFilteredSearchWords.some(
                    (word) =>
                      word.matchingString === searchObjectSubCat.matchingString
                  )
                ) {
                  newFilteredSearchWords.push(searchObjectSubCat);
                }
              }

              subcat?.queryParams?.map((qp) => {
                qp?.items?.map((item) => {
                  if (
                    item?.name_fr
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLocaleLowerCase())
                  ) {
                    var searchObjectItem = {
                      itemId: item?.id,
                      matchingString: item?.name_fr,
                      subCatName:
                        Maincat?.name_fr +
                        " > " +
                        subcat?.name_fr +
                        " > " +
                        qp?.name_fr +
                        " > " +
                        item?.name_fr,
                      itemType: SEARCH_OBJECT_TYPE_QUERY_PARAM_ITEM,
                    };
                    if (
                      !newFilteredSearchWords.some(
                        (word) =>
                          word.matchingString ===
                          searchObjectItem.matchingString
                      )
                    ) {
                      newFilteredSearchWords.push(searchObjectItem);
                    }
                  }
                });
              });
            });
          });
        } else {
          loadedCats?.map((Maincat) => {
            if (
              Maincat?.name_en
                ?.toLowerCase()
                ?.includes(searchTerm?.toLocaleLowerCase())
            ) {
              var searchObject = {
                itemId: Maincat?.id,
                matchingString: Maincat?.name_en,
                subCatName: "",
                itemType: SEARCH_OBJECT_TYPE_CAT,
              };
              if (
                !newFilteredSearchWords.some(
                  (word) => word.matchingString === searchObject.matchingString
                )
              ) {
                newFilteredSearchWords.push(searchObject);
              }
            }

            Maincat?.subCategories?.map((subcat) => {
              if (
                subcat?.name_en
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLocaleLowerCase())
              ) {
                var searchObjectSubCat = {
                  itemId: subcat?.id,
                  matchingString: subcat?.name_en,
                  subCatName: Maincat?.name_en + " > " + subcat?.name_en,
                  itemType: SEARCH_OBJECT_TYPE_SUB_CAT,
                };
                if (
                  !newFilteredSearchWords.some(
                    (word) =>
                      word.matchingString === searchObjectSubCat.matchingString
                  )
                ) {
                  newFilteredSearchWords.push(searchObjectSubCat);
                }
              }

              subcat?.queryParams?.map((qp) => {
                qp?.items?.map((item) => {
                  if (
                    item?.name_en
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLocaleLowerCase())
                  ) {
                    var searchObjectItem = {
                      itemId: item?.id,
                      matchingString: item?.name_en,
                      subCatName:
                        Maincat?.name_en +
                        " > " +
                        subcat?.name_en +
                        " > " +
                        qp?.name_en +
                        " > " +
                        item?.name_en,
                      itemType: SEARCH_OBJECT_TYPE_QUERY_PARAM_ITEM,
                    };
                    if (
                      !newFilteredSearchWords.some(
                        (word) =>
                          word.matchingString ===
                          searchObjectItem.matchingString
                      )
                    ) {
                      newFilteredSearchWords.push(searchObjectItem);
                    }
                  }
                });
              });
            });
          });
        }
      }

      setFilteredSearchWords(newFilteredSearchWords);
    }
  }, [searchTerm]);

  // when the input field loses focus. In this handler, we check if the value of the state variable is empty. If it is, we call the redirect to home
  const handleBlur = () => {
    if (searchTerm == "") {
      setShowList(false);
    }
  };

  return (
    <>
      {/* TOP-BAR CONTAINER WRAPPER */}
      <div className="hide_on_other_pages top_bar_wrapper w-full flex items-center justify-between p-2 lg:pr-4 lg:sticky z-30 top-0 bg-white  ">
        {/* INNER CONTAINER HOLDING LEFT CONTENT */}
        <div className="inner_wrapper w-full lg:flex items-center justify-between">
          {/* FAR LEFT INCLUDING MENU_BUTTON , LOGO AND LANG_SELECT WRAPPER*/}
          <div className="mini_div lg:w-[20%] flex  items-center lg:p-2">
            {/* MENU-BUTTON */}
            <div className="menu_button font-bold">
              {showMenu ? (
                <div
                  onClick={toggleMenu}
                  className="p-2 shadow-lg hover:shadow-none cursor-pointer transition-all ease-linear text-xl rounded-full w-10 h-10 flex justify-center items-center "
                >
                  <AiOutlineCloseCircle />
                </div>
              ) : (
                <div
                  onClick={toggleMenu}
                  className="p-2 shadow-lg hover:shadow-none font-extrabold cursor-pointer transition-all ease-linear text-xl rounded-full w-10 h-10 flex justify-center items-center "
                >
                  <AiOutlineMenu />
                </div>
              )}
            </div>
            {/* MENU-BUTTON */}
            {/* LOGO BUTTON */}
            <Link to={`/`} onClick={handleIconzLogoclick}>
              <div className=" font-extrabold flex cursor-pointer items-center">
                <img src={Logo} alt="Iconz log" className="w-10 ml-2 " />
                <h1 className="h2  text-[30px]">iConz</h1>
              </div>
            </Link>
            {/* CHRISTMAS LOGO */}
            {/* <Link to={`/`} onClick={handleIconzLogoclick} className="-ml-2">
              <div className=" font-extrabold flex cursor-pointer items-center"> 
                <img
                  src={Logo200}
                  alt="Iconz log"
                  className="w-[4.5rem] ml-2 "
                />
                <h1 className="h2 -ml-2  text-4xl font-extrabold">iConz</h1>
              </div>
            </Link> */}
            {/* LOGO BUTTON */}
            {/* CHANGE LANGUAGE */}
             {/* <div className="lang text-[16px] text-gray-500 ml-6 lg:ml-14"> */}
            <div className="lang text-[16px] text-gray-500 ml-6 lg:ml-24">
              <select
                value={userPreferedLang}
                onChange={handlePreferedLangChange}
                className="bg-[#F0F0F0] px-2 border border-slate-300 rounded-md focus:outline-none cursor-pointer"
              >
                <option value="EN">EN</option>
                <option value="FR">FR</option>
              </select>
            </div>
            {/* CHANGE LANGUAGE */}
          </div>
          {/* INCLUDING MENU_BUTTON , LOGO AND LANG_SELECT */}

          <div className="search w-full lg:w-[70%] relative mt-2 lg:mt-0 flex justify-center items-center">
            <div className="flex w-full h-[2.3rem] lg:h-10 items-center justify-center ">
              {/* SEARCH BOX AND SUGGESTION LIST */}
              <div className="w-2/3 lg:w-[60%] h-full relative ">
                {/* SEARCH BOX */}
                <input
                  type="search"
                  id="getSearchCancelButton"
                  className="search border border-r-0 border-[#B7B7B7] rounded-bl-full rounded-tl-full h-full placeholder:text-xs lg:placeholder:text-sm placeholder:text-gray-400 p-2 lg:p-4 w-full  focus:outline-none focus:border-[#2B9EDA] "
                  placeholder={`${
                    userPreferedLang == "FR"
                      ? tf.searchPlaceholderFr
                      : "What are you looking for?"
                  }`}
                  value={
                    typeof searchTerm === "object"
                      ? searchTerm?.matchingString
                      : searchTerm
                  }
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                    setShowList(true);
                  }}
                  onBlur={handleBlur}
                />

                {/* SUGGESTION BOX/RESULT/LIST */}

                {showList ? (
                  <>
                    <div className="res absolute w-[150%] lg:w-[100%] top-10 z-50 bg-white p-1 rounded-md shadow-lg">
                      <ul>
                        {filteredSearchWords?.map((searchstring, index) => {
                          return (
                            <li
                              onClick={() => {
                                handleSearchWord(searchstring, "suggestedWord");
                                setPosts([]);
                              }}
                              className="px-2 py-1 cursor-pointer hover:bg-[#F0F0F0]"
                              key={index}
                            >
                              <p className="font-medium">
                                {searchstring?.matchingString}
                              </p>
                              <p className="text-gray-500  text-xs">
                                {searchstring?.subCatName}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>

              {/* LOCATIONS AND SUB-LOCALITIES */}
              <div className="location border border-[#B7B7B7] border-l-0 text-sm lg:text-xl rounded-br-full h-full rounded-tr-full flex bg-[#F0F0F0] items-center text-gray-600 overflow-hidden ">
                <div className="p-1">
                  <GoLocation /> {/* LOCATION ICON */}
                </div>

                {/* TRIGGER SEE LOCATIONS BUTTON */}
                <button
                  onClick={() => handleSeeLocations()}
                  className="mr-2 focus:outline-none text-sm   cursor-pointer bg-[#F0F0F0] p-1 "
                >
                  {showUserSelects !== null ? (
                    <p>{showUserSelects}</p>
                  ) : (
                    <p>Location</p>
                  )}
                </button>

                {/* RENDER LOCATIONS/REGIONS AND SUB-LOCALITIES */}
                {showLocations ? (
                  <div
                    ref={popupRef}
                    className=" flex text-[1rem] -left-0  top-[2.5rem] lg:left-1/2 lg:top-[40px] absolute z-50 lg:w-full "
                  >
                    {/* ALL REGIONS  */}
                    <div className="p-1 lg:p-2 bg-white shadow-lg lg:w-[30%]">
                      <div
                        onClick={() => handleResetLocations()}
                        className="p-2 font-medium cursor-pointer"
                      >
                        {userPreferedLang == "FR"
                          ? tf.allLocationsFr
                          : "All Locations"}
                      </div>
                      <div className=" rounded-md">
                        {AllLocations.map((location) => {
                          const countMain = MainLocationCounts[location.id];
                          return (
                            <>
                              <div
                                key={location.id}
                                className={`location-item cursor-pointer flex items-center justify-between border-b border-[#d8d5d5] py-1 lg:px-2 rounded ${
                                  selectedRegion?.name_en === location.name_en
                                    ? "bg-[#d8d5d5]"
                                    : "hover:bg-[#d8d5d5]"
                                }`}
                                onClick={() => {
                                  handleRegionSelection(location);
                                }}
                              >
                                <p
                                  className={`${
                                    selectedRegion
                                      ? "text-sm lg:text-[1rem] p-2"
                                      : ""
                                  }`}
                                >
                                  {userPreferedLang === "FR"
                                    ? location.name_fr
                                    : location.name_en}
                                </p>
                                <div className="ml-2 lg:ml-6 flex items-center">
                                  <p className="text-xs ">
                                    <span className="pl-2"> • </span>
                                    <span className="pr-[2.5px]">
                                      {countMain}
                                    </span>
                                    {userPreferedLang === "FR" ? "Pubs" : "Ads"}{" "}
                                  </p>
                                  <FiChevronRight className="text-xl text-gray-400" />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    {/* IF A REGION IS SELECTED LOAD ITS SUB-LOCALITIES */}
                    {selectedRegion && (
                      <div className="bg-white  p-2  lg:w-auto">
                        <div
                          onClick={() => {
                            handleRegionReset(selectedRegion);
                          }}
                          className="p-2 cursor-pointer"
                        >
                          {userPreferedLang === "FR"
                            ? selectedRegion?.name_fr
                            : selectedRegion?.name_en}
                        </div>
                        <div className=" rounded-tr-md  rounded-br-md ">
                          {AllSublocalities.map((sublocality) => {
                            const countSub =
                              AllSubLocalityPostCounts[sublocality.id];
                            return (
                              <div
                                key={sublocality.id}
                                className={`sublocality-item flex items-center justify-between cursor-pointer py-1 px-1 lg:px-2 rounded-md ${
                                  selectedSublocality?.name_en ===
                                  sublocality.name_en
                                    ? "bg-[#d8d5d5]"
                                    : "hover:bg-[#d8d5d5]"
                                }`}
                                onClick={() => {
                                  handleSublocalityClick(sublocality);
                                }}
                              >
                                <div
                                  className={`${
                                    selectedRegion
                                      ? "text-sm lg:text-[1rem] p-2"
                                      : ""
                                  }`}
                                >
                                  {userPreferedLang === "FR"
                                    ? sublocality.name_fr
                                    : sublocality.name_en}
                                </div>
                                <div className="ml-2 lg:ml-8">
                                  {isLoadingLocationMain ? (
                                    <>
                                      <div className="w-2 h-2 border border-t-4 border-[#2B9EDA] rounded-full animate-spin"></div>
                                    </>
                                  ) : (
                                    <>
                                      <p className="text-xs ">
                                        <span className="pl-2"> • </span>
                                        <span className="pr-[2.5px]">
                                          {countSub}
                                        </span>
                                        {userPreferedLang === "FR"
                                          ? "Pubs"
                                          : "Ads"}{" "}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}

                {/* SEARCH BUTTON */}
                <div
                  className="lg:w-[80px] w-[60px]  lg:h-[50px] border-l border-[#B7B7B7] text-xl flex justify-center items-center  h-full px-2 lg:px-4 hover:bg-[#2B9EDA] hover:text-white transition-all ease-in-out cursor-pointer"
                  onClick={() => {
                    if (searchTerm !== "") {
                      handleSearchWord(searchTerm, "userPreffered");
                    } else {
                      if (userPreferedLang == "FR") {
                        alert(
                          "Tapez le nom du produit ou du service à rechercher"
                        );
                      } else {
                        alert("Type in product or service name to search.");
                      }
                    }
                  }}
                >
                  <FiSearch />
                </div>
                {/* SEARCH BUTTON */}
              </div>
            </div>
          </div>
        </div>

        {/* POST AN AD ON ICONZ LAPTOP FAR RIGHT BANNER */}
        <LaptopPopup userPreferedLang={userPreferedLang} />
        {/* POST AN AD ON ICONZ LAPTOP FAR RIGHT BANNER */}
      </div>
    </>
  );
}

export default TopBar;
