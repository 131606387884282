import React, { useState, useRef, useEffect } from 'react'
import { BsMegaphoneFill } from "react-icons/bs";
import {AiOutlineCloseCircle} from 'react-icons/ai'
import GFR from '../../assets/google_fr.png'
import GENG from "../../assets/google_play.png";
import AFR from "../../assets/appstor_fr.svg";
import AENG from "../../assets/app_store.png";

function CreateAdButton() {
    const Lang = localStorage.getItem("userPreferedLang");
  const [showPopup, setShowPopup] = useState(false)


  return (
    <>
      <div className="wrapper lg:hidden w-full sticky z-40 bottom-4">
        <div className="button w-full flex justify-center items-center">
          <div
            onClick={() => {
              setShowPopup((prev) => !prev);
            }}
            className="inner cursor-pointer w-[160px] flex justify-evenly items-center h-[40px] rounded-full bg-[#2CA2DF] text-white font-medium text-[15px] shadow-xl "
          >
            {showPopup ? (
              <AiOutlineCloseCircle className="-rotate-45" />
            ) : (
              <BsMegaphoneFill className="-rotate-45" />
            )}

            <p>
              {Lang === "FR"
                ? `${showPopup ? "Fermer" : "Creez une anoonce"}`
                : `${showPopup ? "Close" : "Create An Ad "}`}
            </p>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div
              onClick={() => setShowPopup(false)}
              className="wrapper w-full h-full flex justify-center items-center p-4 "
            >
              <div className="text_info bg-white p-4 rounded-md text-center ">
                <p>
                  {Lang === "FR"
                    ? `Téléchargez iConz pour Android ou iPhone et publiez tout ce que vous voulez vendre en moins d'une minute.`
                    : `Get iConz for Android or iPhone and post anything you want to sell in less than a minute.`}
                </p>

                <div className="icons w-full">
                  <div className="app flex justify-evenly items-center mt-4">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.eladeforwa.letscon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${Lang === "FR" ? GFR : GENG}`}
                        alt="google playStore"
                        className="w-[100px]"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/cm/app/iconz-buy-sell/id6448638809?l=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${Lang === "FR" ? AFR : AENG}`}
                        alt="google playStore"
                        className="w-[100px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreateAdButton