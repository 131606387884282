import React from 'react'

function ElectricSymbolsSupport() {
  return (
    <>
      <div className="w-full mt-[-60px] lg:mt-0 relative">
        <div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
          <h2 className="text-4xl font-bold text-[#2B9EDA]">
            Electrical Symbols Quiz -Support
          </h2>
        </div>

        <div className="md:px-24 px-4">
          <div className="eng w-full">
            <div className="my-4">
              Electrical Symbols Quiz is a quiz game made up of all electrical
              symbols. Each level has 12 electrical symbols that you will have
              to identify.
            </div>

            <div className="my-4">
              You need a minimum of 60 points to cross to the next
              level. <br /> Getting 1 correct answer gives you a score of 5 points, you
              lose 3 points if you give a wrong answer.
            </div>

            <h2 className="my-4 font-bold text-[#2B9EDA]">
              The app is free to download from AppStore.
            </h2>

            <div className="my-4">
              - The first level of the game is free to play, and proceeding will
              need you to subscribe to one of the packages displayed.
            </div>

            <div className="my-4">
             - All features and content are fully accessible with any package the
              user purchased.
            </div>

            <div className="my-4">
             - If you changed your device or switched to a new one after you
              previously purchase access, you can tap the ‘Restore Purchase’
              button to regain full access to the application.
            </div>

            <div className="my-4">
              If you need further assistance, you can contact the developer at
              <a
                href="mailto:eladeforwa@gmail.com"
                target="_blank"
                className="text-[#2B9EDA] underline ml-2"
              >
                eladeforwa@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ElectricSymbolsSupport