
import { getAuth, signInAnonymously } from 'firebase/auth';

// export const signInAnonymouslyAndCheckAuthState = () => {
//   const auth = getAuth();
//   if (auth.currentUser == null) {
//     return signInAnonymously(auth)
//       .then(userCredential => {
//         // Handle the user credential here
//         // console.log("User signed in anonymously:", userCredential.user.uid);
//         return userCredential.user.uid;
//       })
//       .catch(error => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.log(errorCode, errorMessage);
//         return null; // or you can throw an error here
//       });
//   } else {
//     console.log("User already exists");
//     return auth.currentUser.uid;
//   }
// };



export const signInAnonymouslyAndCheckAuthState = () => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      // User is already signed in, resolve with the user ID
      resolve(auth.currentUser.uid);
    } else {
      // Sign in the user anonymously
      signInAnonymously(auth)
        .then((userCredential) => {
          // User is signed in anonymously, resolve with the user ID
          resolve(userCredential.user.uid);
        })
        .catch((error) => {
          // An error occurred, reject with the error
          reject(error);
        });
    }
  });
};