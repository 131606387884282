import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaUserLock,
  FaInfo,
  FaHome,
  FaInfoCircle,
  FaTiktok,
} from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import {
  RiFacebookCircleFill,
  RiInstagramFill,
  RiTwitterFill,
  RiYoutubeFill,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import * as tf from "../utils/translations";

function SideMenu({ setShowMenu, userPreferedLang, handleIconzLogoclick }) {
  const popupRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      const width = window.innerWidth;
      if (
        width < 768 &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, setShowMenu]);

  return (
    <>
      {/* Sidebar content */}
      <div ref={popupRef} className="p-2 text-sm lg:text-[1rem]">
        <ul className="my-2">
          <Link
            to={`/`}
            onClick={() => {
              setActiveMenu("HOME");
              handleIconzLogoclick();
              // IF ON MOBILE HIDE SIDE MENU
              if (window.innerWidth <= 768) {
                setShowMenu(false);
              }
            }}
            className={`${
              activeMenu === "HOME" || activeMenu === null
                ? " bg-[#F0F0F0] text-[#2B9EDA]"
                : " hover:bg-[#F0F0F0] hover:text-[#2B9EDA]"
            } flex items-center border p-2 cursor-pointer  transition-all ease-in-out rounded-md`}
          >
            <FaHome className="mx-2 text-xl" />
            <p>{userPreferedLang == "FR" ? tf.HomeFr : "Home"}</p>
          </Link>
          <Link
            to="/about-us"
            onClick={() => {
              setActiveMenu("ABOUT");
              // IF ON MOBILE HIDE SIDE MENU
              if (window.innerWidth <= 768) {
                setShowMenu(false);
              }
            }}
            className={`${
              activeMenu === "ABOUT"
                ? " bg-[#F0F0F0] text-[#2B9EDA]"
                : " hover:bg-[#F0F0F0] hover:text-[#2B9EDA]"
            } my-2 flex items-center cursor-pointer border p-2   transition-all ease-in-out rounded-md`}
          >
            <FaInfoCircle className="mx-2  text-xl" />
            <p>{userPreferedLang == "FR" ? tf.AboutFr : "About iConz"}</p>
          </Link>

          <Link
            to="/how-to-sell-on-iconz"
            onClick={() => {
              setActiveMenu("HTSOI");
              // IF ON MOBILE HIDE SIDE MENU
              if (window.innerWidth <= 768) {
                setShowMenu(false);
              }
            }}
            className={`${
              activeMenu === "HTSOI"
                ? " bg-[#F0F0F0] text-[#2B9EDA]"
                : " hover:bg-[#F0F0F0] hover:text-[#2B9EDA]"
            }  flex items-center cursor-pointer border p-2   transition-all ease-in-out rounded-md`}
          >
            <GiCash className="mx-2  text-xl" />
            <p>
              {userPreferedLang == "FR"
                ? tf.howToSellFr
                : "How to sell on iConz"}
            </p>
          </Link>
        </ul>

        {/* divider */}
        <div className="w-full border border-gray-200"> </div>

        {/* social Media */}
        <ul className="my-2">
          <h3 className="text-center mb-2 font-medium">
            {userPreferedLang == "FR"
              ? tf.our_socialsFr
              : "Our Social Media Handles"}
          </h3>
          <li className=" border-transparent border p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://www.facebook.com/app.iconz?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <RiFacebookCircleFill className="mx-2 text-blue-500  text-xl" />
              <p>@iConz</p>
            </a>
          </li>
          <li className=" border-transparent cursor-pointer border p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://instagram.com/iconz_app?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <RiInstagramFill className="mx-2 text-red-500  text-xl" />
              <p>@iConz_app</p>
            </a>
          </li>
          <li className=" border-transparent border cursor-pointer p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://www.tiktok.com/@iconz_app"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <FaTiktok className="mx-2 text-black  text-xl" />
              <p>@iConz</p>
            </a>
          </li>
          <li className=" border-transparent border cursor-pointer p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://twitter.com/iConz_app?t=tdsvocXHaiMEJ5urnTi1dQ&s=09"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <RiTwitterFill className="mx-2 text-blue-500  text-xl" />
              <p>@iConz_app</p>
            </a>
          </li>
          <li className=" border-transparent border cursor-pointer p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://www.linkedin.com/company/letscon/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <RiLinkedinBoxFill className="mx-2 text-blue-500  text-xl" />
              <p>@iConz</p>
            </a>
          </li>
          <li className=" border-transparent border  cursor-pointer p-2 hover:bg-[#F0F0F0] hover:text-[#2B9EDA] transition-all ease-in-out rounded-md">
            <a
              href="https://youtube.com/@iconz_app"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <RiYoutubeFill className="mx-2 text-red-500  text-xl " />
              <p>@iConz_app</p>
            </a>
          </li>
        </ul>

        {/* divider */}
        <div className="w-full border border-gray-200"> </div>

        <ul className="my-2">
          <Link
            to="/terms-and-conditions"
            onClick={() => {
              setActiveMenu("TNC");
              // IF ON MOBILE HIDE SIDE MENU
              if (window.innerWidth <= 768) {
                setShowMenu(false);
              }
            }}
            className={`${
              activeMenu === "TNC"
                ? " bg-[#F0F0F0] text-[#2B9EDA]"
                : " hover:bg-[#F0F0F0] hover:text-[#2B9EDA]"
            }  flex items-center border p-2 cursor-pointer  transition-all ease-in-out rounded-md`}
          >
            <FaInfo className="mx-2  text-xl" />
            <p>
              {userPreferedLang == "FR"
                ? tf.TermsNConditionsFr
                : "Terms & Conditions"}
            </p>
          </Link>

          <Link
            to="/privacy-policy"
            onClick={() => {
              setActiveMenu("PRIPO");
              // IF ON MOBILE HIDE SIDE MENU
              if (window.innerWidth <= 768) {
                setShowMenu(false);
              }
            }}
            className={`${
              activeMenu === "PRIPO"
                ? " bg-[#F0F0F0] text-[#2B9EDA]"
                : " hover:bg-[#F0F0F0] hover:text-[#2B9EDA]"
            } my-2 flex items-center border p-2  cursor-pointer transition-all ease-in-out rounded-md`}
          >
            <FaUserLock className="mx-2  text-xl" />
            <p>
              {userPreferedLang == "FR" ? tf.PrivacyPolicyFr : "Privacy Policy"}
            </p>
          </Link>
        </ul>
      </div>
    </>
  );
}

export default SideMenu;
