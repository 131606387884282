import React from 'react'
import * as tf from '../utils/translations'
import { Link } from 'react-router-dom'
import Seo from '../utils/Seo'


function TermsNCons({userPreferedLang}) {
  return (
    <>
    {/* SEO TAG */}
       
    <Seo
     title={userPreferedLang == 'FR' ? tf.TermsNConditionsFr : 'Terms and Conditions'}
     description={userPreferedLang == 'FR' ? tf.TermsNConditionsFr : 'Terms and Conditions'}
     />
    
   <div className="w-full mt-[-60px] lg:mt-0 relative">

   <div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
      <h2 className='text-3xl font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? tf.TermsNConditionsFr : 'Terms and Conditions'}</h2>
    </div>

  <div className="md:px-24 px-4">

      {
      userPreferedLang == "FR" ?(
      <>
      <div className="fr">
<div className="">
   <h2 className=' font-bold text-[#2B9EDA]'>
   iConz Ltd’s Conditions d’utilisation <br />
[19/05/2023]
    </h2>
 </div>

 <br />
Bienvenue dans les conditions d’utilisation de [iConz Ltd]. Aux fins du présent accord, le terme
« Site » désigne le site Web de la Société, accessible à l’adresse [iconzapp.web] [ou via iConz,
notre application mobile]. « Service » désigne les services de la Société accessibles via le Site,
dans lesquels les utilisateurs peuvent [acheter ou vendre n’importe quoi]. Les termes « nous », «
notre » et « nos » font référence à la Société. « Vous » fait référence à vous, en tant qu’utilisateur
de notre Site ou de notre Service. <br /> <br />
Les conditions d’utilisation suivantes s’appliquent lorsque vous consultez ou utilisez le Service
[via notre site Web situé à l’adresse [iconzapp.web] [ou en accédant au Service en cliquant sur
l’application (l'« Application iConz ») sur votre appareil mobile]. <br /> <br />
Veuillez lire attentivement les conditions suivantes. En accédant ou en utilisant le Service, vous
signifiez votre acceptation des présentes Conditions d’utilisation. Si vous n’acceptez pas d’être
lié par les présentes Conditions d’utilisation dans leur intégralité, vous ne pouvez pas accéder au
Service ni l’utiliser. <br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
POLITIQUE DE CONFIDENTIALITÉ
    </h2>

La Société respecte la vie privée des utilisateurs de son Service. Veuillez-vous référer à la
politique de confidentialité de la société (disponible içi: [insérer le lien vers la politique de
confidentialité]) qui explique comment nous recueillons, utilisons et divulguons les informations
relatives à votre vie privée. Lorsque vous accédez ou utilisez le Service, vous signifiez votre
accord à la Politique de confidentialité ainsi qu’aux présentes Conditions d’utilisation.

<h2 className='my-4 font-bold text-[#2B9EDA]'>
À PROPOS DU SERVICE
    </h2>
Le Service permet aux utilisateurs [d’acheter ou de vendre n’importe quoi en ligne, car nous
connectons les acheteurs aux vendeurs par le biais d’annonces publiées par les vendeurs de
toutes sortes de produits et services en cours d’examen et d’approbation, afin que les acheteurs
puissent voir et contacter directement les vendeurs pour les produits et / ou services offerts].

<h2 className='my-4 font-bold text-[#2B9EDA]'>
INSCRIPTION; RÈGLES DE CONDUITE DE L’UTILISATEUR ET D’UTILISATION
DU SERVICE
    </h2>
Vous devez être âgé d’au moins [18 ans] et résider au Cameroun pour vous inscrire et utiliser le
Service. <br /> <br />
Si vous êtes un utilisateur qui s’inscrit au Service, vous créerez un compte personnalisé qui
comprend un nom d’utilisateur unique et un mot de passe pour accéder au Service et recevoir des
messages de la Société. Vous acceptez de nous informer immédiatement de toute utilisation non
autorisée de votre mot de passe et/ou de votre compte. La Société ne sera pas responsable des
responsabilités, pertes ou dommages découlant de l’utilisation non autorisée de votre nom de
membre, de votre mot de passe et / ou de votre compte. <br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
RESTRICTIONS D’UTILISATION
    </h2>
Votre autorisation d’utiliser le Site est conditionnelle aux restrictions d’utilisation, d’affichage et
de conduite suivantes : <br />
En tant que vendeur, vous acceptez de le faire ; <br /> <br />
• Suivez les directives de publication par lesquelles, vous ajoutez un titre approprié au
produit que vous cherchez à annoncer, joignez le prix réel du produit indiquant s’il est
négociable ou fixe, indiquez l’état réel d’un produit, joignez des images réelles et très
claires du produit, des images non téléchargées sur Internet et décrivez votre produit de
manière très explicite pour attirer vos clients sans autre questionnement de leur part. <br /> <br />
• Sélectionnez la bonne catégorie, sous-catégorie, type, marque, marque, état, sexe,
capacité de stockage, etc. pour le produit ou le service que vous souhaitez annoncer.<br /> <br />
• Donnez suffisamment d’informations pour que vos clients puissent vous localiser.<br /> <br />
• Lisez toujours attentivement les informations qui vous sont envoyées dans vos
notifications et appliquez des corrections si nécessaire avant de déposer une plainte.<br /> <br />
• Posséder le produit ou le service que vous cherchez à annoncer, soyez honnête et agissez
humainement.<br /> <br />
• Suivez les procédures nécessaires données, pour acquérir du crédit publicitaire et pour
obtenir une vérification.<br /> <br />
Vous vous engagez à n’en aucun cas :
• Accéder au Service pour toute raison autre que votre usage personnel et commercial
uniquement dans la mesure permise par la fonctionnalité normale du Service.<br /> <br />
• Collecter ou récolter des données personnelles de tout utilisateur du Site ou du Service.<br /> <br />
• Distribuer une ou plusieurs parties du Site ou du Service sans notre autorisation écrite
explicite.<br /> <br />
• Utiliser le Service à des fins illégales ou pour la promotion d’activités illégales.<br /> <br />
• Tenter de, ou harceler, abuser ou nuire à une autre personne ou à un groupe.<br /> <br />
• Utiliser le compte d’un autre utilisateur sans autorisation.<br /> <br />
• Permettre intentionnellement à un autre utilisateur d’accéder à votre compte.<br /> <br />
• Fournir des informations fausses ou inexactes lors de l’enregistrement d’un compte.<br /> <br />
• Interférer ou tenter d’interférer avec le bon fonctionnement du Service.<br /> <br />
• Contourner, désactiver ou interférer de toute autre manière avec les fonctionnalités liées à
la sécurité du Service ou les fonctionnalités qui empêchent ou restreignent l’utilisation ou
la copie du contenu, ou appliquent des limitations sur l’utilisation du Service ou du
contenu accessible via le Service.<br /> <br />
• Publier ou créer un lien vers du contenu malveillant de quelque nature que ce soit, y
compris celui destiné à endommager ou à perturber le navigateur ou l’ordinateur d’un
autre utilisateur.<br /> <br />
• Utilisez l’application avec l’intention d’arnaquer les acheteurs disponibles avec des
produits qui n’existent pas.<br /> <br />
• Classez un produit ou un service dans la mauvaise catégorie, le mauvais type, la
mauvaise marque ou la mauvaise capacité de stockage.<br /> <br />
• Exiger un remboursement pour tout montant payer pour mettre en avant une publicité.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
RESTRICTIONS RELATIVES À L’AFFICHAGE ET À LA CONDUITE
    </h2>
Lorsque vous créez votre propre compte personnalisé, vous pouvez être en mesure de fournir
[informations sur votre nom, votre adresse e-mail, votre emplacement, l’adresse de votre
magasin, le numéro de contact pour les appels et la messagerie WhatsApp et une photo de
profil.] (« Contenu utilisateur ») au Service. Vous êtes seul responsable du Contenu utilisateur
que vous publiez, téléchargez, liez ou rendez disponible via le Service. <br /> <br />
Vous convenez que nous n’agissons qu’en tant que canal passif pour votre distribution et
publication en ligne de votre Contenu utilisateur.<br /> <br />
 La Société se réserve toutefois le droit de
supprimer tout Contenu Utilisateur du Service à sa seule discrétion.
Nous vous accordons la permission d’utiliser et d’accéder au Service, sous réserve des conditions
expresses suivantes concernant le Contenu utilisateur. Vous acceptez que le non-respect de l’une
de ces conditions constitue une violation substantielle des présentes Conditions.
En transmettant et en soumettant tout Contenu utilisateur lors de l’utilisation du Service, vous
acceptez ce qui suit :<br /> <br />
· Vous êtes seul responsable de votre compte et de l’activité qui se produit lorsque vous êtes
connecté à votre compte ou que vous l’utilisez ;<br /> <br />
· Vous ne publierez pas d’informations malveillantes, diffamatoires, fausses ou inexactes<br /> <br />
· Vous ne publierez aucune information abusive, menaçante, obscène, diffamatoire,
calomnieuse ou raciale, sexuelle, religieuse ou autrement répréhensible et offensante.<br /> <br />
Vous conservez tous les droits de propriété sur votre Contenu utilisateur, mais vous êtes tenu
d’accorder les droits suivants sur le Site et aux utilisateurs du Service, comme indiqué plus en
détail dans les dispositions « Octroi de licence » et « Propriété intellectuelle » ci-dessous :<br />
Lorsque vous téléchargez ou publiez du Contenu utilisateur sur le Site, vous accordez au Site, un
licence non exclusive, libre de redevance et transférable d’utiliser, de reproduire, de distribuer,
de préparer des œuvres dérivées, d’afficher et d’exécuter ce Contenu dans le cadre de la
fourniture du Service; et vous accordez à chaque outil une licence mondiale, non exclusive et
libre de redevance pour accéder à votre Contenu utilisateur par le biais du Service, et pour
utiliser, reproduire, distribuer,<br /> <br /> préparer des œuvres dérivées, afficher et exécuter ce Contenu dans
la mesure permise par le Service et en vertu des présentes Conditions d’utilisation ;
Vous ne soumettrez pas de contenu protégé par des droits d’auteur ou soumis à des droits de
propriété de tiers, y compris la confidentialité, la publicité, le secret commercial ou autres, sauf si
vous êtes le propriétaire de ces droits ou si vous avez l’autorisation appropriée de leur
propriétaire légitime pour soumettre spécifiquement ce contenu, et
Vous convenez par la présente que nous avons le droit de déterminer si vos soumissions de
Contenu utilisateur sont appropriées et conformes aux présentes Conditions d’utilisation, de
supprimer tout ou partie de vos soumissions et de résilier votre compte avec ou sans préavis.
Vous comprenez et acceptez que toute responsabilité, perte ou dommage résultant de l’utilisation
de tout Contenu utilisateur que vous mettez à disposition ou auquel vous accédez par le biais de
votre utilisation du Service relève de votre seule responsabilité.<br /> <br /> Le Site n’est pas responsable de
tout affichage public ou mauvaise utilisation de votre Contenu utilisateur.
Le Site ne présélectionne pas ou ne peut pas présélectionner ou surveiller tout le Contenu
utilisateur. Cependant, à notre discrétion, nous, ou la technologie que nous employons, pouvons
surveiller et / ou enregistrer vos interactions avec le Service ou avec d’autres Utilisateurs.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
CLAUSE DE NON-RESPONSABILITÉ RELATIVE AU CONTENU EN LIGNE
    </h2>
Les opinions, conseils, déclarations, offres ou autres informations ou contenus mis à disposition
par le biais du Service, mais pas directement par le Site, sont ceux de leurs auteurs respectifs et
ne doivent pas nécessairement être invoqués. Ces auteurs sont seuls responsables de ce contenu.<br /> <br />
Nous ne garantissons pas l’exactitude, l’exhaustivité ou l’utilité de toute information sur le Site
ou le Service, et nous n’adoptons ni n’approuvons, ni ne sommes responsables de l’exactitude ou
de la fiabilité de toute opinion, conseil ou déclaration faite par d’autres parties.<br /> <br />  Nous ne prenons
aucune responsabilité et n’assumons aucune responsabilité pour tout Contenu utilisateur que
vous ou tout autre utilisateur ou tiers publiez ou envoyez via le Service.<br /> <br /> En aucun cas, nous ne
serons responsables de toute perte ou dommage résultant de la confiance accordée par quiconque
aux informations ou autres contenus publiés sur le Service, ou transmis aux utilisateurs.<br /> <br />
Bien que nous nous efforcions de faire respecter les présentes Conditions d’utilisation, vous
pouvez être exposé à un Contenu utilisateur inexact ou répréhensible lorsque vous utilisez ou
accédez à l’Application. La Société aura le droit de supprimer tout matériel qui, à son seul avis,
viole ou est présumé violer la loi ou le présent accord ou qui pourrait être offensant, ou qui
pourrait violer les droits, nuire ou menacer la sécurité des utilisateurs ou d’autres personnes.<br /> <br />
L’utilisation non autorisée peut entraîner des poursuites pénales et/ou civiles en vertu de la
législation nationale et locale. Si vous avez connaissance d’une mauvaise utilisation de notre
Service ou d’une violation des présentes Conditions d’utilisation, veuillez nous contacter
<a href="mailto:" target='_blank' className='text-[#2B9EDA] underline ml-2'>feedback.iconz@gmail.com</a>.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
LIENS VERS D’AUTRES SITES ET/OU DOCUMENTS
    </h2>

Dans le cadre du Service, nous pouvons vous fournir des liens pratiques vers nos pages   <a href="https://www.facebook.com/app.iconz?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a> , sur Instagram- <a href="https://instagram.com/iconz_app?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iconz_app</a> , sur Tiktok- <a href="https://www.tiktok.com/@iconz_app" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a> @iConz, on Twitter- <a href="https://twitter.com/iConz_app?t=tdsvocXHaiMEJ5urnTi1dQ&s=09" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz_app</a> , sur LinkedIn-
  <a href="https://www.linkedin.com/company/letscon/" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a>  et sur YouTube- @iconz_app<a href="https://youtube.com/@iconz_app" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz_app</a>. Ces liens sont fournis à
titre gracieux aux abonnés du Service. <br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
PLAINTES RELATIVES AUX DROITS D’AUTEUR ET AGENT DES DROITS
D’AUTEUR
    </h2>
(a) Résiliation des comptes de récidive. Nous respectons les droits de propriété intellectuelle
d’autrui et exigeons que les utilisateurs fassent de même. Conformément à l’article 17 U.S.C.
512(i) de la loi américaine sur le droit d’auteur, nous avons adopté et mis en œuvre une politique
qui prévoit la résiliation, dans des circonstances appropriées, des utilisateurs du Service qui sont
des contrevenants répétés. Nous pouvons mettre fin à l’accès des participants ou des utilisateurs
qui fournissent ou publient à plusieurs reprises du contenu tiers protégé sans les droits et
autorisations nécessaires.<br /> <br />
(b) Avis de retrait DMCA. Si vous êtes propriétaire d’un droit d’auteur ou l’un de ses agents et
croyez, de bonne foi, que tout matériel fourni sur le Service enfreint vos droits d’auteur, OU peut
soumettre une notification en envoyant les informations suivantes par écrit à notre agent des
droits d’auteur désigné à <a href="mailto:" target='_blank' className='text-[#2B9EDA] underline ml-2'>feedback.iconz@gmail.com</a>.<br /> <br />
1. La date de votre notification.<br /> <br />
2. Une signature physique ou électronique d’une personne autorisée à agir au nom du
propriétaire d’un droit exclusif prétendument violé.<br /> <br />
3. Une description de l’œuvre protégée par le droit d’auteur prétendument violée ou, si plusieurs
œuvres protégées par le droit d’auteur sur un seul site en ligne sont couvertes par une seule
notification, une liste représentative de ces œuvres sur ce site.<br /> <br />
4. Une description du matériel prétendument contrefait ou faisant l’objet d’une activité de
contrefaçon et des informations suffisantes pour nous permettre de localiser cette œuvre.<br /> <br />
5. Des informations raisonnablement suffisantes pour permettre au fournisseur de services de
vous contacter, telles qu’une adresse, un numéro de téléphone et/ou une adresse e-mail.<br /> <br />
6. Une déclaration selon laquelle vous croyez de bonne foi que l’utilisation du matériel de la
manière faisant l’objet de la plainte n’est pas autorisée par le propriétaire du droit d’auteur, son
agent ou la loi. et<br /> <br />
7. Une déclaration selon laquelle les informations contenues dans la notification sont exactes et,
sous peine de parjure, que vous êtes autorisé à agir au nom du propriétaire d’un droit exclusif
prétendument violé.<br /> <br />
(c) Contre-notifications. Si vous pensez que votre Contenu utilisateur qui a été retiré du Site ne
constitue pas une violation ou que vous avez l’autorisation du propriétaire des droits d’auteur,
l’agent du titulaire du droit d’auteur, ou conformément à la loi, pour publier et utiliser le contenu
de votre Contenu utilisateur, vous pouvez envoyer un contre-avis contenant les informations
suivantes à notre agent des droits d’auteur en utilisant les coordonnées indiquées ci-dessus :<br /> <br />
1. Votre signature physique ou électronique ;<br /> <br />
2. Une description du contenu qui a été supprimé et l’emplacement où le contenu apparaissait
avant qu’il ne soit supprimé.<br /> <br />
3. Une déclaration selon laquelle vous croyez de bonne foi que le contenu a été supprimé à la
suite d’une erreur ou d’une mauvaise identification du contenu.<br /> <br /> Et
4. Votre nom, adresse, numéro de téléphone et adresse électronique, une déclaration selon
laquelle vous consentez à la compétence de la Haute Cour de [Buea, Région du Sud-Ouest,
Cameroun] et une déclaration selon laquelle vous accepterez la signification de la procédure de
la personne qui a fourni la notification de la violation alléguée.<br /> <br />
Si un contre-avis est reçu par notre agent des droits d’auteur, nous pouvons envoyer une copie du
contre-avis à la partie plaignante initiale informant cette personne qu’elle peut rétablir le contenu
supprimé dans les dix (10) jours ouvrables. À moins que le titulaire du droit d’auteur ne dépose
une action en justice contre le fournisseur de contenu, le membre ou l’utilisateur, le contenu
supprimé peut (à notre seule discrétion) être rétabli sur le site dans un délai de dix (10) à
quatorze (14) jours ouvrables ou plus après réception du contre-avis.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
OCTROI DE LICENCE
    </h2>
En publiant tout Contenu utilisateur via le Service, vous accordez expressément, et vous déclarez
et garantissez que vous avez le droit d’accorder, à la Société une licence libre de redevances,
sous-licenciable, transférable, perpétuelle, irrévocable, non exclusive et mondiale pour utiliser,
reproduire, modifier, publier, répertorier les informations concernant, éditer, traduire, distribuer,
exécuter publiquement, afficher publiquement, et créer des œuvres dérivées de tout ce Contenu
utilisateur et de votre nom, voix et/ou ressemblance tels qu’ils sont contenus dans votre Contenu
utilisateur, le cas échéant, en tout ou en partie, et sous toute forme, média ou technologie, qu’ils
soient connus ou développés ultérieurement, pour une utilisation en relation avec le Service.
<h2 className='my-4 font-bold text-[#2B9EDA]'>
PROPRIÉTÉ INTELLECTUELLE
    </h2>
Vous reconnaissez et acceptez que nous et nos concédants de licence conservons la propriété de
tous les droits de propriété intellectuelle de toute natures liées au Service, y compris les droits
d’auteur, marques de commerce et autres droits de propriété applicables. Les autres noms de
produits et de sociétés mentionnés sur le Service peuvent être des marques commerciales de leurs
propriétaires respectifs. Nous nous réservons tous les droits qui ne vous sont pas expressément
accordés en vertu des présentes Conditions d’utilisation.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
LE COURRIER ÉLECTRONIQUE NE PEUT PAS ÊTRE UTILISÉ POUR FOURNIR
UN AVIS
    </h2>
Les communications effectuées par le biais du système de courrier électronique et de messagerie
du Service ne constitueront pas un avis juridique au Site, au Service ou à l’un de ses dirigeants,
employés, agents ou représentants dans toute situation où un avis juridique est requis par contrat
ou toute loi ou règlement.<br /> <br />

<h2 className='my-2  font-bold text-[#2B9EDA]'>
CONSENTEMENT DE L’UTILISATEUR À RECEVOIR DES COMMUNICATIONS
SOUS FORME ÉLECTRONIQUE
    </h2>
À des fins contractuelles, vous : <br /> <br /> (a) consentez à recevoir des communications de notre part sous
forme électronique via l’adresse électronique que vous avez soumise ; et <br /> <br /> (b) acceptez que toutes
les conditions d’utilisation, accords, avis, divulgations et autres communications que nous vous
fournissons par voie électronique satisfont à toute exigence légale que ces communications
satisferaient si elles étaient écrites. Ce qui précède n’affecte pas vos droits auxquels vous ne
pouvez pas renoncer.<br /> <br />
Nous pouvons également utiliser votre adresse e-mail pour vous envoyer d’autres messages, y
compris des informations sur le Site ou le Service et des offres spéciales. Vous pouvez vous
désabonner de ces e-mails en modifiant les paramètres de votre compte, en utilisant le lien « Se
désabonner » dans le message ou en envoyant un e-mail à [feedback.iconz@gmail.com]
Le retrait peut vous empêcher de recevoir des messages concernant le Site, le Service ou des
offres spéciales.<br /> <br />
EXCLUSION DE GARANTIE
LE SERVICE EST FOURNI « TEL QUEL », SANS GARANTIE D’AUCUNE SORTE. SANS
LIMITER CE QUI PRÉCÈDE, NOUS DÉCLINONS EXPRESSÉMENT TOUTE GARANTIE,
QU’ELLE SOIT EXPRESSE, IMPLICITE OU LÉGALE, CONCERNANT LE SERVICE, Y
COMPRIS, SANS LIMITATION, TOUTE GARANTIE DE QUALITÉ MARCHANDE,
D’ADÉQUATION À UN USAGE PARTICULIER, DE TITRE, DE SÉCURITÉ,
D’EXACTITUDE ET D’ABSENCE DE CONTREFAÇON. SANS LIMITER CE QUI
PRÉCÈDE, NOUS NE GARANTISSONS NI NE FAISONS AUCUNE DÉCLARATION QUE
L’ACCÈS OU LE FONCTIONNEMENT DU SERVICE SERA ININTERROMPU OU
EXEMPT D’ERREURS. VOUS ASSUMEZ L’ENTIÈRE RESPONSABILITÉ ET LE RISQUE
DE PERTE RÉSULTANT DE VOTRE TÉLÉCHARGEMENT ET / OU DE VOTRE
UTILISATION DE FICHIERS, INFORMATIONS, CONTENUS OU AUTRES DOCUMENTS
OBTENUS À PARTIR DU SERVICE. CERTAINES JURIDICTIONS LIMITENT OU
N’AUTORISENT PAS LES EXCLUSIONS DE GARANTIE, DE SORTE QUE CETTE
DISPOSITION PEUT NE PAS S’APPLIQUER À VOUS.<br /> <br />
LIMITATION DES DOMMAGES; LIBÉRER
DANS LA MESURE PERMISE PAR LA LOI APPLICABLE, EN AUCUN CAS LE SITE, LE
SERVICE, SES SOCIÉTÉS AFFILIÉES, ADMINISTRATEURS OU EMPLOYÉS, OU SES
CONCÉDANTS DE LICENCE OU PARTENAIRES, NE PEUVENT ÊTRE TENUS
RESPONSABLES ENVERS VOUS DE TOUTE PERTE DE PROFITS, D’UTILISATION OU
DE DONNÉES, OU DE TOUT DOMMAGE ACCESSOIRE, INDIRECT, SPÉCIAL,
CONSÉCUTIF OU EXEMPLAIRE, QUELLE QU’EN SOIT LA CAUSE, RÉSULTANT DE :
(A) L’UTILISATION, LA DIVULGATION OU L’AFFICHAGE DE VOTRE CONTENU
UTILISATEUR ; (B) VOTRE UTILISATION OU VOTRE INCAPACITÉ À UTILISER LE
SERVICE; (C) LE SERVICE EN GÉNÉRAL OU LE OU LES LOGICIELS OU SYSTÈMES
QUI RENDENT LE SERVICE DISPONIBLE; OU (D) TOUTE AUTRE INTERACTION
AVEC L’UTILISATION OU AVEC TOUT AUTRE UTILISATEUR DU SERVICE, QUE CE
SOIT SUR LA BASE D’UNE GARANTIE, D’UN CONTRAT, D’UN DÉLIT (Y COMPRIS
LA NÉGLIGENCE) OU TOUTE AUTRE THÉORIE JURIDIQUE, ET SI NOUS AVONS ÉTÉ
INFORMÉS OU NON DE LA POSSIBILITÉ DE TELS DOMMAGES, ET MÊME SI UN
RECOURS ÉNONCÉ DANS LES PRÉSENTES S’AVÈRE AVOIR ÉCHOUÉ DANS SON
OBJECTIF ESSENTIEL.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
MODIFICATION DES CONDITIONS D’UTILISATION
    </h2>
Nous pouvons modifier ces Conditions d’utilisation à tout moment et mettrons à jour ces
Conditions d’utilisation dans le cas de telles modifications. Il est de votre seule responsabilité de
vérifier le Site de temps à autre pour voir de telles modifications dans cet accord. Votre
utilisation continue du Site ou du Service signifie que vous acceptez nos révisions des présentes
Conditions d’utilisation. Nous nous efforcerons de vous informer des modifications importantes
apportées aux Conditions en publiant un avis sur notre page d’accueil et/ou en envoyant un email à l’adresse e-mail que vous nous avez fournie lors de votre inscription. Pour cette raison
supplémentaire, vous devez garder vos coordonnées et vos informations de profil à jour.<br /> <br /> Toute
modification apportée aux présentes Conditions (autre que celui énoncé dans le présent
paragraphe) ou la renonciation à nos droits en vertu des présentes ne sera ni valide ni effective,
sauf dans un accord écrit portant la signature physique de l’un de nos dirigeants. Aucune
prétendue renonciation ou modification de cet accord de notre part par téléphone ou par courrier
électronique ne sera valide.<br /> <br />

<h2 className='my-4 font-bold text-[#2B9EDA]'>
CONDITIONS GÉNÉRALES
    </h2>
Si une partie de ces conditions d’utilisation est jugée invalide ou inapplicable, cette partie de
l’accord sera interprétée comme étant conforme à la loi applicable, tandis que les autres parties
de l’accord resteront pleinement en vigueur. Tout manquement de notre part à appliquer une
disposition du présent accord ne sera pas considéré comme une renonciation à notre droit
d’appliquer cette disposition. Nos droits en vertu du présent contrat survivent à tout transfert ou
résiliation du présent accord.<br /> <br />
Vous acceptez que toute cause d’action liée à ou découlant de votre relation avec la Société doit
commencer dans l’ANNÉE suivant la cause d’action. Dans le cas contraire, une telle cause
d’action est définitivement prescrite.<br /> <br />
Les présentes Conditions d’utilisation et votre utilisation du Site sont régies par les lois fédérales
des États-Unis d’Amérique et les lois de la République du Cameroun, sans égard aux
dispositions relatives aux conflits de lois.<br /> <br />
Nous pouvons céder ou déléguer les présentes Conditions d’utilisation et/ou notre Politique de
confidentialité, en tout ou en partie, à toute personne ou entité à tout moment, avec ou sans votre
consentement.<br /> <br />
 Vous ne pouvez pas céder ou déléguer des droits ou obligations en vertu des
Conditions d’utilisation ou de la Politique de confidentialité sans notre consentement écrit
préalable, et toute cession ou délégation non autorisée de votre part est nulle.<br /> <br />
VOUS RECONNAISSEZ QUE VOUS AVEZ LU LES PRÉSENTES CONDITIONS
D’UTILISATION, QUE VOUS COMPRENEZ LES CONDITIONS D’UTILISATION ET QUE
VOUS SEREZ LIÉ PAR CES CONDITIONS GÉNÉRALES. VOUS RECONNAISSEZ EN
OUTRE QUE LES PRÉSENTES CONDITIONS D’UTILISATION AINSI QUE LA
POLITIQUE DE CONFIDENTIALITÉ FIGURANT À <Link to={'/privacypolicy'} className='text-[#2B9EDA] underline'><span>POLITIQUE DE CONFIDENTIALITÉ</span></Link> REPRÉSENTENT LA DÉCLARATION COMPLÈTE
ET EXCLUSIVE DE L’ACCORD ENTRE NOUS ET QU’ELLES REMPLACENT TOUTE
PROPOSITION OU ACCORD ANTÉRIEUR ORAL OU ÉCRIT, ET TOUTE AUTRE
COMMUNICATION ENTRE NOUS RELATIVE À L’OBJET DU PRÉSENT ACCORD.
      </div>
      </>
      )
      :(
        <>
        <div className="eng">
<div className="">
   <h2 className=' font-bold text-[#2B9EDA]'>
   iConz Ltd’s Terms of Use <br></br>
[19/05/2023]
    </h2>
 </div>

<div className="my-2">

</div>
<div className="my-2">
Welcome to the iConz Ltd’s Terms of Use agreement. For purposes of this agreement, “Site”
refers to the Company’s website, which can be accessed at iconzapp.web or through iConz,
our mobile application. “Service” refers to the Company’s services accessed via the Site, in
which users can buy or sell anything. The terms “we,” “us,” and “our” refer to the Company.
“You” refers to you, as a user of our Site or our Service.
</div>
<div className="my-4">
The following Terms of Use apply when you view or use the Service via our website located at
[iconzapp.web] or by accessing the Service through clicking on the application (the “iConz
App”) on your mobile device.
</div>
<div className="my-2">
Please review the following terms carefully. By accessing or using the Service, you signify your
agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in
their entirety, you may not access or use the Service.
</div>

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
PRIVACY POLICY
    </h2>

    <div className="my-2">
    The Company respects the privacy of its Service users. Please refer to the Company’s Privacy
Policy (found here: [insert Link to Privacy Policy]) which explains how we collect, use, and
disclose information that pertains to your privacy. When you access or use the Service, you
signify your agreement to the Privacy Policy as well as these Terms of Use.
</div>

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
ABOUT THE SERVICE
    </h2>

    <div className="my-2">
    The Service allows users to [buy or sell anything online, as we connect buyers to sellers through
Ads published by sellers of all sorts of products and services under due examination and
approval, so buyers can see and contact sellers directly for the products and/or services
offered].
</div>

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE
    </h2>

    <div className="my-2">
    You need to be at least 18 years old and a resident of Cameroon to register for and use the
Service.
If you are a user who signs up for the Service, you will create a personalized account which
includes a unique username and a password to access the Service and to receive messages from
the Company. You agree to notify us immediately of any unauthorized use of your password
and/or account. The Company will not be responsible for any liabilities, losses, or damages
arising out of the unauthorized use of your member name, password and/or account.
</div>


<h2 className=' my-4 font-bold text-[#2B9EDA]'>
USE RESTRICTIONS
    </h2>

    <div className="my-2">
    Your permission to use the Site is conditioned upon the following use, posting and conduct
restrictions: <br />
As a seller, you agree that you will;
</div>

<ul>
  <li>
  ● Follow the posting guidelines whereby, you add a befitting title to the product you seek
to advertise, attach the real price of the product stating if it is negotiable or fixed, state
the real condition of a product, attach real and very clear images of the product, not
downloaded images from the internet and describe your product in a very explicit
manner to attract your customers without further questioning from them.
  </li>
  <li>
  ● Select the right category, sub category, type, mark, brand, condition, gender, storage
capacity, etc for the product or service you seek to advertise.
  </li>
  <li>
  ● Give information sufficient enough for your customers to locate you.
  </li>
  <li>
  ● Always read carefully the information sent to you in your notifications and apply
corrections where necessary before bringing up complaints.
  </li>
  <li>
  ● Possess the product or service you seek to advertise, be honest and act humanely.
  </li>
  <li>
  ● Follow the necessary procedures given, to acquire Ad Credit and to Get Verified.
You agree that you will not under any circumstances:
  </li>
  <li>
  ● Access the Service for any reason other than your personal, commercial use solely as
permitted by the normal functionality of the Service.
  </li>
  <li>
  ● Collect or harvest any personal data of any user of the Site or the Service.
  </li>
  <li>
  ● Distribute any part or parts of the Site or the Service without our explicit written
permission.
  </li>
  <li>
  ● Use the Service for any unlawful purpose or for the promotion of illegal activities;
  </li>
  <li>
  ● Attempt to, or harass, abuse or harm another person or group;
  </li>
  <li>
  ● Use another user’s account without permission;
  </li>
  <li>
  ● Intentionally allow another user to access your account;
  </li>
  <li>
  ● Provide false or inaccurate information when registering an account;
  </li>
  <li>
  ● Interfere or attempt to interfere with the proper functioning of the Service;
  </li>
  <li>
  ● Circumvent, disable or otherwise interfere with any security-related features of the
Service or features that prevent or restrict use or copying of content, or enforce
limitations on use of the Service or the content accessible via the Service;
  </li>
  <li>
  ● Publish or link to malicious content of any sort, including that intended to damage or
disrupt another user’s browser or computer.
  </li>
  <li>
  ● Use the App with intent to scam available buyers with products that do not exist.
  </li>
  <li>
  ● Classify a product or service under the wrong category, type, mark or storage capacity.
  </li>
  <li>
  ● Require a refund for any amount paid to boost an Advert.
  </li>
 
</ul>


<h2 className=' my-4 font-bold text-[#2B9EDA]'>
POSTING AND CONDUCT RESTRICTIONS
    </h2>

    <div className="my-2">
    When you create your own personalized account, you may be able to provide information
about your name, e-mail address, your location, shop address, contact number for calls and
WhatsApp messaging and a profile picture. (“User Content”) to the Service. You are solely
responsible for the User Content that you post, upload, link to or otherwise make available via
the Service.
</div>

<div className="my-4">
You agree that we are only acting as a passive conduit for your online distribution and
publication of your User Content. The Company, however, reserves the right to remove any
User Content from the Service at its sole discretion.
</div>

<div className="my-2">
We grant you permission to use and access the Service, subject to the following express
conditions surrounding User Content. You agree that failure to adhere to any of these conditions
constitutes a material breach of these Terms.
</div>

<div className="my-4">
By transmitting and submitting any User Content while using the Service, you agree as follows:
</div>

      <ul>
        <li>
        · You are solely responsible for your account and the activity that occurs while signed in to or
while using your account;
        </li>
        <li>
        · You will not post information that is malicious, libelous, false or inaccurate;
        </li>
        <li>
        · You will not post any information that is abusive, threatening, obscene, defamatory, libelous,
or racially, sexually, religiously, or otherwise objectionable and offensive;
        </li>
        <li>
        · You retain all ownership rights in your User Content but you are required to grant the
following rights to the Site and to users of the Service as set forth more fully under the “License
Grant” and “Intellectual Property” provisions below: When you upload or post User Content to
the Site, you grant to the Site a worldwide, non-exclusive, royalty-free, transferable license to
use, reproduce, distribute, prepare derivative works of, display, and perform that Content in
connection with the provision of the Service; and you grant to each user of the Service, a
worldwide, non-exclusive, royalty-free license to access your User Content through the Service,
and to use, reproduce, distribute, prepare derivative works of, display and perform such
Content to the extent permitted by the Service and under these Terms of Use;
        </li>
        <li>
        · You will not submit content that is copyrighted or subject to third party proprietary rights,
including privacy, publicity, trade secret, or others, unless you are the owner of such rights or
have the appropriate permission from their rightful owner to specifically submit such content;
and
        </li>
        <li>
        · You hereby agree that we have the right to determine whether your User Content
submissions are appropriate and comply with these Terms of Service, remove any and/or all of
your submissions, and terminate your account with or without prior notice.
You understand and agree that any liability, loss or damage that occurs as a result of the use of
any User Content that you make available or access through your use of the Service is solely
your responsibility. The Site is not responsible for any public display or misuse of your User
Content.
        </li>
      
      </ul>

<br />
      The Site does not, and cannot, pre-screen or monitor all User Content. However, at our
discretion, we, or technology we employ, may monitor and/or record your interactions with the
Service or with other Users.

      <h2 className=' my-4 font-bold text-[#2B9EDA]'>
      ONLINE CONTENT DISCLAIMER
    </h2>

    <div className="my-4">
    Opinions, advice, statements, offers, or other information or content made available through the
Service, but not directly by the Site, are those of their respective authors, and should not
necessarily be relied upon. Such authors are solely responsible for such content.
We do not guarantee the accuracy, completeness, or usefulness of any information on the Site or
the Service nor do we adopt nor endorse, nor are we responsible for, the accuracy or reliability
of any opinion, advice, or statement made by other parties. We take no responsibility and
assume no liability for any User Content that you or any other user or third party posts or sends
via the Service. Under no circumstances will we be responsible for any loss or damage resulting
from anyone’s reliance on information or other content posted on the Service, or transmitted to
users.
</div>

<div className="my-2">
Though we strive to enforce these Terms of Use, you may be exposed to User Content that is
inaccurate or objectionable when you use or access the App. We reserve the right, but have no
obligation, to monitor the materials posted on the App or to limit or deny a user’s access to the
Service or take other appropriate action if a user violates these Terms of Use or engages in any
activity that violates the rights of any person or entity or which we deem unlawful, offensive,
abusive, harmful or malicious.
</div>

<div className="my-4">
Calls and chats sent between you and other participants that are
not readily accessible to the general public will be treated by us as private to the extent required
by applicable law. The Company shall have the right to remove any material that in its sole
opinion violates, or is alleged to violate, the law or this agreement or which might be offensive,
or that might violate the rights, harm, or threaten the safety of users or others. Unauthorized
use may result in criminal and/or civil prosecution under State and local law. If you become
aware of a misuse of our Service or violation of these Terms of Use, please contact us
<a href="mailto:" target='_blank' className='text-[#2B9EDA] underline ml-2'>feedback.iconz@gmail.com</a>.
</div>

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
LINKS TO OTHER SITES AND/OR MATERIALS
    </h2>

 <div className="my-2">
 As part of the Service, we may provide you with convenient links to our pages (“on Facebook-
  <a href="https://www.facebook.com/app.iconz?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a> , on Instagram- <a href="https://instagram.com/iconz_app?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iconz_app</a> , on Tiktok- <a href="https://www.tiktok.com/@iconz_app" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a> @iConz, on Twitter- <a href="https://twitter.com/iConz_app?t=tdsvocXHaiMEJ5urnTi1dQ&s=09" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz_app</a> , on LinkedIn-
  <a href="https://www.linkedin.com/company/letscon/" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz</a>  and on YouTube- @iconz_app<a href="https://youtube.com/@iconz_app" target="_blank" rel="noopener noreferrer" className="text-[#2B9EDA] underline">@iConz_app</a> ”). These links are provided as a courtesy to Service
subscribers.
 </div>

 
<h2 className=' my-4 font-bold text-[#2B9EDA]'>
COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT
    </h2>

<div className="my-2">
(a) Termination of Repeat Infringer Accounts. We respect the intellectual property rights of
others and require that the users do the same. Pursuant to 17 U.S.C. 512(i) of the United States
Copyright Act, we have adopted and implemented a policy that provides for the termination in
appropriate circumstances of users of the Service who are repeat infringers. We may terminate
access for participants or users who are found repeatedly to provide or post protected third
party content without necessary rights and permissions.
</div>

 <div className="my-4">
 (b) DMCA Take-Down Notices. If you are a copyright owner or an agent thereof and believe, in
good faith, that any materials provided on the Service infringe upon your copyrights, you may
submit a notification pursuant by sending the following information in writing to our
designated copyright agent at [feedback.iconz@gmail.com]:
 </div>


1. The date of your notification;<br /> <br />
2. A physical or electronic signature of a person authorized to act on behalf of the
owner of an exclusive right that is allegedly infringed;<br /> <br />
3. A description of the copyrighted work claimed to have been infringed, or, if multiple
copyrighted works at a single online site are covered by a single notification, a
representative list of such works at that site; <br /> <br />
4. A description of the material that is claimed to be infringing or to be the subject of
infringing activity and information sufficient to enable us to locate such work;<br /> <br />
5. Information reasonably sufficient to permit the service provider to contact you, such
as an address, telephone number, and/or email address;<br /> <br />
6. A statement that you have a good faith belief that use of the material in the manner
complained of is not authorized by the copyright owner, its agent, or the law; and <br /> <br />

7. A statement that the information in the notification is accurate, and under penalty of
perjury, that you are authorized to act on behalf of the owner of an exclusive right that
is allegedly infringed. <br /><br />
<span className=' text-[#2B9EDA]'>
(c) Counter-Notices. 
    </span> <br />
 If you believe that your User Content that has been removed from the Site
is not infringing, or that you have the authorization from the copyright owner, the copyright
owner's agent, or pursuant to the law, to post and use the content in your User Content, you
may send a counter-notice containing the following information to our copyright agent using
the contact information set forth above: <br /> <br />
1. Your physical or electronic signature; <br /> <br />
2. A description of the content that has been removed and the location at which the
content appeared before it was removed; <br /> <br />
3. A statement that you have a good faith belief that the content was removed as a
result of mistake or a misidentification of the content; and <br /> <br />
4. Your name, address, telephone number, and email address, a statement that you
consent to the jurisdiction of the high court in [Buea, South West Region, Cameroon]
and a statement that you will accept service of process from the person who provided
notification of the alleged infringement. <br />
If a counter-notice is received by our copyright agent, we may send a copy of the counter-notice
to the original complaining party informing such person that it may reinstate the removed
content in ten (10) business days. Unless the copyright owner files an action seeking a court
order against the content provider, member or user, the removed content may (in our sole
discretion) be reinstated on the Site in ten (10) to fourteen (14) business days or more after
receipt of the counter-notice. <br />

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
LICENSE GRANT
    </h2>
By posting any User Content via the Service, you expressly grant, and you represent and
warrant that you have a right to grant, to the Company a royalty-free, sublicensable,
transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify,
publish, list information regarding, edit, translate, distribute, publicly perform, publicly display,
and make derivative works of all such User Content and your name, voice, and/or likeness as
contained in your User Content, if applicable, in whole or in part, and in any form, media or
technology, whether now known or hereafter developed, for use in connection with the Service.

 <div className="my-4 font-bold text-[#2B9EDA]">
 INTELLECTUAL PROPERTY
</div>
You acknowledge and agree that we and our licensors retain ownership of all intellectual
property rights of any kind related to the Service, including applicable copyrights, trademarks
and other proprietary rights. Other product and company names that are mentioned on the
Service may be trademarks of their respective owners. We reserve all rights that are not
expressly granted to you under these Terms of Use. <br /><br />

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
EMAIL MAY NOT BE USED TO PROVIDE NOTICE
    </h2>
Communications made through the Service’s email and messaging system will not constitute
legal notice to the Site, the Service, or any of its officers, employees, agents or representatives in
any situation where legal notice is required by contract or any law or regulation. <br /><br />

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM
    </h2>

For contractual purposes, you:  <br /> <br /> (a) consent to receive communications from us in an electronic
form via the email address you have submitted; and  <br /> <br /> (b) agree that all Terms of Use, agreements,
notices, disclosures, and other communications that we provide to you electronically satisfy any
legal requirement that such communications would satisfy if it were in writing. The foregoing
does not affect your non-waivable rights. <br /> <br />
We may also use your email address to send you other messages, including information about
the Site or the Service and special offers. You may opt out of such email by changing your
account settings, using the “Unsubscribe” link in the message, or by sending an email to
 <a href="mailto:" target='_blank' className='text-[#2B9EDA] underline mx-2'>feedback.iconz@gmail.com</a>
Opting out may prevent you from receiving messages regarding the Site, the Service or special
offers. <br /> <br />


<h2 className=' my-4 font-bold text-[#2B9EDA]'>
WARRANTY DISCLAIMER
    </h2>
THE SERVICE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT
LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER
EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SERVICE INCLUDING WITHOUT
LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. <br /> WITHOUT
LIMITING THE FOREGOING, WE MAKE NO WARRANTY OR REPRESENTATION THAT
ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR
FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR
DOWNLOADING AND/OR USE OF FILES, INFORMATION, CONTENT OR OTHER
MATERIAL OBTAINED FROM THE SERVICE. <br /> <br /> SOME JURISDICTIONS LIMIT OR DO NOT
PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.
LIMITATION OF DAMAGES; RELEASE
TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE, THE
SERVICE, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR
PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR
ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
HOWEVER ARISING, THAT RESULT FROM: <br /> <br /> (A) THE USE, DISCLOSURE, OR DISPLAY OF
YOUR USER CONTENT; <br /> <br /> (B) YOUR USE OR INABILITY TO USE THE SERVICE;  <br /> <br />(C) THE
SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE
AVAILABLE; OR  <br /> <br />(D) ANY OTHER INTERACTIONS WITH USE OR WITH ANY OTHER USER
OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
NEGLIGENCE)  <br /> <br />OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE
BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY
SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.


<h2 className=' my-4 font-bold text-[#2B9EDA]'>
MODIFICATION OF TERMS OF USE
    </h2>
We can amend these Terms of Use at any time and will update these Terms of Use in the event
of any such amendments. <br /> It is your sole responsibility to check the Site from time to time to
view any such changes in this agreement. Your continued use of the Site or the Service signifies
your agreement to our revisions to these Terms of Use. We will endeavor to notify you of
material changes to the Terms by posting a notice on our homepage and/or sending an email to
the email address you provided to us upon registration. <br /> <br /> For this additional reason, you should
keep your contact and profile information current. Any changes to these Terms (other than as
set forth in this paragraph) or waiver of our rights hereunder shall not be valid or effective
except in a written agreement bearing the physical signature of one of our officers. No
purported waiver or modification of this agreement on our part via telephonic or email
communications shall be valid. <br /> <br />

<h2 className=' my-4 font-bold text-[#2B9EDA]'>
GENERAL TERMS
    </h2>
If any part of this Terms of Use agreement is held or found to be invalid or unenforceable, that
portion of the agreement will be construed as to be consistent with applicable law while the
remaining portions of the agreement will remain in full force and effect. Any failure on our part
to enforce any provision of this agreement will not be considered a waiver of our right to
enforce such provision. Our rights under this agreement survive any transfer or termination of
this agreement. <br /> <br />
You agree that any cause of action related to or arising out of your relationship with the
Company must commence within ONE year after the cause of action accrues. Otherwise, such
cause of action is permanently barred. <br /> <br />
These Terms of Use and your use of the Site are governed by the federal laws of the United
States of America and the laws of the Republic of Cameroon, without regard to conflict of law
provisions. <br /> <br />
We may assign or delegate these Terms of Service and/or our Privacy Policy, in whole or in part,
to any person or entity at any time with or without your consent. <br />
 You may not assign or
delegate any rights or obligations under the Terms of Service or Privacy Policy without our
prior written consent, and any unauthorized assignment or delegation by you is void. <br /> <br />
YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND
THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS. YOU
FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE
PRIVACY POLICY AT <Link to={'/privacypolicy'} className='text-[#2B9EDA] underline'><span>PRIVACY POLICY PAGE</span></Link> REPRESENT THE COMPLETE
AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT
SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY
OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF
THIS AGREEMENT. <br /> <br />

        </div>
        </>
      )}

  </div>

   </div>


    </>
  )
}
  
export default TermsNCons