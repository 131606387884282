 // CONVERT TIME TO LONG STRING
 export const getTime = (dater,userPreferedLang) => {
    const timeObject = dater;
  const timeDate = new Date(timeObject.seconds * 1000 + timeObject.nanoseconds / 1000000);

   // Set the locale based on the USER PREFRED LANGUAGE
   let locale = 'en-US';
   if (userPreferedLang === 'FR') {
     locale = 'fr-FR';
   }

// format the date string
const dateString = timeDate.toLocaleString('en-US', {
weekday: 'long',
year: 'numeric',
month: 'long',
day: 'numeric'
}); 

return dateString

}
 
// CONVERT TO SHORTER DATE OF JUST YEAR-MONTH-DAY 
export const getTimePost = (dater,userPreferedLang) => {
    const timeObject = dater;
  const timeDate = new Date(timeObject?.seconds * 1000 + timeObject?.nanoseconds / 1000000);

  // Set the locale based on the value
  let locale = 'en-US';
  if (userPreferedLang === 'FR') {
    locale = 'fr-FR';
  }

  // Format the date string with the selected locale
  const dateString = timeDate.toLocaleString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }); 

  return dateString;
}