import { useState, useEffect } from "react";
import { ad1Ref, getAllPosts, PAGESIZE } from "../utils/dbQueries";
import * as tf from "../utils/translations";
import { where, query, orderBy } from "firebase/firestore";
import {
  SEARCH_OBJECT_TYPE_CAT,
  SEARCH_OBJECT_TYPE_SUB_CAT,
} from "../utils/constants";
import SkeletonLoading from "./ui/SkeletonLoading";
import LgPostAd from "./LgPostAd";
import MobilePostAd from "./MobilePostAd";

function SearchResults({ uid, userPreferedLang, userSearchTerm, wordType }) {
  // COMPONENT VARIABLE
  const [posts, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rerenderCount, setRerenderCount] = useState(0);
  const [buttonIfAlertWasShown, setButtonAlert] = useState(false); 

  // LOAD-MORE POSTS
  const loadMore = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      let newPosts = [];

      if (wordType === "suggestedWord") {
        newPosts = await handleSuggestedSearch(userSearchTerm, lastPost);
      } else {
        newPosts = await handleStringSearch(userSearchTerm, lastPost);
      }

      // Clear the posts array if this is the first page of results
      if (!lastPost) {
        setPosts(newPosts);
      } else {
        setPosts([...posts, ...newPosts]);
      }

      setLastPost(newPosts?.[newPosts?.length - 1]);
      // setHasMore(newPosts?.length === PAGESIZE);

      if (newPosts?.length < PAGESIZE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // FUNCTION TO HANDLE SEARCH TERM FROM SUGGESTIONS
  const handleSuggestedSearch = async (userSearchObj, lastPost) => {
    let newPosts = [];
    let itemid = userSearchObj?.itemId;
    let typeOfSearch = userSearchObj?.itemType;

    // console.log('user clicked on', userSearchObj?.matchingString);
    // console.log("term", userSearchTerm?.matchingString);
    // console.log("WordType", wordType);

    if (typeOfSearch === SEARCH_OBJECT_TYPE_CAT) {
      // query user search
      const postsQuery = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("catId", "==", itemid),
        orderBy("updatedAt", "desc")
      );

      newPosts = await getAllPosts(lastPost, postsQuery);

      // console.log('the search type is that of MainCategory');
    } else if (typeOfSearch === SEARCH_OBJECT_TYPE_SUB_CAT) {
      // query user search
      const postsQuery = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("subCatId", "==", itemid),
        orderBy("updatedAt", "desc")
      );

      newPosts = await getAllPosts(lastPost, postsQuery);

      // console.log('the search type is that of subcategory');
    } else {
      // query user search
      const postsQuery = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("queryItemsIds", "array-contains", itemid),
        orderBy("updatedAt", "desc")
      );

      newPosts = await getAllPosts(lastPost, postsQuery);

      // console.log('the search type is that of QueryParams');
    }

    return newPosts;
  };

  // FUNCTION TO HANDLE SEARCH TERM FROM SEARCH BOX I.E STRING
  const handleStringSearch = async (userSearchTerm, lastPost) => {
    let newPosts = [];
    let searchTerm = userSearchTerm.toLowerCase();

    const postsQuery = query(
      ad1Ref,
      where("complete", "==", true),
      where("deleteForever", "==", false),
      where("trash", "==", false),
      where("status", "==", true),
      where("adminEnable", "==", true),
      where("approved", "==", true),
      where("searchStrings", "array-contains", searchTerm),
      orderBy("updatedAt", "desc")
    );

    newPosts = await getAllPosts(lastPost, postsQuery);

    // console.log("String term", userSearchTerm);
    // console.log("WordType", wordType);

    return newPosts;
  };

  useEffect(() => {
    setPosts([]);
    setLastPost(null);
    setHasMore(true);
    loadMore();
  }, [userSearchTerm, rerenderCount]);

  // HANDLES SCROLL TO BOTTOM
  const handleScroll = () => {
    const scrollBottom =
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) + 0;
    if (
      scrollBottom >= document.documentElement.offsetHeight ||
      !document.documentElement.offsetHeight
    ) {
      loadMore();
    }
  };

  // CLEAN THE USE-EFFECT
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  // MAKE SURE THERE IS A RERENDER EACH TIME A VARIABLE CHNAGES
  useEffect(() => {
    setRerenderCount((count) => count + 1);
  }, [userSearchTerm]);

  // check if user clicked connect it within 24hours
  useEffect(() => {
    let currentTime = new Date().getTime();
    // Get the expiration time of the popup
    const expirationTime = localStorage.getItem("warningExpirationTime");
    // Check if the popup has been shown before
    const warningShown = localStorage.getItem("warningShown");

    // If the popup has been shown before and it hasn't expired yet, don't show the popup
    if (warningShown && currentTime < expirationTime) {
      setButtonAlert(true);
      const timeLeft = Math.round((expirationTime - currentTime) / 1000);
      console.log(`Time left: ${timeLeft} seconds`);
    } else {
      console.log("warning shown already ");
    }
  }, [buttonIfAlertWasShown]);

  // DISPLAY ADS WITH SEARCH TERM IN CONSOle
  // console.log("Ads with SearchWord-in-them : ", posts);
  // console.log("term", userSearchTerm);

  return (
    <>
      {/* ADS/PRODUCTS CONTAINER FOR LARGE SCREENS */}
      {/* LOOP THROUGH POSTS TO DISPLAY ADS */}
      <div className=" hidden min_wrapper w-full lg:grid">
        {posts?.map((post, key) => (
          <LgPostAd
            key={key}
            post_object={post}
            uid={uid}
            userPreferedLang={userPreferedLang}
            buttonIfAlertWasShown={buttonIfAlertWasShown}
            setButtonAlert={setButtonAlert}
          />
        ))}
      </div>
      {/* ADS/PRODUCTS CONTAINER FRO LARGE SCREENS */}

      {/* ADS CONTAINER FOR SMALL DEVICES */}
      <div className="small_screens grid grid-cols-2 gap-2 lg:hidden px-2">
        {posts?.map((post, key) => (
          <MobilePostAd
            key={key}
            post_object={post}
            uid={uid}
            userPreferedLang={userPreferedLang}
            buttonIfAlertWasShown={buttonIfAlertWasShown}
            setButtonAlert={setButtonAlert}
          />
        ))}
      </div>
      {/* ADS CONTAINER FOR SMALL DEVICES */}

      {/* EXECUTION STATE */}
      <div className="mt-4 p-2">
        {hasMore ? (
          <>
            <div className="grid grid-cols-2 gap-1  lg:grid-cols-4">
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
              <SkeletonLoading />
            </div>
          </>
        ) : !hasMore && posts?.length !== 0 ? (
          <div className="w-full flex items-center justify-center my-12">
            {userPreferedLang == "FR" ? tf.thatsallFr : "That's All."}
          </div>
        ) : posts?.length == 0 ? (
          <div className="w-full  flex items-center justify-center my-12">
            <div className="w-1/2 flex justify-center items-center bg-[#2B9EDA] text-white rounded-md p-2 shadow-xl">
              <p>
                {userPreferedLang === "FR" ? tf.nopostFr : "No Products here."}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
export default SearchResults;
