import React from 'react'
import Loader from '../../assets/loader.gif'

function Loading({text}) {
  return (

    <div className='w-full my-8'>
<div className='w-full h-full flex justify-center items-center'>
     <div className=' flex justify-center items-center p-1 rounded-md'>
<img src={Loader} alt="loader" />
<p className='text-gray-400 text-xs'>{text}</p>
        </div>
        </div>
    </div>
    
  )
}

export default Loading