import React from 'react'
import * as tf from '../utils/translations'
import Seo from '../utils/Seo'

function AboutUs({userPreferedLang}) {
  return (
    <>
     {/* SEO TAG */}
        
     <Seo
            title={userPreferedLang == 'FR' ? tf.AboutFr : 'About Us'}
            description={userPreferedLang == 'FR' ? 'Chez iConz, nous croyons en la création dune expérience transparente et conviviale pour les acheteurs et les vendeurs Notre plateforme est conçue pour fournir un environnement sûr, sécurisé et fiable où les utilisateurs peuvent facilement parcourir et acheter des produits ou des services, ou promouvoir et vendre leurs propres offres.' : 'At iConz, we believe in creating a seamless and user-friendly experience for both buyers and sellers. Our platform is designed to provide a safe, secure, and trustworthy environment where users can easily browse and purchase products or services, or promote and sell their own offerings.'}
          />
    <div className="w-full mt-[-60px] lg:mt-0 relative">

    <div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
      <h2 className='text-4xl font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? tf.AboutFr : 'About Us'}</h2>
    </div>

    <div className="md:px-24 px-4">
      
      {
        userPreferedLang == "FR" ?
         <>
        <div className="french-v w-full">

          <div className="my-4">
  Bienvenue sur iConz, la plateforme en ligne de premier choix qui connecte les utilisateurs au Cameroun à un vaste réseau de services et de produits proposés par des particuliers et des entreprises. Notre plateforme est disponible sur Android, iOS et sur le web, ce qui la rend facile et pratique pour tout le monde.
          </div>

          <div className="my-4">
          Chez iConz, nous croyons en la création d'une expérience fluide et conviviale pour les acheteurs et les vendeurs. Notre plateforme est conçue pour offrir un environnement sûr, sécurisé et fiable où les utilisateurs peuvent facilement parcourir et acheter des produits ou des services, ou promouvoir et vendre leurs propres offres.
          </div>

        <div className="my-4">
        Que vous recherchiez un produit ou un service spécifique, ou que vous souhaitiez promouvoir votre propre entreprise, iConz facilite la connexion avec les autres et la réalisation de vos objectifs. Nos outils de recherche et de filtrage intuitifs vous permettent de trouver rapidement ce dont vous avez besoin, tandis que notre vaste réseau d'utilisateurs et de vendeurs garantit que vous avez accès à un large éventail d'options.
        </div>

        <div className="my-4">
        Chez iConz, nous nous engageons à fournir un service clientèle et une assistance exceptionnels. Notre équipe est toujours disponible pour répondre à vos questions, résoudre tout problème que vous pourriez rencontrer et fournir des conseils et une assistance au besoin. Nous sommes déterminés à faire en sorte que chaque utilisateur ait une expérience positive et enrichissante sur notre plateforme.
        </div>

        <div className="my-4">
        Si vous cherchez une plateforme fiable et sûre pour acheter ou vendre quoi que ce soit au Cameroun, ne cherchez pas plus loin qu'iConz. Avec notre plateforme facile à utiliser, notre vaste réseau d'utilisateurs et de vendeurs, et notre engagement envers un service clientèle exceptionnel, nous sommes convaincus que vous trouverez tout ce dont vous avez besoin et bien plus encore sur iConz.
        </div>


        </div>
        </> :
        <>
         <div className="eng w-full">
     <div className="my-4">
      Welcome to iConz, the premier online platform connecting users in Cameroon with a vast network of services and products offered by individuals and businesses alike. Our platform is available on both Android and iOS, as well as on the web, making it easy and convenient for anyone to connect with others and find what they need.

      </div>

      <div className="my-4">
      At iConz, we believe in creating a seamless and user-friendly experience for both buyers and sellers. Our platform is designed to provide a safe, secure, and trustworthy environment where users can easily browse and purchase products or services, or promote and sell their own offerings.
        </div>
   
        <div className="my-4">
        Whether you're looking for a specific product or service, or seeking to promote your own business, iConz makes it easy to connect with others and get the job done. Our intuitive search and filtering tools allow you to quickly find what you need, while our extensive network of users and sellers ensures that you have access to a wide range of options.

        </div>

        <div className="my-4">
        At iConz, we're committed to providing exceptional customer service and support. Our team is always available to answer your questions, resolve any issues you may encounter, and provide guidance and assistance as needed. We're dedicated to ensuring that every user has a positive and rewarding experience on our platform.
        </div>

        <div className="my-4">
        So if you're looking for a reliable and trustworthy platform to buy or sell anything in Cameroon, look no further than iConz. With our easy-to-use platform, extensive network of users and sellers, and commitment to exceptional customer service, we're confident that you'll find everything you need and more on iConz.
        </div>
     </div>
        </>
      }
  
      </div>

    </div>
    </>
  )
}
 
export default AboutUs