import React from 'react'
import * as tf from '../utils/translations'
import Seo from '../utils/Seo'


function PrivacyPolicy({userPreferedLang}) {
  return (
    <>
     {/* SEO TAG */}
       
     <Seo
     title={userPreferedLang == 'FR' ? tf.PrivacyPolicyFr : 'Privacy Policy'}
     description= {userPreferedLang == 'FR' ? tf.p1Fr : tf.p1eng}
          />

    <div className="w-full mt-[-60px] lg:mt-0 relative ">

<div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
   <h2 className='text-3xl font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? tf.PrivacyPolicyFr : 'Privacy Policy'}</h2>
 </div>

<div className="md:px-24 px-4">

      <div className="my-4">
        <p>
        {userPreferedLang == 'FR' ? tf.p1Fr : tf.p1eng}
        </p>
      </div>

      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Portée et consentement :' : 'Scope and consent:'}</span>  {userPreferedLang == 'FR' ? tf.p2Fr : tf.p2eng}
        </p>
      </div>

      <div className="my-4">
        <h3 className='text-[#2B9EDA] font-bold text-center'> {userPreferedLang == 'FR' ? tf.hFr : tf.heng}</h3>
      </div>

      <div className="my-2">
        <p>
        {userPreferedLang == 'FR' ? tf.p3Fr : tf.p3eng}
        </p>
      </div>

      <div className="my-2">
        <p>
        {userPreferedLang == 'FR' ? tf.p4Fr : tf.p4eng}
        </p>
      </div>


      <div className="my-4">
        <h3 className='text-[#2B9EDA] font-bold '> {userPreferedLang == 'FR' ? tf.h1Fr : tf.h1eng}</h3>
      </div>

      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Votre nom:' : 'Your Name:'}</span>  {userPreferedLang == 'FR' ? tf.p5Fr : tf.p5eng}
        </p>
      </div>

      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Votre adresse e-mail:' : 'Your Email Address:'}</span>{userPreferedLang == 'FR' ? tf.p6Fr : tf.p6eng}
        </p>
      </div>

      
      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Votre numéro de téléphone:' : 'Your Phone Number:'} </span> {userPreferedLang == 'FR' ? tf.p7Fr : tf.p7eng}
        </p>
      </div>

      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Votre numéro Whatsapp :' : 'Your WhatsApp Number:'} </span> {userPreferedLang == 'FR' ? tf.p8Fr : tf.p8eng}
        </p>
      </div>

      <div className="mb-2">
        <p>
       <span className='font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? 'Adresse de votre boutique :' : 'Your Shop Address:'}</span>
        {userPreferedLang == 'FR' ? tf.p9Fr : tf.p9eng}
        </p>
      </div>

   </div>
</div>

    </> 
  )
}
 
export default PrivacyPolicy