import React from "react";
import { FaStar } from "react-icons/fa";
import TimeDifference from "../utils/TimeDifference";

function UsersReview({ post, userPreferedLang }) {
  // console.log(post)

  const starIcons = [];
  for (let i = 0; i < post?.rating; i++) {
    starIcons.push(<FaStar className="text-[#0F8747] mx-1" key={i} />);
  }

  return (
    <>
      <div className="w-full  shadow-md px-2 rounded-md my-4">
        {/* senderinfo */}
        <div className="info flex items-center">
          <img
            src={post?.sender?.profilePhotoUrl}
            alt={post?.sender?.userName}
            className="w-10 h-10 mr-2 rounded-full"
          />
          <div className="name">
            <p className="capitalize font-medium text-[1rem]">
              {post?.sender?.userName}
            </p>
            <p className="stars flex items-center ">{starIcons}</p>
          </div>
        </div>
        {/* review */}
        <div className="rev mt-2 ml-2 ">
          <p className="ml-10 text-sm lg:text-[1rem] leading-tight ">
            {post?.reviewMessage}
          </p>
        </div>
        {/* timestamp */}
        <div className="time flex justify-end text-[#2A9ED9] text-xs lg:text-[1rem] md:mt-4 ">
          <p>
            <TimeDifference
              date_created={post?.updatedAt}
              userPreferedLang={userPreferedLang}
            />
          </p>
        </div>
      </div>
    </>
  );
}

export default UsersReview;
