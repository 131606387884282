import React from 'react'
import Pic1 from '../assets/guide1.jpg'
import Pic2 from '../assets/guide2.jpg'
import * as tf from '../utils/translations'
import Seo from '../utils/Seo'


function HowToSell({userPreferedLang}) {
  return (
    <>
     {/* SEO TAG */}
       
     <Seo
     title={userPreferedLang == 'FR' ? tf.howToSellFr : 'How To Sell On iConz'}
     description={userPreferedLang == 'FR' ?"Veuillez lire nos consignes de publication avant de créer votre annonce. Votre annonce sera refusée si vous ne suivez pas ces directives." : "Please read through our posting guidelines before creating your Ad. Your Ad will be declined if you don't follow this guidelines."}
          />
   <div className="w-full mt-[-60px] lg:mt-0 relative ">

   <div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
      <h2 className='text-3xl font-bold text-[#2B9EDA]'>{userPreferedLang == 'FR' ? tf.howToSellFr : 'How To Sell On iConz'}</h2>
    </div>

    <div className="md:px-24 px-4">

    <div className="guidelines my-4 font-semibold">
      <p>
      {userPreferedLang == 'FR' ?"Veuillez lire nos consignes de publication avant de créer votre annonce. Votre annonce sera refusée si vous ne suivez pas ces directives." : "Please read through our posting guidelines before creating your Ad. Your Ad will be declined if you don't follow this guidelines."}
      </p>
    </div>
   
 {/* Title guide */}
    <div className="guidelines my-2">
      <p>
     <span className='font-semibold'>{userPreferedLang =="FR" ? "Ajouter un titre:" :' Ad Title:'}  </span><br/> 
     {userPreferedLang =='FR' ? "Il doit s'agir d'une brève description de l'article que vous souhaitez vendre ou du service que vous souhaitez offrir. Voir l'exemple ci-dessous.": "This should be a short description of the item you want to sell or service you want to offer. See the example bellow."}
    
      </p>
    </div>
  <div className="flex justify-center items-center">
  <div className="price w-full lg:w-1/2 lg:flex flex-col">
   <div className="img w-full bg-gray-200 my-2 border border-slate-500 rounded-md p-1 flex justify-between items-center">
      <p>  {userPreferedLang == 'FR' ?"Pas cher Promotion téléphone" : "Cheap Promotion phone"}</p>
      <p>X</p>
    </div>
    <div className="img w-full bg-gray-200 my-2 border border-slate-500 rounded-md p-1 flex justify-between items-center">
      <p>Tecno Spark 5 Pro</p>
      <p>V</p>
    </div>
   </div>
  </div>

{/* price guide */}
    <div className="guidelines mb-4 mt-8">
      <p>
     <span className='font-semibold'>{userPreferedLang =="FR" ? "Prix ​​de l'article:" :' Item Price:'}  </span><br/> 
     {userPreferedLang == 'FR' ? "Le prix de chaque article doit être déclaré avant de pouvoir être approuvé. Si vous offrez un service, vous devez entrer le prix minimum que vous facturez pour le service. Voir l'exemple ci-dessous." : " The price of every item must be declared before it can be approved. If you offer a service, you should enter the minimum price you charge for the service. See the example bellow."}
      </p>
    </div>
   <div className=" flex justify-center items-center">
   <div className="price w-full lg:w-1/2 lg:flex flex-col">
   <div className="img w-full bg-gray-200 my-2 border border-slate-500 rounded-md p-1 flex justify-between items-center">
      <p>XAF 0 &nbsp;&nbsp;&nbsp;&nbsp;  XAF 1</p>
      <p>X</p>
    </div>
    <div className="img w-full bg-gray-200 my-2 border border-slate-500 rounded-md p-1 flex justify-between items-center">
      <p>XAF 80 000</p>
      <p>V</p>
    </div>
   </div>
   </div>

{/* item condition guide */}
    <div className="guidelines mb-4 mt-8">
      <p>
     <span className='font-semibold'> {userPreferedLang =="FR" ? "Etat de l'objet:" :'Item Condition: '}  </span><br/> 
     {userPreferedLang == 'FR' ? "Indiquez l'état honnête de l'article. Ne prétendez pas qu'un article d'occasion est neuf. Voir l'exemple ci-dessous.": " Indicate the honest condition of the item. Don't claim a used item is brand new. See the example bellow."}
      </p>
    </div>
    <div className="im  flex justify-center items-center">
      <img src={Pic1} alt="" />
    </div>

{/* item photos guide */}
    <div className="guidelines my-2">
      <p>
     <span className='font-semibold'>{userPreferedLang =="FR" ? "Photos de l'article :" :' Item Photos: '}   </span><br/> 
     {userPreferedLang == 'FR' ? "Prenez une vraie photo de l'article que vous souhaitez vendre. Nous n'autorisons pas les images téléchargées depuis Internet. Voir l'exemple ci-dessous." :"Take a real photo of the item you want to sell. We don't allow images downloaded from the internet. See the example bellow."}
      </p>
    </div>
    <div className="im flex justify-center items-center">
      <img src={Pic2} alt="" />
    </div>


    <div className="guidelines mb-4 mt-8">
      <p>
     <span className='font-semibold'>{userPreferedLang =="FR" ? 'Information additionnelle:' :' Additional Info:'} </span><br/> 
     {userPreferedLang == 'FR' ? "Lorsque vous publiez un article, nous vous conseillons de prendre de très bonnes photos de l'article, d'écrire un bon titre et de décrire l'article en détail, car cela aidera à attirer les bons acheteurs pour votre article. Nous vous demandons également de sélectionner la bonne catégorie pour l'article, car cela aidera les acheteurs à trouver facilement l'article. Vous pouvez sélectionner <Autre> si vous ne parvenez pas à trouver le bon champ correspondant à l'élément." : "When posting an item, we advice that you take very good pictures of the item, write a good title and also describe the item in details as it will help attract the right buyers for your item. We also request that you select the right category for the item as it will help buyers find the item easily. You can select 'Other' if you are not able to find the right field that matches the item."}
      </p>
    </div>

    </div>
    
   </div>
    </>
  )
}  

export default HowToSell; 