import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaStar, FaUserCircle } from "react-icons/fa";
import { AiFillShop } from "react-icons/ai";
import * as Query from "../utils/dbQueries";
import { getTimePost } from "../utils/convertDate";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "../components/InfiniteScroll";
import InfiniteScrollReview from "../components/InfiniteScrollReviews";
import * as tf from "../utils/translations";
import SkeletonLoading from "../components/ui/SkeletonLoading";
import Snooker from "../assets/Snooker.gif";
import NoMatch from "./NoMatch";
import Seo from "../utils/Seo";

function UserProfile({ uid, userPreferedLang }) {
  const location = useLocation();
  const navigate = useNavigate();

  // IF USERS TRY TO NAVIGATE TO USERPROFILE DIRECTLY ON BROWSER
  useEffect(() => {
    if (!location.state || !location.state.id) {
      navigate("/NoMatch");
    }
  }, [location.state, navigate]);

  // GET ID OF USER FROM STATE
  const { id } = location.state || {};

  // COMPONENT VARIABLE DECLARATION
  const [changeView, setChangeView] = useState("ads");
  const [totalAds, setTotalAds] = useState(0);
  const [totalRevs, setTotalRevs] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [rating, setRatings] = useState([]);
  const [miniLoadingPosts, setMiniLoadingPosts] = useState(true);
  const [miniLoadingUser, setMiniLoadingUser] = useState(true);

  // GET USER DATA FROM DB
  useEffect(() => {
    // get user_details
    Query.getUserDetails(id)
      .then((data) => {
        // Check whether a user with the given id exists
        if (data) {
          // If the user exists, set the userDetails state with the user data
          setUserDetails(data);
          setMiniLoadingUser(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setMiniLoadingUser(false);
      });
  }, []);

  // GET USER STATS FROM DB
  useEffect(() => {
    // get posts counts
    Query.countUsersPosts(id)
      .then((data) => {
        setTotalAds(data);
        setMiniLoadingPosts(false);
      })
      .catch((error) => {
        console.error(error);
        setMiniLoadingPosts(false);
      });

    // get review counts
    Query.countReviews(id).then((data) => {
      setTotalRevs(data);
    });

    // get user reviews
    Query.getAUserAllReviews(id).then((data) => {
      setRatings(data);
    });
  }, []);

  // CALCULATE STAR RATINGS
  // Step 1: Calculate the sum of all ratings
  let sum = 0;
  for (let i = 0; i < rating?.length; i++) {
    sum += rating[i]?.rating;
  }
  // Step 2: Calculate the average rating
  let avgRating = sum / rating?.length;
  // Step 3: Round the average rating to one decimal place
  let avgRatingRounded = avgRating.toFixed(1);
  // Step 5: Map the average rating to a corresponding number of stars
  avgRating = avgRatingRounded;
  // Step 6: Display the average rating and the corresponding number of stars
  // console.log(`Average rating: ${avgRating}`);

  return (
    <>
      {/* SEO TAG */}

      <Seo
        title={`Profile | ${userDetails[0]?.userName} `}
        description={`${userDetails[0]?.shopAddress}`}
      />

      {/* USER_PROFILE WRAPPER */}
      <div className="w-full md:px-8 lg:mt-4 ">
        {/* USER-INFO */}
        <div className="user_info  w-full pt-2 md:pt-0 ">
          {/* USER_DETAILS */}
          <div className="profile_pic flex items-center cursor-pointer">
            {miniLoadingUser ? (
              <p>
                <FaUserCircle className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] p-1 rounded-full text-gray-300" />
              </p>
            ) : (
              <img
                src={userDetails[0]?.profilePhotoUrl}
                alt={userDetails[0]?.userName}
                className="  w-[80px] h-[80px] md:w-[120px] md:h-[120px] p-1 rounded-full"
              />
            )}

            <div className="info">
              <p className=" capitalize flex items-center font-medium text-[20px]">
                {miniLoadingUser ? (
                  <img
                    src={Snooker}
                    alt="loading"
                    className="w-24 opacity-50"
                  />
                ) : (
                  userDetails[0]?.userName
                )}
                {userDetails[0]?.verified ? (
                  <span>
                    <FaCheckCircle className="text-[#2B9EDA]  ml-1 mr-2" />
                  </span>
                ) : null}
                <span className="flex items-center ml-2">
                  {" "}
                  {isNaN(avgRating) ? "0" : avgRating}{" "}
                  <FaStar className="text-[#2B9EDA] mx-1" />{" "}
                </span>{" "}
              </p>

              <p className="text-gray-500 text-[.74rem] flex items-center lg:text-[16px] lg:mb-2">
                <span className="mr-1 capitalize">
                  {userPreferedLang == "FR" ? tf.registeredFr : "Registered on"}{" "}
                </span>
                {miniLoadingUser ? (
                  <img
                    src={Snooker}
                    alt="loading"
                    className="w-10 opacity-50"
                  />
                ) : (
                  getTimePost(userDetails[0]?.createdAt, userPreferedLang)
                )}
              </p>

              {/* shop Address */}
              <div className="w-full flex items-center text-[.74rem] lg:text-[16px]">
                <span className="font-medium flex items-center">
                  <AiFillShop className="mr-1" />
                  {userPreferedLang == "FR"
                    ? tf.shopAddressFr
                    : "Shop Address "}
                </span>{" "}
                :{" "}
                <span className="mx-2">
                  {miniLoadingUser ? (
                    <img
                      src={Snooker}
                      alt="loading"
                      className="w-10 opacity-50"
                    />
                  ) : (
                    userDetails[0]?.shopAddress
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* USER STATS */}
          <div className="my-4 text-sm lg:text-[20px] font-medium flex items-center w-full border-b-2 border-gray-300">
            {/* SEE ads*/}
            <div
              onClick={() => {
                setChangeView("ads");
              }}
              className={`${
                userPreferedLang == "FR" ? "lg:w-[20%]" : "lg:w-[15%]"
              } w-[30%]  cursor-pointer ${
                changeView == "ads"
                  ? "border-b-4 pb-2 border-blue-500 text-black"
                  : "border-b-4 text-gray-400 pb-2 border-transparent"
              } flex flex-col items-center justify-center `}
            >
              <p>
                {miniLoadingPosts ? (
                  <img
                    src={Snooker}
                    alt="loading"
                    className="w-12 opacity-50"
                  />
                ) : (
                  totalAds
                )}
              </p>
              <p>{userPreferedLang == "FR" ? tf.activeAdsFr : "Active Ads"}</p>
            </div>

            {/* SEE revIEWS */}
            <div
              onClick={() => {
                setChangeView("reviews");
              }}
              className={`w-[30%] lg:w-[15%]  cursor-pointer ${
                changeView == "reviews"
                  ? "border-b-4 pb-2 border-blue-500 text-black"
                  : "border-b-4 text-gray-400 pb-2 border-transparent"
              }  flex flex-col items-center justify-center `}
            >
              <p>
                {miniLoadingPosts ? (
                  <img
                    src={Snooker}
                    alt="loading"
                    className="w-12 opacity-50"
                  />
                ) : (
                  totalRevs
                )}
              </p>
              <p>{userPreferedLang == "FR" ? tf.reviewsFr : "Reviews"}</p>
            </div>
          </div>
        </div>
        {/* USER-INFO */}

        {/* CONDITIONALLY RENDER USER'S ADS AND REVIEWS */}
        {changeView == "ads" ? (
          <>
            {/* LOADING ANIMATION */}
            {miniLoadingPosts ? (
              <>
                <div className="grid grid-cols-2 gap-1  lg:grid-cols-4">
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                </div>
              </>
            ) : (
              <>
                {totalAds === 0 ? (
                  // IF USER HAS NO ADS
                  <div className="text-center w-full h-[200px] flex justify-center items-center">
                    <p className="p-2 bg-[#2B9EDA] shadow-lg text-white">
                      <span className="capitalize">
                        {userDetails[0]?.userName}
                      </span>{" "}
                      {userPreferedLang === "FR"
                        ? tf.hasnopostFr
                        : "has no Posts."}
                    </p>
                  </div>
                ) : (
                  <InfiniteScroll
                    userId={id}
                    uid={uid}
                    userPreferedLang={userPreferedLang}
                    userName={userDetails[0]?.userName}
                  />
                )}
              </>
            )}
          </>
        ) : (
          // USER'S REVIEWS
          <>
            <div className="w-full px-2">
              <div className="md:w-[80%]">
                {totalRevs == 0 ? (
                  <>
                    <div className=" text-center w-full h-[200px] flex justify-center items-center ">
                      <p className="p-2 bg-[#2B9EDA] shadow-lg text-white">
                        {" "}
                        <span className="capitalize">
                          {userDetails[0]?.userName}
                        </span>{" "}
                        {userPreferedLang == "FR"
                          ? tf.hasnoreviewsFr
                          : "has no reviews."}
                      </p>
                    </div>
                  </>
                ) : (
                  <InfiniteScrollReview
                    userId={id}
                    userPreferedLang={userPreferedLang}
                    userName={userDetails[0]?.userName}
                    avgRating={avgRating}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserProfile;
