
// SET DEFAULT COUNTRY TO CAMEROON IF COUNTRY IS UNDEFINED
let country = 'Cameroon';
// let country = null || 'Cameroon';
// GET USER_COUNTRY FROM API
// export async function getUserData() {
//   const response = await fetch('https://api.techniknews.net/ipgeo/');
//   const data = await response.json();
//   country = data?.country
//   console.log('user_Country : ', country)
//   return data;
// }
export {country}


// IF USER COUNTRY IS NOT SUPPORTED
export const CountryNotAvailable = () => {
  return <div className="w-full px-12 h-screen flex flex-col justify-center items-center">
    <p>If you are in page it is because of one of the following reasons:</p>
    <p>1. iConz marketPlace is not available in your country yet.</p>
    <p>2. Your device does not have a healthy Internet connection at the moment.</p>
  </div>;
};

// IF USER IS USING VPN
export const TurnOffVPN = () => {
  return <div className="w-full h-screen flex justify-center items-center">Please turn off your VPN to use iConz MarketPlace</div>;
};
