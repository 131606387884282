import React, { useState, useEffect } from "react";


function WarnUser({
  userPreferedLang,
  setShowWarning,
  actionTaken,
  user_info,
  post_link,
  setButtonAlert,
}) {
  let currentTime = new Date().getTime();

  // Function to handle closing the popup
  const handleClose = () => {
    // Set the expiration time for the popup to 1 hour from now
    const expirationTime = currentTime + 30 * 1000; // 30 seconds in milliseconds
    // const expirationTime = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    localStorage.setItem("warningExpirationTime", expirationTime);
    localStorage.setItem("warningShown", true);
    setShowWarning(false);
    setButtonAlert(true) 
  };

  return (
    <>
      <div
        onClick={() => handleClose()}
        className="fixed text-sm w-full h-screen overflow-y-scroll lg:overflow-hidden inset-0 bg-black bg-opacity-30 z-50  "
      >
        <div className="w-full h-full  flex justify-center items-center px-4">
          <div className="bg-white lg:w-1/2 rounded-md shadow-md p-2 text-sm">
            <p>
              {userPreferedLang !== "FR"
                ? `Please do not send money for an item you have not seen physically.
              It’s better to meet with the vendor in a public place or have a
              video call to see the item`
                : ` N'envoyez pas d'argent pour un article que vous n'avez pas vu physiquement.
  Il est préférable de rencontrer le vendeur dans un lieu public ou d'avoir un
              appel vidéo pour voir l'objet`}
            </p>

            <div className="continue w-full flex justify-center items-center">
              <p className="bg-blue-500 w-1/3 text-center rounded-md shadow-sm mt-4 text-white p-2">
                {actionTaken === "CALL" ? (
                  <>
                    <a
                      href={`tel:${user_info?.phoneNumber}`}
                      target="_blank"
                      className=""
                    >
                      {userPreferedLang === "FR" ? "Compris!" : "Got it!"}
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href={
                        userPreferedLang == "FR"
                          ? `https://wa.me/${user_info?.whatsAppNumber}?text=Bonne journée,%0Aje%20veux%20acheter%0A${post_link}`
                          : `https://wa.me/${user_info?.whatsAppNumber}?text=Hi,%0AI%20want%20to%20buy%20this%0A${post_link}`
                      }
                      className=""
                    >
                      {userPreferedLang === "FR" ? "Compris!" : "Got it!"}
                    </a>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WarnUser;
