import React, { useState, useRef, useEffect } from "react";
import {AiFillPlusCircle} from 'react-icons/ai' 
import AppStore from '../../assets/app_store.png'
import Playstore from '../../assets/google_play.png'
import * as tf from '../../utils/translations'


function LaptopPopup({userPreferedLang}) {
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
  
    useEffect(() => {
      function handleClickOutside(event) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popupRef]);
  
    return (
      <>

 <div className="hidden ad lg:w-[250px] h-[20px] lg:flex justify-center items-center">
    <button  onClick={() => setShowPopup(true)}
     className='p-2 font-medium bg-[#2B9EDA] text-white flex items-center rounded-md cursor-pointer transition-colors duration-200 ease-in-out hover:bg-blue-600 '>
      <AiFillPlusCircle className={`${userPreferedLang == 'FR' ? 'text-xs': 'text-[.88rem]'}`} /> 
      <p className={`${userPreferedLang == 'FR' ? 'text-[.8rem]': 'text-[.8rem]'} pl-2`}>{userPreferedLang == 'FR' ? tf.postAdFr : 'Post an Ad on iConz'}</p>
    </button>
  </div>

        {showPopup ? (
          <div ref={popupRef}
          className="absolute right-2 top-2 shadow-lg animate__animated animate__zoomIn"
          onAnimationEnd={() => {
            popupRef.current.classList.remove("animate__zoomIn");
          }}
          >

 <div className="shadow-lg w-[300px]  p-2 bg-[#2B9EDA] text-white ">
    <div className="div text-sm">
      <p>
      {userPreferedLang == 'FR' ? tf.topAdBannerFr : 'Get the app to easily reach vendors and also post any item or service you offer for free !'}</p>
    </div>
    <div className="icons mt-2 flex justify-around items-center">
      {/* google playstore */}
      <a href="https://play.google.com/store/apps/details?id=com.eladeforwa.letscon" target="_blank" rel="noopener noreferrer">
        <img src={Playstore} alt="google playstore icon" className='w-24 hover:scale-90 transition-all ease-in ' />
      </a>
      {/* apple app store */}
      <a href="https://apps.apple.com/cm/app/iconz-buy-sell/id6448638809?l=en" target="_blank" rel="noopener noreferrer">
      <img src={AppStore} alt="Apple appStore icon" className='w-24 hover:scale-90 transition-all ease-in ' />
      </a>
    </div>
    </div>
          </div>

        ) : null}
      </>
    );
  }
  
export default LaptopPopup