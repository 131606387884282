import React from 'react'
import { Helmet } from 'react-helmet-async'

function Seo({ title, description}) {
  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={'https://iconzapp.com'} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
    </>
  )
}

export default Seo