
    // FRENCH TRANSLATIONS
    export const HomeFr = 'Acceuil'
    export const AboutFr = "À propos d'iconz"
    export const howToSellFr = "Comment vendre sur iConz"
    export const our_socialsFr = "Nos poignées de médias sociaux"
    export const TermsNConditionsFr = "Termes et Conditions"
    export const PrivacyPolicyFr = "Politique de confidentialité"
    export const locationFr = "emplacement"
    export const AllFr = "Tous"
    export const allLocationsFr = "Tous les emplacements"
    export const negotiableFr= "Négociable"
    export const fixedFr = 'Prix fixe'
    export const callFr = "Appel"
    export const chatFr = "Causer"
    export const activeAdsFr = "Annonces actives"
    export const reviewsFr = "Commentaires"
    export const descriptionFr = "Description"
    export const postedonFr = "Posté"
    export const priceConditionFr = "Condition de prix"
    export const priceFr = "Prix"
    export const viewfullprofileFr = "Voir le profil complet"
    export const registeredFr = "inscrite"
    export const gettingpostsFr = "Obtenir des messages. S'il vous plaît, attendez!"
    export const shopAddressFr = "Adresse du magasin"
    export const searchPlaceholderFr = "Qu'est-ce que tu cherches ?"
    export const topAdBannerFr = "Téléchargez l'application pour atteindre facilement les fournisseurs et publiez gratuitement tout article ou service que vous proposez !"
    export const thatsallFr = "C'est tout!"
    export const nopostFr = "Aucun annonce ici."
    export const hasnoreviewsFr = "N'a pas d'avis"
    export const hasnopostFr = "N'a pas de messages"
    export const postAdFr = "Publier une annonce sur iConz"
    export const loadingMenu = "menu de chargement ..."

    // STATUSES FOR NOT AVAILABEL IN COUNTRY AND USER USING VPN
    export const notAvailblecountryFr = "Désolé, iConz marketPlace n'est pas encore disponible dans votre pays."
    export const tunrofvpnFr = "Veuillez désactiver votre VPN pour utiliser iConz MarketPlace"

    // 404 PAGE
    export const h1404Fr = "On dirait que vous avez trouvé le porte vers le grand rien"
    export const p404Fr = "Désolé pour ça! Veuillez visiter notre page d'accueil pour obtenir ce dont vous avez besoin."
    export const takemehomeFr = "Emmenez-moi là-bas !"


    // CHAT TYPES
    export const Chatbtn ="CHAT"
    export const call ="CALL"

    // privacy policy PAGE translated in paragraphs as on website
    export const p1eng="   This Privacy Policy explains what personal data is collected when you use the iConz mobile application and the services provided through it (together the “Service”), how such personal data will be used, shared"
    export const p1Fr="Cette politique de confidentialité explique quelles données personnelles sont collectées lorsque vous utilisez l'application mobile iConz et les services fournis par son intermédiaire (ensemble le « Service »), comment ces données personnelles seront utilisées, partagées"

    export const p2eng="By using iConz and related Services, you give explicit consent to iConz for the collection, use, disclosure and retention of your personal information by us, as described in this Privacy Policy and our Terms of Use.iConz may change this Privacy Policy from time to time. We advise you to read it regularly. Substantial changes to our Privacy Policy will be announced on our Website. The amended Privacy Policy will be effective immediately after it is first posted on our Website. This Privacy Policy is effective as of 30th May 2022."
    export const p2Fr="En utilisant iConz et les services connexes, vous donnez votre consentement explicite à iConz pour la collecte, l'utilisation, la divulgation et la conservation de vos informations personnelles par nous, comme décrit dans la présente politique de confidentialité et nos conditions d'utilisation. iConz peut modifier cette politique de confidentialité de temps à autre. temps. Nous vous conseillons de le lire régulièrement. Les changements substantiels à notre politique de confidentialité seront annoncés sur notre site Web. La politique de confidentialité modifiée entrera en vigueur immédiatement après sa première publication sur notre site Web. Cette politique de confidentialité est effective à compter du 30 mai 2022."

    export const hFr="Quelles informations personnelles collectons-nous ?"
    export const heng="What Personal Information Do We Collect"

    export const p3eng=" 1.For users who are only using the app to find items or servies, we don't collect any personal information from them."
    export const p3Fr="1.Pour les utilisateurs qui n'utilisent l'application que pour trouver des articles ou des services, nous ne collectons aucune information personnelle à leur sujet."

    export const p4eng=" 2.For users who choose to create content on iConz, we collect your name, your email address, your phone number, your whatsApp number and your shop address if you have one."
    export const p4Fr="2.Pour les utilisateurs qui choisissent de créer du contenu sur iConz, nous collectons votre nom, votre adresse e-mail, votre numéro de téléphone, votre numéro WhatsApp et l'adresse de votre boutique si vous en avez une."

    export const h1Fr="Comment nous utilisons les informations personnelles que nous recueillons auprès de vous"
    export const h1eng="How We Use The Personal Information We Collect From You"

    export const p5eng=" We use your name to display it on any item or service you're advertising so other users will know who is advertising the item or service."
    export const p5Fr="Nous utilisons votre nom pour l'afficher sur tout article ou service dont vous faites la publicité afin que les autres utilisateurs sachent qui fait la publicité de l'article ou du service."

    export const p6eng="We use your email address for identifying your account on iConz. Your email address is NOT share with any other user or third party."
    export const p6Fr="Nous utilisons votre adresse e-mail pour identifier votre compte sur iConz. Votre adresse e-mail n'est PAS partagée avec un autre utilisateur ou un tiers."

    export const p7eng="We use your phone number to help buyers reach out to you via phone call if they are interested in an item or service you posted on iConz."
    export const p7Fr="Nous utilisons votre numéro de téléphone pour aider les acheteurs à vous contacter par téléphone s'ils sont intéressés par un article ou un service que vous avez publié sur iConz."

    export const p8eng="We use your whatsApp number to help buyers reach out to you via whatsApp if they are interested in an item or service you posted on iConz."
    export const p8Fr="Nous utilisons votre numéro WhatsApp pour aider les acheteurs à vous contacter via WhatsApp s'ils sont intéressés par un article ou un service que vous avez publié sur iConz."

    export const p9eng="We use this information to help buyers find the physical address of your shop if they want to visit it."
    export const p9Fr="Nous utilisons ces informations pour aider les acheteurs à trouver l'adresse physique de votre boutique s'ils souhaitent la visiter."



    // TERMS AND CONDITIONS AS ON WEBSITE
    export const t1eng="These terms and conditions outline the rules and regulations for the use of iConz."
    export const t1Fr="Ces termes et conditions décrivent les règles et réglementations d'utilisation d'iConz." 

    export const t2eng="By accessing this App we assume you accept these terms and conditions. Do not continue to use iConz if you do not agree to take all of the terms and conditions stated on this page."
    export const t2Fr="En accédant à cette application, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser iConz si vous n'acceptez pas tous les termes et conditions énoncés sur cette page."

    export const t4eng="1.Your access and use of the iConz mobile applications, as well as any service, content, and data available via them (together, the “Service” or the “Platform”) are governed by these Terms."
    export const t4Fr="1.Votre accès et votre utilisation des applications mobiles iConz, ainsi que tout service, contenu et données disponibles via celles-ci (ensemble, le « Service » ou la « Plateforme ») sont régis par les présentes Conditions."

    export const t5eng="2. If you do not agree with any part of these Terms, or if you are not eligible or authorized to be bound by the Terms, then do not access or use the Service."
    export const t5Fr="2. Si vous n'êtes pas d'accord avec une partie de ces Conditions, ou si vous n'êtes pas éligible ou autorisé à être lié par les Conditions, n'accédez pas ou n'utilisez pas le Service."

    export const t6eng="3. iConz is a service which only connects buyers/sellers and service providers/service finders by helping people find items they are in need of."
    export const t6Fr="3. iConz est un service qui relie uniquement les acheteurs/vendeurs et les fournisseurs de services/chercheurs de services en aidant les gens à trouver les articles dont ils ont besoin."

    export const t7eng="4. iConz is not responsible for any payment made for an item or service to any individual you meet on iConz."
    export const t7Fr="4. iConz n'est pas responsable de tout paiement effectué pour un article ou un service à toute personne que vous rencontrez sur iConz."

    export const t8eng="5. iConz is not responsible for any outcome of your business transaction with anyone/business you meet on iConz."
    export const t8Fr="5. iConz n'est pas responsable des résultats de votre transaction commerciale avec toute personne/entreprise que vous rencontrez sur iConz."

    export const t9eng="6.iConz reserves the right to suspend your account if we found out that you have posted any prohibited item/items or carryout any activities that goes against this terms and conditions."
    export const t9Fr="6.iConz se réserve le droit de suspendre votre compte si nous découvrons que vous avez publié des articles interdits ou que vous avez mené des activités contraires aux présentes conditions générales."

    export const theng="Prohibited Content"
    export const thFr="Contenu interdit"

    export const t10eng="Prohibited content refers to post or services related to items that are prohibited to be on iConz. Bellow are content types that are not allowed on iConz."
    export const t10Fr="Le contenu interdit fait référence à la publication ou aux services liés à des éléments interdits sur iConz. Vous trouverez ci-dessous les types de contenu qui ne sont pas autorisés sur iConz."

    export const t11eng="1.We don't allow any sexually explicit content of any kind to be posted on iConz."
    export const t11Fr="1. Nous n'autorisons pas la publication de contenu sexuellement explicite de quelque nature que ce soit sur iConz."

    export const t12eng="2.We don't allow content related to the sale of illegal drugs or substances to be posted on iConz"
    export const t12Fr="2. Nous n'autorisons pas la publication de contenu lié à la vente de drogues ou de substances illégales sur iConz"
    export const t13eng="3.We don't allow content related to the sale of tobacco products of anykind to be posted on iConz."
    export const t13Fr="3. Nous n'autorisons pas la publication de contenu lié à la vente de produits du tabac de quelque nature que ce soit sur iConz."

    export const t14eng="4. We don't allow content related to human trafficking on iConz."
    export const t14Fr="4. Nous n'autorisons pas le contenu lié à la traite des êtres humains sur iConz."

    export const t15eng="5. We don't allow content related to sex trafficking on iConz."
    export const t15Fr="5. Nous n'autorisons pas le contenu lié au trafic sexuel sur iConz."

    export const t1heng="Spamming Content"
    export const t1hFr="Contenu de spam"

    export const t16eng="Spamming Content refer to the act of repeatedly creating or posting the same item or services more than once on iConz. iConz only allow an item to be posted once, even if you have that item in stock, you can only post one version of it once on iConz. If an item posted run out of stock or you're no longer in posession of the given item, you can turn OFF the particular post refering to that item and when you have it back in stock, you can then turned it back ON. Bellow is an example of spamming content. You have more than one post with the following description: Apple,iPhone7 with 32GB storage, 2GB RAM, Brand New, Black in color Bellow is an example of 'not spamming conten.You have one of the items above with the following description: Apple,iPhone7 with 32GB storage, 4GB RAM, Brand New, Black in color."
    export const t16Fr="Le contenu de spam fait référence à l'acte de créer ou de publier à plusieurs reprises le même article ou les mêmes services plus d'une fois sur iConz. iConz n'autorise la publication d'un article qu'une seule fois, même si vous avez cet article en stock, vous ne pouvez en publier qu'une seule version une fois sur iConz. Si un article affiché est en rupture de stock ou si vous n'êtes plus en possession de l'article en question, vous pouvez désactiver la publication particulière faisant référence à cet article et lorsque vous l'avez de nouveau en stock, vous pouvez alors le réactiver. Voici un exemple de contenu de spam. Vous avez plus d'un article avec la description suivante : Apple, iPhone7 avec 32 Go de stockage, 2 Go de RAM, tout neuf, de couleur noire. Ci-dessous un exemple de contenu sans spam. Vous avez l'un des éléments ci-dessus avec la description suivante : Apple , iPhone7 avec 32 Go de stockage, 4 Go de RAM, tout neuf, de couleur noire."

    
  
    