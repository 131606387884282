import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchResults from "../components/SearchResults";
import Seo from "../utils/Seo";

function SearchPage({ uid, userPreferedLang }) {
  const location = useLocation();
  const navigate = useNavigate();

  // IF USER NAVIGATES TO SEARCH PAGE FROM BROWSER
  useEffect(() => {
    if (!location.state) {
      navigate("/*");
    }
  }, [location.state, navigate]);

  // EXTRACT DATA FROM STATE IF FROM USER SEARCH
  const { searchedWord, wordType } = location.state || {};

  return (
    <>
      {/* SEO TAG */}

      <Seo
        title={` ${
          wordType != "suggestedWord" ? "You searched for " : "Your Search"
        }`}
        description={userPreferedLang == "FR" ? " tf.p1Fr" : "tf.p1eng"}
      />
      <div className="w-full md:px-8 mt-4 ">
        {
          <SearchResults
            uid={uid}
            userSearchTerm={searchedWord}
            userPreferedLang={userPreferedLang}
            wordType={wordType}
          />
        }
      </div>
    </>
  );
}

export default SearchPage;
