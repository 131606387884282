import React, { useEffect, useState } from "react";
import "./App.css";
import { signInAnonymouslyAndCheckAuthState } from "./utils/signInUser";
import { TurnOffVPN, CountryNotAvailable } from "./utils/getUserCountry";
import { getAllowedCountries } from "./utils/dbQueries";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TopBar from "./components/TopBar";
import SideMenu from "./components/SideMenu";
import NoMatch from "./pages/NoMatch";
import HowToSell from "./pages/HowToSell";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNCons from "./pages/TermsNCons";
import Home from "./pages/Home";
import UserProfile from "./pages/UserProfile";
import SearchPage from "./pages/SearchPage";
import CreateAdButton from "./components/ui/CreateAdButton";
import AboutUs from "./pages/AboutUs";
import ElectricSymbolsPrivacyPolicy from "./pages/ElectricSymbolsPrivacyPolicy";
import ElectricSymbolsSupport from "./pages/ElectricSymbolsSupport";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const hideDivs = () => {
  //     const { pathname } = location;
  //     if (
  //       pathname === "/es_quiz_privacy_policy" ||
  //       pathname === "/es_quiz_support"
  //     ) {
  //       const divsToHide = document.querySelectorAll(".hide_on_other_pages");
  //       divsToHide.forEach((div) => {
  //         div.style.display = "none";
  //       });
  //     }
  //   };

  //   hideDivs();
  // }, [location]);

  const isHomePage = location.pathname === "/"; 
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Change the overflow property based on whether it's the homepage and on a large screen
    if (isHomePage && isLargeScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Clean up the style when the component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isHomePage, isLargeScreen]);




  // STATE VARIABLES
  const [showMenu, setShowMenu] = useState(false);
  const [countryNotAvailable, setIsCountryAvailable] = useState(false);
  const [isVPNOff, setIsVPNOff] = useState(false);
  const [uid, setUid] = useState(null);
  const [loadedCats, setLoadedCats] = useState(null);
  const [posts, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  // setting the subLocality user selected in local storage
  const [userSelectedSubLocality, setUserSelectedSubLocality] = useState(() => {
    const localData = localStorage.getItem("userSelectedSubLocality");
    return localData ? JSON.parse(localData) : null;
  });
  // user Search Region
  const [userSelectedRegion, setUserSelectedRegion] = useState(null);
  // setting user selected language in local storage
  let userLang = navigator.language || navigator.userLanguage;
  let lang = userLang.substring(0, 2);
  const [userPreferedLang, setUserPreferedLang] = useState(lang.toUpperCase()
  );

  // toggle menu
  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  // redirect to home and reset main values
  function handleIconzLogoclick() {
    navigate("/");
    setPosts([]);
    setLastPost(null);
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedValues([]);
  }

  // ANONYMOUSLY SIGN IN USER
  useEffect(() => {
    signInAnonymouslyAndCheckAuthState()
      .then((uid) => {
        setUid(uid);
        console.log('uid', uid)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Remember language selected
  useEffect(() => {
    localStorage.setItem("userPreferedLang", userPreferedLang);
    localStorage.setItem(
      "userSelectedSubLocality",
      JSON.stringify(userSelectedSubLocality)
    );
    console.log(userPreferedLang)
  }, [userPreferedLang, userSelectedSubLocality]);

  ////////////////////////////// STATUSES
  if (countryNotAvailable) {
    return <CountryNotAvailable />;
  }

  if (isVPNOff) {
    return <TurnOffVPN />;
  }
  /////////////////////////////// STATUSES

  return (
    <>
      {/*
     TOP BAR INCLUDES
     LOGO -- MENU_BUTTON -- SEARCH_BOX -- LOCATION_SELECT -- DOWNLOAD_APP_POPUP 
    */}
      <TopBar
        toggleMenu={toggleMenu}
        showMenu={showMenu}
        setUserPreferedLang={setUserPreferedLang}
        userPreferedLang={userPreferedLang}
        setUserSelectedSubLocality={setUserSelectedSubLocality}
        userSelectedSubLocality={userSelectedSubLocality}
        loadedCats={loadedCats}
        setPosts={setPosts}
        setSelectedMainCategory={setSelectedMainCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        setSelectedValues={setSelectedValues}
        setUserSelectedRegion={setUserSelectedRegion}
        handleIconzLogoclick={handleIconzLogoclick}
        uid={uid}
        setLastPost={setLastPost}
      />
      {/* TOP-BAR  */}

      {/* MAIN PAGE BEGINS HERE */}
      <div className="main_wrapper_content w-full overflow-hidden flex relative">
        {/*   SIDE_MENU -- SIDE_BAR */}
        <div
          className={`hide_on_other_pages ${
            showMenu
              ? "lg:w-[25%] w-64 lg:static fixed top-0 "
              : "w-0 overflow-hidden"
          }  z-50 lg:z-30 left-0 h-screen bg-white transition duration-300 ease-in-out transform hide_on_other_pages ${
            showMenu ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <SideMenu
            setShowMenu={setShowMenu}
            userPreferedLang={userPreferedLang}
            handleIconzLogoclick={handleIconzLogoclick}
          />
        </div>
        {/*   SIDE_MENU -- SIDE_BAR */}

        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                uid={uid}
                showMenu={showMenu}
                userPreferedLang={userPreferedLang}
                userSelectedSubLocality={userSelectedSubLocality}
                setLoadedCats={setLoadedCats}
                loadedCats={loadedCats}
                posts={posts}
                setPosts={setPosts}
                selectedMainCategory={selectedMainCategory}
                setSelectedMainCategory={setSelectedMainCategory}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                userSelectedRegion={userSelectedRegion}
                setLastPost={setLastPost}
                lastPost={lastPost}
              />
            }
          />
          <Route
            path="/how-to-sell-on-iconz"
            element={<HowToSell userPreferedLang={userPreferedLang} />}
          />
          <Route
            path="/about-us"
            element={<AboutUs userPreferedLang={userPreferedLang} />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsNCons userPreferedLang={userPreferedLang} />}
          />
          <Route
            path="/profile/:username"
            element={
              <UserProfile uid={uid} userPreferedLang={userPreferedLang} />
            }
          />
          <Route
            path="/search"
            element={
              <SearchPage uid={uid} userPreferedLang={userPreferedLang} />
            }
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy userPreferedLang={userPreferedLang} />}
          />
          <Route
            path="*"
            element={<NoMatch userPreferedLang={userPreferedLang} />}
          />
          {/* other pages */}
          <Route
            path="/es_quiz_privacy_policy"
            element={<ElectricSymbolsPrivacyPolicy />}
          />
          <Route path="/es_quiz_support" element={<ElectricSymbolsSupport />} />
        </Routes>
      </div>
      {/* CREATE aD button */}
      <CreateAdButton />
    </>
  );
}

export default App;
