import { db } from './firebase';
import { getDocs, collection, where, query, orderBy, limit, getCountFromServer, startAfter } from 'firebase/firestore';
import { country } from './getUserCountry';



// DATABASE COLLECTION REFERENCES USED IN THIS FILE ITSELF
const allowedCountriesRef = collection(db, 'iConz');
const adRef = collection(db, "iConz", `${country}`, "allPosts");
const allCategories = collection(db, 'iConz', `${country}`, 'allCategories');
const UsersRef = collection(db, "iConz", `${country}`, "allUsers");
const allLocalities = collection(db, 'iConz', `${country}`, 'allLocalities');
const allReviews = collection(db, 'iConz', `${country}`, 'allReviews');

// EXPORTED QUERIES FOR USE IN OTHER FILES
export const ad1Ref = collection(db, "iConz", `${country}`, "allPosts");
export const allSubCategories = collection(db, 'iConz', `${country}`, 'allCategories');
export const allChats = collection(db, 'iConz', `${country}`, 'allChats');
export const NotificationsRef = collection(db, 'iConz', `${country}`, 'Notifications');
export const allAdsClicksRef = collection(db, 'iConz', `${country}`, 'allAdClicks');
export const itemClicksRef = collection(db, 'iConz', `${country}`, 'allItemClicks');
export const userSearchRef = collection(db, 'iConz', `${country}`, 'allUserSearch'); 

// PAGESIZE OR QUERY LIMIT
export const PAGESIZE = 25;
   
  // FUNCTION TO GET ALL LOCALITIES
  export async function getAllLocations() {
    try {
      const localitiesRef = query(allLocalities, orderBy("sortingIndex",'asc'));
      const querySnapshot = await getDocs(localitiesRef);
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      return data;
    } catch (error) {
      console.error("Error getting locations: ", error);
      // alert("Error occurred while loading data. Please check your internet and Refresh .");
    }
  }

 
// FUNCTION TO GET ALL POSTS
export const getAllPosts = async (lastPost, adsQuery) => {
  try {
    let postsQuery = query(adsQuery, limit(PAGESIZE));

    if (lastPost) {
      postsQuery = query(
        adsQuery,
        startAfter(lastPost?.updatedAt),
        limit(PAGESIZE)
      );
    }

    // const numberOfDocs = await getCountFromServer(adsQuery);
    // const postsCount = numberOfDocs.data().count;
    const querySnapshot = await getDocs(postsQuery);
    const posts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log("number of docs returned : ", postsCount);

    return posts;
  } catch (error) {
    console.error(error);
    // alert("Error occurred while loading data. Please check your internet and Refresh .");
    // throw error;
  }
};


// FUNCTION TO GET ALL Reviews
export async function getAUserAllReviews(userId, lastPost){
  let userReviewsQuery = query(
    allReviews,
    where('receiverId',"==",userId),
    orderBy('createdAt','desc'),
    limit(PAGESIZE)
  );

  if (lastPost) {
    userReviewsQuery = query(
      allReviews,
      where('receiverId',"==",userId),
      orderBy('createdAt','desc'),
      startAfter(lastPost?.createdAt),
      limit(PAGESIZE)
    );
  }

  const querySnapshot = await getDocs(userReviewsQuery);
  const userReviews = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return userReviews;
 }


//  get user details
export async function getUserDetails(userId) {
  const postsRef = query(UsersRef, where('id', '==', userId));
  const querySnapshot = await getDocs(postsRef);
  const data = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  if (data.length === 0) {
    return null; // or any other falsy value
  }else{
    return data;
  }
}


//  function to count a particular user posts
export async function countUsersPosts(userId){
  const countRef = query(
        adRef,
        where('complete', '==', true),
        where('deleteForever', '==', false), 
        where("trash","==",false),
        where("status","==",true),
        where('adminEnable',"==",true),
        where('approved', '==', true),
        where("userId", "==", userId)
  )
  const numberOfDocs = await getCountFromServer(countRef)
  const totalAds = numberOfDocs.data().count

    return totalAds
 }

 //  FUNCTION TO COUNT ALL A  USERS REVIEWS
 export async function countReviews(userId){
  const countRef = query(allReviews,where('receiverId',"==",userId))
  const numberOfDocs = await getCountFromServer(countRef)
  const totalRevs = numberOfDocs.data().count

    return totalRevs
 }


//  FUNCTION TO GET All  CATEGORIES
export async function getAllCats() {
  try {
    const mainCategoriesRef = query(allCategories, orderBy("sortingIndex",'asc'));
    const mainCategoriesSnapshot = await getDocs(mainCategoriesRef);
    const data = mainCategoriesSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));
    return data;
  } catch (error) {
    console.error("Error getting categories: ", error);
  }
}


//  FUNCTION TO GET ALL COUNTRIES
export async function getAllowedCountries() {
  const querySnapshot = await getDocs(allowedCountriesRef);
  return querySnapshot.docs.map((doc) => doc.data().name);
}

// GET A COUNTRIES INFO
export let currencySign = null
async function getUserCountryCurrencySign(){
  try{
    const postsRef = query(allowedCountriesRef,  `${country}`);
  const querySnapshot = await getDocs(postsRef);
  const data = querySnapshot.docs.map((doc) => doc.data().identifires?.sign)
    currencySign = data[0]
    // console.log("sign : ",currencySign)

  }catch(error){
    console.error("errror",error)
  }
}
getUserCountryCurrencySign()