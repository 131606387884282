import { useState, useEffect } from "react";
import { getAUserAllReviews, PAGESIZE } from "../utils/dbQueries";
import Loading from "./ui/Loading";
import UsersReview from "./UsersReview";
import * as tf from "../utils/translations";

// THIS FUNCTION IS JUST FOR THE USER PROFILE

const InfiniteScrollReview = ({ userId, userName, userPreferedLang }) => {
  const [reviews, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      const newPosts = await getAUserAllReviews(
        userId,
        lastPost && lastPost !== "undefined" ? lastPost : null
      );
      setPosts([...reviews, ...newPosts]);
      setLastPost(newPosts?.[newPosts?.length - 1]);
      // setHasMore(newPosts?.length === PAGESIZE);

      if (newPosts?.length < PAGESIZE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMore();
  }, []);

  //  console.log(reviews)

  const handleScroll = () => {
    const scrollBottom =
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) + 0;
    if (
      scrollBottom >= document.documentElement.offsetHeight ||
      !document.documentElement.offsetHeight
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      {reviews?.map((post, key) => (
        <UsersReview
          key={key}
          post={post}
          userPreferedLang={userPreferedLang}
        />
      ))}

      <div className="">
        {hasMore ? (
          <>
            <div className="">
              <Loading text={`Getting ${userName}'s Posts. Please wait!`} />
            </div>
          </>
        ) : (
          <div className="w-full flex items-center justify-center my-12">
            {userPreferedLang == "FR" ? tf.thatsallFr : "That's All."}
          </div>
        )}
      </div>
    </>
  );
};

export default InfiniteScrollReview;
