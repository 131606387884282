import React from 'react'
import { Link} from "react-router-dom";
import * as tf from '../utils/translations'

function NoMatch({userPreferedLang}) {
  return (
    <>
   {/* 404 PAGE IF USER ENTERS AN INVALID URL */}
<div className="lg:px-24 w-full h-screen bg-white absolute top-0 z-[50] lg:py-24 md:py-20 md:px-44 px-4 pb-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
            <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div className="relative">
                    <div className="absolute">
                        <div className="">
                            <h1 className="my-2 text-gray-800 font-bold text-2xl">
                            {userPreferedLang == 'FR' ? tf.h1404Fr : "Looks like you've found the doorway to the great nothing"}
                            </h1>
                            <p className="my-2 text-gray-800">{userPreferedLang == 'FR' ? tf.p404Fr : 'Sorry about that! Please visit our homepage to get what you need.'}</p>
                            <Link to="/"><button className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-blue-500 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50">{userPreferedLang == 'FR' ? tf.takemehomeFr : 'Take me there!'}</button></Link>         
                        </div>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
                    </div>
                </div>
            </div>
            <div>
                <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
            </div>
        </div>
    </>
  )
}
 
export default NoMatch