import React from 'react'

function SkeleLoadMainCats() {
  return (
    <>
     <div className='p_wrapper flex justify-around items-center '>
<div role="status" className="m-2 bg-gray-300 p-1 rounded-full w-[150px] flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>
<div role="status" className="m-2 bg-gray-300 p-1 rounded-full w-[150px] flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>
<div role="status" className="m-2 bg-gray-300 p-1 rounded-full w-[150px] flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>

<div role="status" className="hidden m-2 bg-gray-300 p-1 rounded-full w-[150px] lg:flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>
<div role="status" className=" hidden m-2 bg-gray-300 p-1 rounded-full w-[150px] lg:flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>
<div role="status" className="hidden m-2 bg-gray-300 p-1 rounded-full w-[150px] lg:flex justify-between items-center animate-pulse ">
    <div className="h-5 w-5 p-2  rounded-full bg-gray-200 "></div>
    <div className="h-5 w-full ml-1 p-2 rounded-full bg-gray-200 "></div>
    <span className="sr-only">Loading...</span>
</div>
</div>
    </>
  )
}

export default SkeleLoadMainCats