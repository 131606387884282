import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaStar } from "react-icons/fa";
import { getTimePost } from "../utils/convertDate";
import { useNavigate } from "react-router-dom";
import * as Query from "../utils/dbQueries";
import * as tf from "../utils/translations";
import { doc, setDoc } from "firebase/firestore";
import {
  NOTIFICATION_SENDING_USER_TYPE_ANONYMOUS,
  NOTIFICATION_TYPE_PROFILE_VIEW,
} from "../utils/constants";
import { Link } from "react-router-dom";

function UserPostProfile({ user_object, userPreferedLang, uid, ad_object }) {
  // COMPONENT VARIABLES
  const [totalAds, setTotalAds] = useState(0);
  const [totalRevs, setTotalRevs] = useState(0);
  const [rating, setRatings] = useState([]);

  // GET USER STATS
  useEffect(() => {
    // get posts counts
    Query.countUsersPosts(user_object?.id).then((data) => {
      setTotalAds(data);
    });

    // get review counts
    Query.countReviews(user_object?.id).then((data) => {
      setTotalRevs(data);
    });

    // get user reviews
    Query.getAUserAllReviews(user_object?.id).then((data) => {
      setRatings(data);
    });
  }, []);

  // send user notification that profile has been viewed
  const sendViewedProfilePushNotification = async (user_object) => {
    const createNotificationDoc = doc(Query.NotificationsRef);
    const notificationId = createNotificationDoc.id;
    try {
      const notification = {
        id: notificationId,
        receiverDeviceToken: user_object?.fcmToken,
        titleEn: "iConz",
        titleFr: "iConz",
        senderDeviceToken: "",
        textEn: "Someone has viewed your Profile",
        textFr: "Quelqu'un a vu votre Profile",
        sendingUser: null,
        sendingUserType: NOTIFICATION_SENDING_USER_TYPE_ANONYMOUS,
        createdAt: new Date(),
        updatedAt: new Date(),
        notificationType: NOTIFICATION_TYPE_PROFILE_VIEW,
        sendingUserId: uid,
        receivingUser: user_object,
        receivingUserId: user_object?.id,
        itemId: ad_object?.id,
        viewed: false,
      };

      await setDoc(doc(Query.NotificationsRef, notification.id), notification);

      //  console.log(notification)
    } catch (error) {
      console.log("error sending viewed profile notification", error);
    }
  };

  // Step 1: Calculate the sum of all ratings
  let sum = 0;
  for (let i = 0; i < rating?.length; i++) {
    sum += rating[i]?.rating;
  }
  // Step 2: Calculate the average rating
  let avgRating = sum / rating?.length;
  // Step 3: Round the average rating to one decimal place
  let avgRatingRounded = avgRating.toFixed(1);
  // Step 5: Map the average rating to a corresponding number of stars
  avgRating = avgRatingRounded;

  return (
    <>
      <Link
        to={{ pathname: `/profile/${user_object?.userName}` }}
        state={{ id: user_object?.id }}
        onClick={() => {
          // send user notification
          sendViewedProfilePushNotification(user_object);
        }}
        className="user_info  lg:px-4 p-1 text-xs lg:text-sm flex justify-between items-center my-2 shadow-lg rounded-[10px]"
      >
        <div className="profile_pic flex items-center cursor-pointer">
          <img
            src={user_object?.profilePhotoUrl}
            alt={user_object?.userName}
            className="w-[40px] h-[40px]  mr-1 rounded-full"
          />

          <div className="info">
            <p className=" capitalize font-bold flex items-center text-xs lg:text-sm">
              {" "}
              {user_object?.userName}
              {user_object?.verified ? (
                <span>
                  <FaCheckCircle className="text-[#2B9EDA] text-xs ml-1 mr-2" />
                </span>
              ) : null}
              <span className="flex items-center font-medium">
                {" "}
                {isNaN(avgRating) ? "0" : avgRating}{" "}
                <FaStar className="text-[#2B9EDA] ml-1" />{" "}
              </span>{" "}
            </p>

            <p className="text-gray-500 text-xs">
              {userPreferedLang == "FR" ? tf.registeredFr : "Registered"}{" "}
              {getTimePost(user_object?.createdAt, userPreferedLang)}
            </p>

            <div
              className={`${
                userPreferedLang == "FR" ? "text-xs " : "text-sm"
              } text-[#2B9EDA] font-medium `}
            >
              {userPreferedLang == "FR"
                ? tf.viewfullprofileFr
                : "View full profile"}
            </div>
          </div>
        </div>
        {/* USER location */}
        <div className="location text-center ">
          <p className="font-medium text-[18px]">{totalAds}</p>
          <p
            className={`${
              userPreferedLang == "FR"
                ? "text-gray-500 text-[.7rem]"
                : "text-gray-500 text-xs"
            }`}
          >
            {userPreferedLang == "FR" ? tf.activeAdsFr : "Active Ads"}
          </p>
        </div>

        {/* DISPLAY STATS */}
        <div className="text-center">
          <p className="font-medium text-[18px]">{totalRevs}</p>
          <p
            className={`${
              userPreferedLang == "FR"
                ? "text-gray-500 text-[.7rem]"
                : "text-gray-500 text-xs"
            }`}
          >
            {userPreferedLang == "FR" ? tf.reviewsFr : "Reviews"}
          </p>
        </div>
      </Link>
    </>
  );
}

export default UserPostProfile;
