
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


// TEST CONFIG
const firebaseConfig = {
    apiKey: "AIzaSyBEgHq5KLijo_2hCtWpWiZWJdF76XZrkuU",
    authDomain: "wecon-91e87.firebaseapp.com",
    databaseURL: "https://wecon-91e87.firebaseio.com",
    projectId: "wecon-91e87",
    storageBucket: "wecon-91e87.appspot.com",
    messagingSenderId: "792622036439",
    appId: "1:792622036439:web:bfde777e80de90be0f0792",
    measurementId: "G-WER6D1XCSF"
};
  


const app = initializeApp(firebaseConfig);
// Initialize Analytics
const analytics = getAnalytics(app);
export const auth = getAuth(app);
// firebase database
export const db = getFirestore(app);