import React, { useState, useEffect} from "react";
import CategoryMenuBar from "../components/CategoryMenuBar";
import { ad1Ref, getAllPosts, PAGESIZE } from "../utils/dbQueries";
import * as tf from "../utils/translations";
import SkeletonLoading from "../components/ui/SkeletonLoading";
import { where, query, orderBy } from "firebase/firestore";
import Seo from "../utils/Seo";
import LgPostAd from "../components/LgPostAd";
import MobilePostAd from "../components/MobilePostAd";

function Home({
  uid,
  showMenu,
  userPreferedLang,
  userSelectedSubLocality,
  loadedCats,
  setLoadedCats,
  posts,
  setPosts,
  selectedMainCategory,
  setSelectedMainCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedValues,
  setSelectedValues,
  userSelectedRegion,
  lastPost,
  setLastPost,
}) {
  // COMPONENT VARIABLE DECLARATIONS
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  // category selection related  variables
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  // TRACK AND MAKE SURE THERE IS A RERENDER WHEN VARIABLES CHANGE
  const [rerenderCount, setRerenderCount] = useState(0);
  const [buttonIfAlertWasShown, setButtonAlert] = useState(false);

  // LOAD  POSTS
  const loadPosts = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      let newPosts = [];

      // IF USER IS CHANGING SUBLOCALITIES OR COMING-BACK PREVIOUSLY SELECTED A SUB-LOCALITY
      if (userSelectedSubLocality?.id !== undefined) {
        // if THERE IS A SELECTED SUB-LOCALITY AND A CATEGORY IS SELECTED
        if (selectedMainCategory?.id) {
          // if THERE IS  A SELECTED SUB-LOCALITY,CATEGORY AND A SUB-CAT IS SELECTED
          if (selectedSubCategory?.id) {
            // if THERE IS  A SELECTED SUB-LOCALITY,CATEGORY, SUB-CAT AND 1 OR MORE QUERYPARAMS IS(ARE) SELECTED
            if (selectedValues) {
              const keys = Object.values(selectedValues);
              const queryTag = keys.join("");
              if (queryTag !== "") {
                const postsQuery = query(
                  ad1Ref,
                  where("complete", "==", true),
                  where("deleteForever", "==", false),
                  where("trash", "==", false),
                  where("status", "==", true),
                  where("adminEnable", "==", true),
                  where("approved", "==", true),
                  where("catId", "==", selectedMainCategory?.id),
                  where("subCatId", "==", selectedSubCategory?.id),
                  where("subLocalityId", "==", userSelectedSubLocality?.id),
                  where("queryTags", "array-contains", queryTag),
                  orderBy("updatedAt", "desc")
                );

                newPosts = await getAllPosts(lastPost, postsQuery);

                setPosts([...posts, ...newPosts]);
                setLastPost(newPosts?.[newPosts?.length - 1]);

                if (newPosts?.length < PAGESIZE) {
                  setHasMore(false);
                }

                //  console.log('user has selected a mainCat, a subCat, queryParam(s) and a Sub-locality is available')
                //  console.log("QueryParam selected : ",queryTag)
              }
              // IF THE QUERY PARAMS IS RESETTER
              else {
                const postsQuery = query(
                  ad1Ref,
                  where("complete", "==", true),
                  where("deleteForever", "==", false),
                  where("trash", "==", false),
                  where("status", "==", true),
                  where("adminEnable", "==", true),
                  where("approved", "==", true),
                  where("catId", "==", selectedMainCategory?.id),
                  where("subCatId", "==", selectedSubCategory?.id),
                  where("subLocalityId", "==", userSelectedSubLocality?.id),
                  orderBy("updatedAt", "desc")
                );

                newPosts = await getAllPosts(lastPost, postsQuery);

                setPosts([...posts, ...newPosts]);
                setLastPost(newPosts?.[newPosts?.length - 1]);

                if (newPosts?.length < PAGESIZE) {
                  setHasMore(false);
                }

                // console.log('user has selected a mainCat and subCat and there"s  a Sub-locality available')
                // console.log('selected SubCat ',selectedSubCategory?.name_en)
              }
            }
            // if THERE IS  A SELECTED SUB-LOCALITY,CATEGORY AND JUST A SUB-CAT IS SELECTED WITHOUT QUERY-PARAMS
            else {
              const postsQuery = query(
                ad1Ref,
                where("complete", "==", true),
                where("deleteForever", "==", false),
                where("trash", "==", false),
                where("status", "==", true),
                where("adminEnable", "==", true),
                where("approved", "==", true),
                where("subLocalityId", "==", userSelectedSubLocality?.id),
                orderBy("updatedAt", "desc")
              );

              newPosts = await getAllPosts(lastPost, postsQuery);

              setPosts([...posts, ...newPosts]);
              setLastPost(newPosts?.[newPosts?.length - 1]);

              if (newPosts?.length < PAGESIZE) {
                setHasMore(false);
              }

              console.log(
                'user has selected a mainCat and subCat and there"s  a Sub-locality available'
              );
              console.log("selected SubCat ", selectedSubCategory?.name_en);
            }
          }
          // if THERE IS A SELECTED SUB-LOCALITY AND JUST A CATEGORY IS SELECTED WITHOUT SUB-CAT
          else {
            const postsQuery = query(
              ad1Ref,
              where("complete", "==", true),
              where("deleteForever", "==", false),
              where("trash", "==", false),
              where("status", "==", true),
              where("adminEnable", "==", true),
              where("approved", "==", true),
              where("catId", "==", selectedMainCategory?.id),
              where("subLocalityId", "==", userSelectedSubLocality?.id),
              orderBy("updatedAt", "desc")
            );

            newPosts = await getAllPosts(lastPost, postsQuery);

            setPosts([...posts, ...newPosts]);
            setLastPost(newPosts?.[newPosts?.length - 1]);

            if (newPosts?.length < PAGESIZE) {
              setHasMore(false);
            }

            // console.log('user has selected a mainCat and there is a Sub-locality available')
            // console.log('selected MainCat ',selectedMainCategory?.name_en)
          }
        }
        // if THERE IS  A SELECTED SUB-LOCALITY AND NO CATEGORY SELECTED JUST SHOW POSTS IN THAT LOCALITY
        else {
          // query posts with search locality
          const postsQuery = query(
            ad1Ref,
            where("complete", "==", true),
            where("deleteForever", "==", false),
            where("trash", "==", false),
            where("status", "==", true),
            where("adminEnable", "==", true),
            where("approved", "==", true),
            where("subLocalityId", "==", userSelectedSubLocality?.id),
            orderBy("updatedAt", "desc")
          );

          newPosts = await getAllPosts(lastPost, postsQuery);

          setPosts([...posts, ...newPosts]);
          setLastPost(newPosts?.[newPosts?.length - 1]);

          if (newPosts?.length < PAGESIZE) {
            setHasMore(false);
          }

          // console.log('showing all posts in Sub-locality : ', userSelectedSubLocality?.name_en)
        }
      }
      // HERE THE NO USER LOCATION/REGION OR NO SUB-LOCALITY IN LOCAL STORAGE EXIST IN LOCAL STORAGE
      // FIRST TIME ENTERING THE SITE AND GOES STRAIGHT TO CATEGORIES
      else if (selectedMainCategory?.id && !userSelectedSubLocality) {
        // if THERE IS  A SELECTED CATEGORY AND A SUB-CAT IS SELECTED BUT NO SUB-LOCALITY
        if (selectedSubCategory?.id) {
          // if THERE IS  A SELECTED CATEGORY, SUB-CAT AND 1 OR MORE QUERYPARAMS IS(ARE) selected BUT NO SUB-LOCALITY
          if (selectedValues) {
            const keys = Object.values(selectedValues);
            const queryTag = keys.join("");

            // console.log("selected queries: ", selectedValues)
            if (queryTag !== "") {
              const postsQuery = query(
                ad1Ref,
                where("complete", "==", true),
                where("deleteForever", "==", false),
                where("trash", "==", false),
                where("status", "==", true),
                where("adminEnable", "==", true),
                where("approved", "==", true),
                where("catId", "==", selectedMainCategory?.id),
                where("subCatId", "==", selectedSubCategory?.id),
                where("queryTags", "array-contains", queryTag),
                orderBy("updatedAt", "desc")
              );

              newPosts = await getAllPosts(lastPost, postsQuery);

              setPosts([...posts, ...newPosts]);
              setLastPost(newPosts?.[newPosts?.length - 1]);

              if (newPosts?.length < PAGESIZE) {
                setHasMore(false);
              }

              //  console.log('user has selected a mainCat, a subCat, queryParam(s)')
              //  console.log("QueryParam selected : ",queryTag)
            }
            // IF THE QUERY PARAMS IS RESET
            else {
              const postsQuery = query(
                ad1Ref,
                where("complete", "==", true),
                where("deleteForever", "==", false),
                where("trash", "==", false),
                where("status", "==", true),
                where("adminEnable", "==", true),
                where("approved", "==", true),
                where("catId", "==", selectedMainCategory?.id),
                where("subCatId", "==", selectedSubCategory?.id),
                orderBy("updatedAt", "desc")
              );

              newPosts = await getAllPosts(lastPost, postsQuery);

              setPosts([...posts, ...newPosts]);
              setLastPost(newPosts?.[newPosts?.length - 1]);

              if (newPosts?.length < PAGESIZE) {
                setHasMore(false);
              }

              // console.log('user has selected a mainCat and subCat ')
              // console.log('selected SubCat ',selectedSubCategory?.name_en)
            }
          }
          // if THERE IS  A SELECTED CATEGORY AND  A SUB-CAT IS SELECTED WITHOUT QUERY-PARAMS
          else {
            const postsQuery = query(
              ad1Ref,
              where("complete", "==", true),
              where("deleteForever", "==", false),
              where("trash", "==", false),
              where("status", "==", true),
              where("adminEnable", "==", true),
              where("approved", "==", true),
              where("catId", "==", selectedMainCategory?.id),
              where("subCatId", "==", selectedSubCategory?.id),
              orderBy("updatedAt", "desc")
            );

            newPosts = await getAllPosts(lastPost, postsQuery);

            setPosts([...posts, ...newPosts]);
            setLastPost(newPosts?.[newPosts?.length - 1]);

            if (newPosts?.length < PAGESIZE) {
              setHasMore(false);
            }

            // console.log('user has selected a mainCat and subCat')
            // console.log('selected SubCat ',selectedSubCategory?.name_en)
          }
        }
        // if THERE IS A SELECTED MAIN CATEGORY AND NO SUB-CATEGORY IS SELECTED
        else {
          const postsQuery = query(
            ad1Ref,
            where("complete", "==", true),
            where("deleteForever", "==", false),
            where("trash", "==", false),
            where("status", "==", true),
            where("adminEnable", "==", true),
            where("approved", "==", true),
            where("catId", "==", selectedMainCategory?.id),
            orderBy("updatedAt", "desc")
          );

          newPosts = await getAllPosts(lastPost, postsQuery);

          setPosts([...posts, ...newPosts]);
          setLastPost(newPosts?.[newPosts?.length - 1]);

          if (newPosts?.length < PAGESIZE) {
            setHasMore(false);
          }

          // console.log('user has selected a mainCat', selectedMainCategory?.name_en)
        }
      }
      // HERE THE USER HAS RESET SUB-LOCALITY AND WANTS ALL POSTS IN A PARTICULAR REGION
      else if (userSelectedRegion?.id) {
        // if THERE IS A SELECTED REGION AND A CATEGORY IS SELECTED
        if (selectedMainCategory?.id) {
          // if THERE IS  A SELECTED LOCALITY,CATEGORY AND A SUB-CAT IS SELECTED
          if (selectedSubCategory?.id) {
            // if THERE IS  A SELECTED LOCALITY,CATEGORY, SUB-CAT AND 1 OR MORE QUERY-PARAMS IS(ARE) SELECTED
            if (selectedValues) {
              const keys = Object.values(selectedValues);
              const queryTag = keys.join("");
              if (queryTag !== "") {
                const postsQuery = query(
                  ad1Ref,
                  where("complete", "==", true),
                  where("deleteForever", "==", false),
                  where("trash", "==", false),
                  where("status", "==", true),
                  where("adminEnable", "==", true),
                  where("approved", "==", true),
                  where("catId", "==", selectedMainCategory?.id),
                  where("subCatId", "==", selectedSubCategory?.id),
                  where("localityId", "==", userSelectedRegion?.id),
                  where("queryTags", "array-contains", queryTag),
                  orderBy("updatedAt", "desc")
                );

                newPosts = await getAllPosts(lastPost, postsQuery);

                setPosts([...posts, ...newPosts]);
                setLastPost(newPosts?.[newPosts?.length - 1]);

                if (newPosts?.length < PAGESIZE) {
                  setHasMore(false);
                }

                //  console.log('user has selected a mainCat, a subCat, queryParam(s) and a locality is available')
                //  console.log("QueryParam selected : ",queryTag)
              }
              // IF THE QUERY PARAMS IS RESETTED
              else {
                const postsQuery = query(
                  ad1Ref,
                  where("complete", "==", true),
                  where("deleteForever", "==", false),
                  where("trash", "==", false),
                  where("status", "==", true),
                  where("adminEnable", "==", true),
                  where("approved", "==", true),
                  where("catId", "==", selectedMainCategory?.id),
                  where("subCatId", "==", selectedSubCategory?.id),
                  where("localityId", "==", userSelectedRegion?.id),
                  orderBy("updatedAt", "desc")
                );

                newPosts = await getAllPosts(lastPost, postsQuery);

                setPosts([...posts, ...newPosts]);
                setLastPost(newPosts?.[newPosts?.length - 1]);

                if (newPosts?.length < PAGESIZE) {
                  setHasMore(false);
                }

                // console.log('user has selected a mainCat and subCat and there"s a locality available')
                // console.log('selected SubCat ',selectedSubCategory?.name_en)
              }
            }
            // if THERE IS  A SELECTED LOCALITY,CATEGORY AND JUST A SUB-CAT IS SELECTED WITHOUT QUERYPARAMS
            else {
              const postsQuery = query(
                ad1Ref,
                where("complete", "==", true),
                where("deleteForever", "==", false),
                where("trash", "==", false),
                where("status", "==", true),
                where("adminEnable", "==", true),
                where("approved", "==", true),
                where("catId", "==", selectedMainCategory?.id),
                where("subCatId", "==", selectedSubCategory?.id),
                where("localityId", "==", userSelectedRegion?.id),
                orderBy("updatedAt", "desc")
              );

              newPosts = await getAllPosts(lastPost, postsQuery);

              setPosts([...posts, ...newPosts]);
              setLastPost(newPosts?.[newPosts?.length - 1]);

              if (newPosts?.length < PAGESIZE) {
                setHasMore(false);
              }

              // console.log('user has selected a mainCat and subCat and there"s a locality available')
              // console.log('selected SubCat ',selectedSubCategory?.name_en)
            }
          }
          // if THERE IS A SELECTED LOCALITY AND JUST A CATEGORY IS SELECTED WITHOUT SUB-CAT
          else {
            const postsQuery = query(
              ad1Ref,
              where("complete", "==", true),
              where("deleteForever", "==", false),
              where("trash", "==", false),
              where("status", "==", true),
              where("adminEnable", "==", true),
              where("approved", "==", true),
              where("catId", "==", selectedMainCategory?.id),
              where("localityId", "==", userSelectedRegion?.id),
              orderBy("updatedAt", "desc")
            );

            newPosts = await getAllPosts(lastPost, postsQuery);

            setPosts([...posts, ...newPosts]);
            setLastPost(newPosts?.[newPosts?.length - 1]);

            if (newPosts?.length < PAGESIZE) {
              setHasMore(false);
            }

            // console.log('user has selected a mainCat and there is a locality available')
            // console.log('selected MainCat ',selectedMainCategory?.name_en)
          }
        }
        // if THERE IS  A SELECTED LOCALITY AND NO CATEGORY SELECTED JUST SHOW POSTS IN THAT LOCALITY
        else {
          // query posts with search locality
          const postsQuery = query(
            ad1Ref,
            where("complete", "==", true),
            where("deleteForever", "==", false),
            where("trash", "==", false),
            where("status", "==", true),
            where("adminEnable", "==", true),
            where("approved", "==", true),
            where("localityId", "==", userSelectedRegion?.id),
            orderBy("updatedAt", "desc")
          );

          newPosts = await getAllPosts(lastPost, postsQuery);

          setPosts([...posts, ...newPosts]);
          setLastPost(newPosts?.[newPosts?.length - 1]);

          if (newPosts?.length < PAGESIZE) {
            setHasMore(false);
          }
          // console.log('showing all posts in Locality/Region : ', userSelectedRegion?.name_en)
        }
      }
      // SHOW ALL POSTS ALL POSTS (USER HAS MADE ANY QUERY)
      else {
        // query all posts
        const postsQuery = query(
          ad1Ref,
          where("complete", "==", true),
          where("deleteForever", "==", false),
          where("trash", "==", false),
          where("status", "==", true),
          where("adminEnable", "==", true),
          where("approved", "==", true),
          orderBy("updatedAt", "desc")
        );

        newPosts = await getAllPosts(lastPost, postsQuery);

        setPosts([...posts, ...newPosts]);
        setLastPost(newPosts?.[newPosts?.length - 1]);

        if (newPosts?.length < PAGESIZE) {
          setHasMore(false);
        }
        console.log("showing all posts");
      }
    } catch (error) {
      console.error(error);
      // alert('Failed to fetch data. Please check your network connection.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // reset posts and lastPost when user selects a new query condition
    // SET POST[] IS NOT HERE BECAUSE WHERE A VARIABLE IS CHANGED THE SET POSTS RESET IN THE RESPECTIVE FUNCTIONS
    setLastPost(null);
    setHasMore(true);

    if (uid !== null) {
      loadPosts();
    }
  }, [
    selectedMainCategory,
    selectedSubCategory,
    selectedValues,
    userSelectedSubLocality,
    userSelectedRegion,
    uid,
    rerenderCount,
  ]);

  // HANDLE USER SCROLLING TO BOTTOM TO TRIGGER LOAD MORE
  const handleScroll = () => {
    const scrollBottom =
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) + 0;
    const threshold = window.innerHeight / 2; // Adjust this value based on your requirements

    const currentHeight = document.documentElement.offsetHeight;

    if (scrollBottom >= currentHeight - threshold || !currentHeight) {
      loadPosts();
    }
  };

  // CLEAN THE FUNCTION
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  // HANDLE SCROLL EFFECT FOR LARGE SCREENS
    const handleScrollG = () => {
      const scrollableDiv = document.getElementById("ScrollableDiv");
      const scrollBottom =
        Math.ceil(scrollableDiv.scrollTop + scrollableDiv.clientHeight) + 0;
      const threshold = scrollableDiv.clientHeight / 2; // Adjust this value based on your requirements

      const currentHeight = scrollableDiv.scrollHeight;

      if (scrollBottom >= currentHeight - threshold || !currentHeight) {
        loadPosts();
      }
    };

    useEffect(() => {
      const scrollableDiv = document.getElementById("ScrollableDiv");
      scrollableDiv.addEventListener("scroll", handleScrollG);

      return () => {
        scrollableDiv.removeEventListener("scroll", handleScrollG);
      };
    });


  // TRACK AND RERENDER WHEN VARIABLE CHANGE
  useEffect(() => {
    setRerenderCount((count) => count + 1);
  }, [
    selectedMainCategory,
    selectedSubCategory,
    selectedValues,
    userSelectedSubLocality,
    userSelectedRegion,
  ]);


  // check if user clicked connect it within 24hours
  useEffect(() => {
    let currentTime = new Date().getTime();
    // Get the expiration time of the popup
    const expirationTime = localStorage.getItem("warningExpirationTime");
    // Check if the popup has been shown before
    const warningShown = localStorage.getItem("warningShown");

    // If the popup has been shown before and it hasn't expired yet, don't show the popup
    if (warningShown && currentTime < expirationTime) {
      setButtonAlert(true);
      const timeLeft = Math.round((expirationTime - currentTime) / 1000);
      console.log(`Time left: ${timeLeft} seconds`);
    } else {
      console.log("warning shown already ");
    }
  }, [buttonIfAlertWasShown]);


  // SHOW LOADED/FETCHED ADS
  console.log("FETCHED Ads : ", posts);

  return (
    <>
      {/* SEO TAG */}

      <Seo
        title={
          "iConz - Your Go-To Platform for Buying and Selling Anything in Cameroon"
        }
        description={
          "Buy and sell anything in Cameroon on iConz. Our easy-to-use platform and exceptional customer service make it simple to find what you need. Get started today!"
        }
      />

      {/* MAIN CONTENT AREA RIGHT */}
      <div
        className={`${
          showMenu ? "overflow-hidden" : "overflow-y-scroll"
        } w-full no-scroll-bar  relative`}
      >
        {/* CATEGORIES AND SUB-CATEGORIES COMPONENT */}
        <div className="categories lg:sticky lg:top-0 z-40 bg-white">
          <CategoryMenuBar
            setLoadedCats={setLoadedCats}
            loadedCats={loadedCats}
            setMainCategories={setMainCategories}
            subCategories={subCategories}
            setSubCategories={setSubCategories}
            selectedMainCategory={selectedMainCategory}
            setSelectedMainCategory={setSelectedMainCategory}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            userPreferedLang={userPreferedLang}
            setPosts={setPosts}
            uid={uid}
            setLastPost={setLastPost}
          />
          <div className="relative w-full mt-4 ">
            <div className="absolute h-6 inset-0 -top-3">
              <div className="h-6 w-full gradient-fade"></div>
            </div>
          </div>
        </div>

        {/* ADS/PRODUCTS CONTAINER FOR LARGE SCREENS */}
        {/* LOOP THROUGH POSTS TO DISPLAY ADS */}
        <div
          id="ScrollableDiv"
          className=" w-full lg:h-[90vh] lg:overflow-y-scroll lg_scroll bg-[#e8f1f5f7] lg:pb-[80px]"
        >
          <div className="relative hidden min_wrapper w-full lg:grid p-2">
           
            {posts?.map((post, key) => (
              <LgPostAd
                key={key}
                post_object={post}
                loadedCats={loadedCats}
                uid={uid}
                userPreferedLang={userPreferedLang}
                buttonIfAlertWasShown={buttonIfAlertWasShown}
                setButtonAlert={setButtonAlert}
              />
            ))}
          </div>
          {/* ADS/PRODUCTS CONTAINER FRO LARGE SCREENS */}

          {/* ADS CONTAINER FOR SMALL DEVICES */}
          <div className="small_screens grid grid-cols-2 gap-2 lg:hidden bg-[#e8f1f5f7] px-2 mt-2 ">
            {posts?.map((post, key) => (
              <MobilePostAd
                key={key}
                post_object={post}
                loadedCats={loadedCats}
                uid={uid}
                userPreferedLang={userPreferedLang}
                buttonIfAlertWasShown={buttonIfAlertWasShown}
                setButtonAlert={setButtonAlert}
              />
            ))}
          </div>
          {/* ADS CONTAINER FOR SMALL DEVICES */}

          {/* QUERY/STATE OF EXECUTION
  1. IF HAS MORE SHOW LOADING AND THEN MORE ADS LOADS
  2. IF POST ARRAY IS EMPTY SHOW NO POSTS
  */}
          <div className="execution_states">
            {hasMore ? (
              <>
                <div className="grid grid-cols-2 gap-2 lg:gap-[10px] bg-[#e8f1f5f7] p-2 lg:grid-cols-5">
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <div className="hidden lg:flex">
                    <SkeletonLoading />
                  </div>
                </div>
              </>
            ) : !hasMore && posts?.length !== 0 ? (
              <div className="w-full flex items-center justify-center my-12">
                {userPreferedLang === "FR" ? tf.thatsallFr : "That's All."}
              </div>
            ) : posts?.length === 0 ? (
              <div className="w-full flex items-center justify-center my-12">
                <div className="w-1/2 flex justify-center items-center bg-[#2B9EDA] text-white rounded-md p-2 shadow-xl">
                  <p>
                    {userPreferedLang === "FR"
                      ? tf.nopostFr
                      : "No Products here."}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          {/* STATES OF EXECUTION */}
        </div>
      </div>
    </>
  );
}

export default Home;
