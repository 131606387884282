
    // SEARCH OBJECT TYPES
      export const SEARCH_OBJECT_TYPE_CAT = "SearchTypeCat"
      export const SEARCH_OBJECT_TYPE_SUB_CAT = "SearchTypeSubcat"
      export const SEARCH_OBJECT_TYPE_QUERY_PARAM_ITEM = "SearchTypeQueryParamItem"

    // AD/POST CONDITIONS
      export const REFURBISHEDen = "Refurbished"
      export const USEDen = "Used"
      export const FOREIGN_USEDen = "Foreign Used"
      export const LOCALLY_USEDen = "Locally Used"

     // NOTIFICATION SENDING TYPES
     export const NOTIFICATION_SENDING_USER_TYPE_ANONYMOUS = "anonymous"

    //  NOTIFICATION TYPES
    export const NOTIFICATION_TYPE_POST_VIEWED="postViewed"
    export const NOTIFICATION_TYPE_PROFILE_VIEW = "profileViewed"

    // ALL CLICK TYPES
    export const CLICK_TYPE_AD = "CLICK_TYPE_AD"

    // USER SEARCH TYPES
    export const USER_SEARCH_TYPE_MAIN_SEARCH = "USER_SEARCH_TYPE_MAIN_SEARCH" //STRING SEARCH ON SEARCH BUTTON CLICK
    export const USER_SEARCH_TYPE_CAT_SEARCH = "USER_SEARCH_TYPE_CAT_SEARCH" //MAIN CATEGORY
    export const USER_SEARCH_TYPE_SUB_CAT_SEARCH = "USER_SEARCH_TYPE_SUB_CAT_SEARCH" //SUB-CAT SEARCH
    export const USER_SEARCH_TYPE_QUERY_PARAM_ITEM_SEARCH = "USER_SEARCH_TYPE_QUERY_PARAM_ITEM_SEARCH" //QUERY PARAM

    // ITEM CLICK TYPES
    export const CLICK_TYPE_LOCALITIES_ITEM = "CLICK_TYPE_LOCALITIES_ITEM"
    export const CLICK_TYPE_SUB_LOCALITIES_ITEM = "CLICK_TYPE_SUB_LOCALITIES_ITEM"
    export const CLICK_TYPE_CATEGORY_ITEM = "CLICK_TYPE_CATEGORY_ITEM"
    export const CLICK_TYPE_SUB_CATEGORY_ITEM = "CLICK_TYPE_SUB_CATEGORY_ITEM"
    export const CLICK_TYPE_QUERY_PARAM_ITEM = "CLICK_TYPE_QUERY_PARAM_ITEM"
    export const CLICK_TYPE_SEARCH_SUGGESTION_ITEM = "CLICK_TYPE_SEARCH_SUGGESTION_ITEM"


    // ITEM TYPES
    export const  POST_TYPE_ITEM_FOR_SALE ="itemForSale" //normal price
    export const  POST_TYPE_HOUSE_FOR_RENT ="houseForRent" //per month
    export const  POST_TYPE_SHORT_LET ="shortLet" //perday
    export const  POST_TYPE_VEHICLE_FOR_RENT ="vehicleForRent"
    export const  POST_TYPE_SERVICE ="service"
    export const  POST_TYPE_GOOGLE_AD ="googleAd"



      //Production Database
      //  export const MOBILE_PHONES = "2dPagavSmlOUe8fCUX9d";
      //  export const LAPTOPS_COMPUTERS = "CsRxF2t6GJxECRWGmvjp";
      //  export const FLAT_SCREEN_TVS = "FkPB25tsteto5fvyIlbC";
      //  export const CARS = "73h7FkSzqSW4mDpBnT3L";
      //  export const FURNITURE = "ekCgNQKDgraUOvaOkzbk";
      //  export const LAND_PLOTS_FOR_SALE = "YUF8ooxru23y0YZ7OPAh";
  
      //Test Database
       export const MOBILE_PHONES = "Qoz1ffRjwMddD6Gd70Ec";
       export const LAPTOPS_COMPUTERS = "PxrdRaXCnNEoDSgmez4L";
       export const FLAT_SCREEN_TVS = "rkef3MKH1EyPHAgxPFcd";
       export const CARS = "ICQJJYqi4JTCiFrSiqFT";
       export const FURNITURE = "sm9HasrVpBoWcIZxrH5X";
       export const LAND_PLOTS_FOR_SALE = "3wn88fXBrr8zS4lX5T9x";
  