import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../assets/custom.css";
import { Carousel } from "react-responsive-carousel";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import UserPostProfile from "./UserPostProfile";
import { getTime } from "../utils/convertDate";
import * as tf from "../utils/translations";
import { currencySign } from "../utils/dbQueries";

function AdPopup({
  ad,
  onClose,
  loadedCats,
  uid,
  userClickCTA,
  userPreferedLang,
}) {
  //   numBER format
  const nf = new Intl.NumberFormat();
  return (
    <div className="fixed text-sm w-full h-screen overflow-y-scroll lg:overflow-hidden inset-0 bg-black bg-opacity-50 z-50  ">
      <div className="w-full lg:h-screen flex justify-center  p-2">
        <div className="md:w-1/2 lg:flex lg:overflow-y-scroll bg-white rounded-lg shadow-lg p-4 relative pb-10 ">
          {/* div container for pics on lg-screens */}
          <div className="w-full">
            {/* title and close button */}
            <div className="title-enclose w-full flex justify-center items-center ">
              <p className="capitalize font-bold text-xl">{ad?.title}</p>
              <button
                className="mr-2 hover:bg-red-500 transition-all ease-in-out bg-white rounded-full shadow-lg p-2 absolute top-0 right-0 text-gray-500 hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {/* ad pictures and cta*/}
            <div className="my-4 lg:my-0 w-full h-[80%]">
              <Carousel
                className="w-full "
                showArrows={true}
                showThumbs={false}
              >
                {ad?.itemImageUrls?.map((img, index) => {
                  return (
                    <div key={index} className="carousel w-full">
                      <img
                        src={img}
                        alt={`Product Image ${index + 1}`}
                        className="carousel-image object-contain w-full h-full"
                      />
                    </div>
                  );
                })}
              </Carousel>

              {/* ad CTA */}
              <div className="ad_cta_button my-4 lg:mt-2 lg:pb-8 text-[#2B9EDA] font-medium flex items-center justify-around">
                <a
                  onClick={() => userClickCTA(tf.call, ad)}
                  href={`tel:${ad?.user?.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center border border-[#2B9EDA] rounded-md p-2 w-[100px]  hover:bg-[#2B9EDA] hover:text-white transition-all ease-in"
                >
                  <FaPhoneAlt className="mx-2  text-[1.11rem]" />{" "}
                  <p>{userPreferedLang == "FR" ? tf.callFr : "Call"}</p>
                </a>

                <a
                  onClick={() => userClickCTA(tf.Chatbtn, ad)}
                  href={
                    userPreferedLang == "FR"
                      ? `https://wa.me/${ad?.user?.whatsAppNumber}?text=Bonne journée,%0Aje%20veux%20acheter%0A${ad?.shortLink}`
                      : `https://wa.me/${ad?.user?.whatsAppNumber}?text=Hi,%0AI%20want%20to%20buy%20this%0A${ad?.shortLink}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center group justify-center border rounded-md border-[#2B9EDA] p-2 w-[100px] hover:bg-[#2B9EDA] hover:text-white transition-all ease-in"
                >
                  <FaWhatsapp className="text-green-500 mx-2 group-hover:text-white  text-[1.20rem]" />{" "}
                  <p>{userPreferedLang == "FR" ? tf.chatFr : "Chat"}</p>
                </a>
              </div>
            </div>
          </div>

          {/* post info */}
          <div className="w-full lg:pl-4">
            <div className="details  md:my-6">
              <div className="detail mb-2 border-b flex justify-between items-center">
                <p className="text-gray-700">Price</p>
                <p className="font-bold">
                  {nf.format(ad?.itemPrice) + " " + currencySign}{" "}
                </p>
              </div>
              <div className="detail mb-2 border-b flex justify-between items-center">
                <p className="text-gray-700">
                  {userPreferedLang == "FR"
                    ? tf.priceConditionFr
                    : "Price Condition"}
                </p>
                {ad?.negotiable ? (
                  <p className="font-bold">
                    {userPreferedLang == "FR" ? tf.negotiableFr : "negotiable"}
                  </p>
                ) : (
                  <span className="font-bold">
                    {userPreferedLang == "FR" ? tf.fixedFr : "Fixed"}
                  </span>
                )}
              </div>

              {loadedCats?.map((cat) => {
                if (cat?.id == ad?.catId) {
                  return cat?.subCategories?.map((subcat) => {
                    if (subcat?.id == ad?.subCatId) {
                      if (subcat?.queryParams !== null) {
                        return subcat?.queryParams?.map((qpam) => {
                          // var qp = qpam?.name_en
                          if (qpam.items !== null) {
                            return qpam?.items?.map((item) => {
                              if (item) {
                                return ad?.queryItemsIds?.map((itemId, key) => {
                                  if (item?.id == itemId) {
                                    return (
                                      <>
                                        <div
                                          key={key}
                                          className="detail mb-2 border-b flex justify-between items-center"
                                        >
                                          <p className="text-gray-700">
                                            {userPreferedLang == "FR"
                                              ? qpam?.name_fr
                                              : qpam?.name_en}
                                          </p>
                                          <p className="font-bold">
                                            {" "}
                                            {userPreferedLang == "FR"
                                              ? item?.name_fr
                                              : item?.name_en}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                    }
                  });
                }
              })}

              {/* dynamic conditions */}
              <div className="detail mb-2 border-b flex justify-between items-center">
                <p className="text-gray-700">Location</p>
                <p className="font-bold">
                  {" "}
                  {userPreferedLang == "FR" ? ad?.localityFr : ad?.localityEn}
                </p>
              </div>
              <div className="detail mb-2  flex justify-between items-center">
                <p className="text-gray-700">
                  {userPreferedLang == "FR" ? tf.postedonFr : "Posted"}
                </p>
                <p className="font-bold">{getTime(ad?.createdAt)}</p>
              </div>

              <div className="des mt-2">
                <p className="mb-2 text-sm font-medium">
                  {userPreferedLang == "FR" ? tf.descriptionFr : "Description"}
                </p>
                <p className=" break-words ">{ad?.description}</p>
              </div>
            </div>

            {/* user post profile */}
            <UserPostProfile
              user_object={ad?.user}
              userPreferedLang={userPreferedLang}
              uid={uid}
              ad_object={ad}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdPopup;
