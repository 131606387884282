import { useState, useEffect } from "react";
import { ad1Ref, getAllPosts, PAGESIZE } from "../utils/dbQueries";
import Loading from "./ui/Loading";
import * as tf from "../utils/translations";
import { where, query, orderBy } from "firebase/firestore";
import LgPostAd from "./LgPostAd";
import MobilePostAd from "./MobilePostAd";

// THIS FUNCTION IS JUST FOR THE USER PROFILE
const InfiniteScroll = ({ userId, uid, userName, userPreferedLang }) => {
  const [posts, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [buttonIfAlertWasShown, setButtonAlert] = useState(false);

  // LOAD-MORE
  const loadMore = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      let newPosts = [];
      const postsQuery = query(
        ad1Ref,
        where("complete", "==", true),
        where("deleteForever", "==", false),
        where("trash", "==", false),
        where("status", "==", true),
        where("adminEnable", "==", true),
        where("approved", "==", true),
        where("userId", "==", userId),
        orderBy("updatedAt", "desc")
      );

      newPosts = await getAllPosts(lastPost, postsQuery);

      setPosts([...posts, ...newPosts]);
      setLastPost(newPosts?.[newPosts?.length - 1]);

      if (newPosts?.length < PAGESIZE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMore();
  }, []);

  const handleScroll = () => {
    const scrollBottom =
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) + 0;
    if (
      scrollBottom >= document.documentElement.offsetHeight ||
      !document.documentElement.offsetHeight
    ) {
      loadMore();
    }
  };
  // CLEAN USE-EFFECT
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  // check if user clicked connect it within 24hours
  useEffect(() => {
    let currentTime = new Date().getTime();
    // Get the expiration time of the popup
    const expirationTime = localStorage.getItem("warningExpirationTime");
    // Check if the popup has been shown before
    const warningShown = localStorage.getItem("warningShown");

    // If the popup has been shown before and it hasn't expired yet, don't show the popup
    if (warningShown && currentTime < expirationTime) {
      setButtonAlert(true);
      const timeLeft = Math.round((expirationTime - currentTime) / 1000);
      console.log(`Time left: ${timeLeft} seconds`);
    } else {
      console.log("warning shown already ");
    }
  }, [buttonIfAlertWasShown]);

  // LOG USER'S POST'S
  // console.log("USER'S LOADED POSTS: ",posts)

  return (
    <>
      {/* ADS/PRODUCTS CONTAINER FOR LARGE SCREENS */}
      {/* LOOP THROUGH POSTS TO DISPLAY ADS */}
      <div className=" hidden min_wrapper w-full lg:grid">
        {posts?.map((post, key) => (
          <LgPostAd
            key={key}
            post_object={post}
            uid={uid}
            userPreferedLang={userPreferedLang}
            buttonIfAlertWasShown={buttonIfAlertWasShown}
            setButtonAlert={setButtonAlert}
          />
        ))}
      </div>
      {/* ADS/PRODUCTS CONTAINER FRO LARGE SCREENS */}

      {/* ADS CONTAINER FOR SMALL DEVICES */}
      <div className="small_screens grid grid-cols-2 gap-2 lg:hidden">
        {posts?.map((post, key) => (
          <MobilePostAd
            key={key}
            post_object={post}
            uid={uid}
            userPreferedLang={userPreferedLang}
            buttonIfAlertWasShown={buttonIfAlertWasShown}
            setButtonAlert={setButtonAlert}
          />
        ))}
      </div>
      {/* ADS CONTAINER FOR SMALL DEVICES */}

      {/* EXECUTION STATUS */}
      <div className="">
        {hasMore ? (
          <>
            <div className="">
              <Loading text={`Getting ${userName}'s Posts. Please wait!`} />
            </div>
          </>
        ) : (
          <div className="w-full flex items-center justify-center my-12">
            {userPreferedLang == "FR" ? tf.thatsallFr : "That's All."}
          </div>
        )}
      </div>
    </>
  );
};

export default InfiniteScroll;
