import React, { useState, useEffect } from 'react';

function TimeDifference({ date_created, userPreferedLang }) {
  const [timeDiff, setTimeDiff] = useState("");

  useEffect(() => {
  //  IF DATE IS NOT DEFINED RETURN ERROR
    if ( !date_created?.seconds || !date_created?.nanoseconds) {
      setTimeDiff("invalid Date");
      return;
    }
// SET DATE VARIABLE AND CHECK IF NAN
    const date = new Date(date_created?.seconds * 1000 + date_created?.nanoseconds / 1000000);
    if (isNaN(date)) {
      setTimeDiff("");
      return;
    }

// CREATE VARIABLES FOR MILLI AND SECONDS FOR CALCULATIONS
    const now = new Date();
    const diffInMilliseconds = now - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

    // START WITH WHEN LESS THAN 5 MINS
    if (diffInSeconds < 300) {
      setTimeDiff(userPreferedLang === "FR" ? "maintenant" : "just now");
    }
     else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      setTimeDiff(`${diffInMinutes} ${userPreferedLang === "FR" ? 'minute' : 'min'}${diffInMinutes > 1 ? "s" : ""}`);
    } 
    else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      if (diffInHours === 1) {
        setTimeDiff(userPreferedLang === "FR" ? "il y a 1 heure" : "1 hour ago");
      } else {
        setTimeDiff(`${diffInHours} ${userPreferedLang === "FR" ? 'heure' : 'hour'}${diffInHours > 1 ? "s" : ""}`);
      }
    } 
    else if (diffInSeconds < 172800) {
      setTimeDiff(userPreferedLang === "FR" ? "hier" : "yesterday");
    }
     else if (diffInSeconds < 604800) {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      setTimeDiff(`${diffInDays} ${userPreferedLang === "FR" ? 'jour' : 'day'}${diffInDays > 1 ? "s" : ""}`);
    } 
    else {
      const diffInWeeks = Math.floor(diffInSeconds / 604800);
      if (diffInWeeks === 1) {
        setTimeDiff(userPreferedLang === "FR" ? "il y a 1 semaine" : "1 week ago");
      } else {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        setTimeDiff(date.toLocaleDateString(userPreferedLang === "FR" ? 'fr-FR' : 'en-US', options));
      }
    }
  }, [date_created, userPreferedLang]);

  return (
    <>{timeDiff}</>
  );
}

export default TimeDifference;