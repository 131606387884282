import React from 'react'

function SkeletonLoading() {
  return (
    <>
      <div className="p_wrapper w-full border rounded-md bg-white">
        <div className="w-full">
          <div
            role="status"
            className="lg:m-2 px-1 md:p-2 rounded-md shadow-md animate-pulse "
          >
            <div className="flex items-center space-x-1">
              <svg
                className=" w-14 h-14 text-gray-300"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                ></path>
              </svg>

              <div className="flex justify-between w-full">
                <div>
                  <div className="w-12 h-1.5 md:h-2.5  rounded-full bg-gray-300 md:w-32 lg:w-32 mb-1 md:mb-2"></div>
                  <div className="w-6 md:w-12 h-1 md:h-2  rounded-full bg-gray-300"></div>
                </div>
                <div className="">
                  <div className="w-2 h-1 md:w-4 md:h-2  rounded-full bg-gray-300"></div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center h-32 md:h-56 mb-2 lg:mb-4 bg-gray-300 rounded ">
              <svg
                className="w-12 h-12   text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 640 512"
              >
                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
              </svg>
            </div>

            <div className="h-1.5 lg:h-2.5  rounded-full bg-gray-300 w-24 lg:w-48 mb-1 lg:mb-4"></div>
            <div className="h-2  rounded-full bg-gray-300 mb-1.5 lg:mb-2.5"></div>
            <div className=" w-1/2 h-2 rounded-full bg-gray-300 mb-1.5 lg:mb-2.5"></div>

            <div className="w-full grid grid-cols-2 gap-2 pb-1">
              <div className="h-8 rounded bg-gray-300 "></div>
              <div className="h-8  rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkeletonLoading