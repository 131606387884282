import React from 'react'

function ElectricSymbolsPrivacyPolicy() {
  return (
    <>
      <div className="w-full mt-[-60px] lg:mt-0 relative">
        <div className="bg-[#F1F5F9] w-full h-[200px] my-4 flex justify-center items-center">
          <h2 className="text-4xl font-bold text-[#2B9EDA]">
            Electric Symbols Privacy Policy
          </h2>
        </div>

        <div className="md:px-24 px-4">
          <div className="eng w-full">
            <div className="my-4">
              This Privacy Policy explains what personal data is collected when
              you use the Electrical Symbols Quiz mobile application and the
              services provided through it (together the “Service”), how such
              personal data will be used, shared
            </div>

            <h2 className="my-4 font-bold text-[#2B9EDA]">
              What Personal Information Do We Collect
            </h2>

            <div className="my-4">
              Electrical Symbols Quiz does NOT collect, store or share any data
              from its users. All service offered by the app can be accessed
              without any form of account creation. All subscriptions, purchases
              within the app are managed by Apple using your Apple account.
            </div>

            <div className="my-4">
              Then only Third-Party SDK used in the app is RevenueCat and their
              privacy policy can be found here.
            </div>

            <div className="my-4">
              <a
                href="https://www.revenuecat.com/privacy/"
                target="_blank"
                className="text-[#2B9EDA] underline ml-2"
              >
                RevenueCat privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ElectricSymbolsPrivacyPolicy